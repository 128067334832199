import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import Select from "react-select";

export const ProductionOrderViewForm = ({
	company,
	crateProductionProduct,
	updateProductionProduct,
	productionProductToEdit,
	setProductionProductToEdit,
	closeModal,
	productionOrder,
	inventory,
}) => {
	const initialForm = {
		id: null,
		productionOrder: productionOrder,
		refProductionProduct: "",
		description: "",
		generalFeatures: "",
		specificFeatures: "",
		amount: 0,
		measureUnit: "",
		onProcess: 0,
		finishedProduct: 0,
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	// const [selectInventory, setSelectInventory] = useState([]);
	const [selectInventory, setSelectInventory] = useState(null);

	useEffect(() => {
		console.log("productionProductToEdit:", productionProductToEdit);
		if (productionProductToEdit) {
			console.log(">>> productionProductToEdit: ", productionProductToEdit);
			setForm(productionProductToEdit);
		} else {
			setForm(initialForm);
		}
		convertSelect();
	}, [productionProductToEdit, inventory]);

	const convertSelect = () => {
		// setSelectInventory([]);
		let selectInventoryProv = [];
		inventory.map((item) => {
			// console.log(">>>>> linea de inventario", item);
			const lineInventory = {
				value: item.refInventory,
				label: item.refInventory + " - " + item.description,
				id: item.id,
				measureUnit: item.measureUnit,
				description: item.description,
				generalFeatures: item.features,
			};
			selectInventoryProv.push(lineInventory);
			// console.log("ahora hay en inventario para mostrar:", selectInventory);
		});
		setSelectInventory(selectInventoryProv);
	};

	const handleChange = (e) => {
		if (e.target.name === "amount") {
			console.log("está en amount");
			setForm({
				...form,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSelectChange = (e) => {
		setForm({
			...form,
			refProductionProduct: e.value,
			measureUnit: e.measureUnit,
			description: e.description,
			generalFeatures: e.generalFeatures,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.amount <= 0) {
			alert("Solo se pueden producir cantidades mayores a 0");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			crateProductionProduct(form);
		} else {
			console.log(">>> está en el else....");
			updateProductionProduct(form);
		}
		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setProductionProductToEdit(null);
	};

	return (
		<div className="conten0">
			{/* {console.log("inventory:", inventory)} */}
			{selectInventory && (
				<div className="conten1">
					{/* {console.log("--- selectInventorry:::", selectInventory)} */}
					<h3 className="titulo1">
						{productionProductToEdit
							? "Editar Producto"
							: "Agregar Nuevo Producto"}
					</h3>
					<form onSubmit={handleSubmit} className="form-procesos">
						<h2 className="title2-account">{company}</h2>

						{loading ? (
							<Loader />
						) : (
							<div>
								{productionProductToEdit ? (
									<p className="title2-account">
										{form.refProductionProduct} - {form.description}
									</p>
								) : (
									<Select
										className="input1_3"
										options={selectInventory}
										name="refProductionProduct"
										onChange={handleSelectChange}
										placeholder="Seleccione un producto"
									/>
								)}

								<input
									type="text"
									name="specificFeatures"
									placeholder="Característias Específicas"
									onChange={handleChange}
									value={form.specificFeatures}
									className="input1"
									required
								/>
								<input
									type="number"
									name="amount"
									placeholder="Cantidad"
									onChange={handleChange}
									value={form.amount}
									className="input1"
									required
								/>

								<br />
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
								<input
									type="reset"
									value="Cerrar"
									onClick={closeModal}
									className="btn-close-reg-asign"
								/>
							</div>
						)}
					</form>
				</div>
			)}
		</div>
	);
};
