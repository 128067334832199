import React from "react";

import imagen from "../../generalComponents/images/logoBPW.png";

export const MainTrainingInitial = () => {
	return (
		<div>
			<div className="containerIcon">
				<h2>CONFIGURACION DE CURSOS </h2>
				<br />
				<br />
				<br />
				<img src={imagen} />
			</div>
		</div>
	);
};
