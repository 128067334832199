import React from "react";
import imagen from "../../../generalComponents/images/logoBPW.png";

export const GeneralInventoryInitial = () => {
	return (
		<div>
			<div className="containerIcon">
				<h2> INVENTARIOS </h2>
				<br />
				<br />
				<br />
				<img src={imagen} />
			</div>
		</div>
	);
};
