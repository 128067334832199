import React, { useEffect, useState } from "react";
import CrudDataBaseTableRow from "./CrudDataBAseTableRow";

const CrudDataBaseTable = ({ process, setProcessToEdit, openModal1 }) => {
	const initialForm = {
		area: "Area",
	};

	const [form, setForm] = useState(initialForm);
	const [processFilter, setProcessFilter] = useState(process);

	useEffect(() => {
		setProcessFilter(process);
	}, [process]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setProcessFilter(process);
		}
		setProcessFilter(
			process.filter(
				(item) =>
					item.process.toLowerCase().includes(e.target.value) ||
					item.area.toLowerCase().includes(e.target.value)
			)
		);
	}

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.area !== "Area") {
			setProcessFilter(process.filter((proceso) => proceso.area === form.area));
		} else {
			setProcessFilter(process);
		}
	};

	const handleReset = (e) => {
		setProcessFilter(process);
	};

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					value=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h3 className="titulo1">Lista de Procesos</h3>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos1">Area</th>
						<th className="titulo-tabla-procesos2">Proceso</th>
						<th className="titulo-tabla-procesos3">Acción</th>
					</tr>
				</thead>
				<tbody>
					{processFilter.length > 0 ? (
						processFilter.map((el) => (
							<CrudDataBaseTableRow
								key={el.id}
								el={el}
								setProcessToEdit={setProcessToEdit}
								openModal1={openModal1}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudDataBaseTable;
