import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Loader from "../../generalComponents/Loader";
import Modal from "../../generalComponents/Modal";

import Message from "../../generalComponents/Message";
import { firebaseConfig } from "../../../config/firebaseConfig";
import {
	addModules,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { useModal } from "../../generalComponents/hooks/useModal";
import { BlocksForm } from "./BlocksForm";

export const BlocksApp = ({ company }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	// ++++++++++++++++++++++++ se definen los módulos existentes
	const modules = ["Procesos", "Documentos", "Capacitación", "Evaluación"];
	//+++++++++++++++++++++++++

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [modulesToEdit, setModulesToEdit] = useState(null);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [dataBase, setDataBase] = useState(null);

	const askModules = async () => {
		setLoading(true);
		const q = query(
			collection(db, "adminModules"),
			where("company", "==", company)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				if (dataList.length > 0) {
					setModulesToEdit(dataList[0]);
				} else {
					setModulesToEdit(null);
				}
				setLoading(false);
			},
			(error) => {
				setModulesToEdit(null);
				setDataBase([]);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askModules();
	}, [company]);

	const createAsignedModules = async (data) => {
		setLoading(true);
		const response = await addModules(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	const updateAsignedModules = async (data) => {
		setLoading(true);
		const nameCollection = "adminModules";
		const docId = data.id;
		await updateDocumentById(nameCollection, docId, data);
		setLoading(false);
	};

	const clickCrear = () => {
		openModal1();
	};

	return (
		<div>
			<h3 className="titulo1">Módulos Asignados a : {company}</h3>
			<article>
				{dataBase && dataBase.length > 0 ? (
					dataBase[0].modules.length !== 0 ? (
						<div>
							{dataBase[0].modules.map((line) => (
								<div key={line}>
									<h2 className="titulo2">* {line}</h2>
								</div>
							))}
						</div>
					) : (
						<h2 className="titulo2">
							Se han revocado todos los permisos de Módulos para la empresa
						</h2>
					)
				) : (
					<h2 className="titulo2">
						No hay asignación de Módulos para la empresa
					</h2>
				)}
				{/* {dataBase && dataBase[0].modules.length === 0 && (
					<h2 className="titulo2">
						Se han revocado todos los permisos de Módulos para la empresa
					</h2>
				)} */}
				<button onClick={clickCrear} className="btn-create-document">
					Revisar Asignación de Módulos
				</button>
				{dataBase && (
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<BlocksForm
							company={company}
							modules={modules}
							closeModal={closeModal1}
							isOpenModal1={isOpenModal1}
							setError={setError}
							modulesToEdit={modulesToEdit}
							setModulesToEdit={setModulesToEdit}
							createAsignedModules={createAsignedModules}
							updateAsignedModules={updateAsignedModules}
						/>
					</Modal>
				)}

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{dataBase ? (
					dataBase.length >= 0 ? (
						<div>
							{/* <CoursesTable
								courses={dataBase}
								setCourseToEdit={setCourseToEdit}
								deleteCourse={deleteCourse}
								openModal1={openModal1}
							/> */}
						</div>
					) : (
						<h3 className="alertEmpty">No hay Módulos para mostrar</h3>
					)
				) : (
					<h3 className="alertEmpty">No hay Módulos para mostrar...</h3>
				)}
			</article>
		</div>
	);
};
