import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { UsersProductionTable } from "./usersProductionTable";
import { UserProductionDetailTable } from "./userProductionDetailTable";
import Loader from "../../generalComponents/Loader";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
} from "../../../utils/actions/firebase/firebaseActions";
export const UsersProductionApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	let area = null;
	if (localUser.type === "2") {
		area = "Producción";
	} else {
		area = localUser.area;
	}

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [users, setUsers] = useState(null);
	// const [show, setShow] = useState(false);
	const [userToShow, setUserToShow] = useState(null);
	const [activitiesList, setActivitiesList] = useState(null);

	useEffect(() => {
		askUsers();
	}, []);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";

		const q = query(
			collection(db, nameCollection),
			where("company", "==", company),
			where("area", "==", "Producción")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setUsers(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("resultado negativo", error);
				setUsers(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askUserWork = async (user) => {
		setLoading(true);
		let activitiesListProv = [];
		await askDataKeyValue2Simple(
			"processRegister-" + company,
			"area",
			"Producción",
			"status",
			"abierto"
		)
			.then(async (resp1) => {
				const processes = resp1.data;
				if (processes.length === 0) {
					setActivitiesList(activitiesListProv);
					setLoading(false);
				} else {
					await Promise.all(
						processes.map(async (process) => {
							const processNumber = process.processNumber;
							await askDataKeyValue3Simple(
								"assignments-" + company,
								"area",
								"Producción",
								"assignment",
								user.email,
								"processNumber",
								processNumber
							)
								.then(async (resp2) => {
									let assignments = resp2.data;
									if (assignments.length === 0) {
									} else {
										await Promise.all(
											assignments.map(async (assignment) => {
												await askDataKeyValue3Simple(
													"activitiesRegister-" + company,
													"area",
													"Producción",
													"processNumber",
													processNumber,
													"stage",
													assignment.stage
												)
													.then((resp3) => {
														let activities = resp3.data;

														activities.forEach((activity) => {
															let totalActivity = {
																...activity,
																totalTime: assignment.totalTime,
																statusActingTime: assignment.statusActingTime,
																eventsTime: assignment.eventsTime,
																refProductionProduct:
																	process.refProductionProduct,
																finishProduct: process.finishProduct,
															};
															activitiesListProv.push(totalActivity);
														});
													})
													.catch((error) => {
														console.log("error:", error);
														setLoading(false);
													});
											})
										);
									}
								})
								.catch((error) => {
									console.log("error:", error);
									setLoading(false);
								});
						})
					);
					// console.log(
					// 	">>> --- Finalmente La lista de actividades largo ________: ",
					// 	activitiesListProv.length
					// );
					setActivitiesList(activitiesListProv);
					setLoading(false);
				}
			})
			.catch((error) => {
				console.log("error:", error);
				setLoading(false);
			});
	};
	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<div>
					<article className="titulo9">
						TRABAJOS POR USUARIO ({company})
					</article>
					{users && (
						<div>
							{!userToShow ? (
								<UsersProductionTable
									users={users}
									setUserToShow={setUserToShow}
									askUserWork={askUserWork}
								/>
							) : (
								activitiesList && (
									// activitiesList.length > 0 && (
									<UserProductionDetailTable
										setUserToShow={setUserToShow}
										userToShow={userToShow}
										activitiesList={activitiesList}
									/>
								)
								// )
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
