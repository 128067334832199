import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import { askData } from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";

const ProcessAdmin = () => {
	const [db, setDb] = useState([]);
	const [company, setCompany] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [goTo, setGoTo] = useState(null);

	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	// console.log("localUser.type", localUser.type);

	const askAccounts = async () => {
		setLoading(true);
		const nameCollection = "accounts";
		const respuesta = await askData(nameCollection);
		if (respuesta.statusResponse === true) {
			setDb(respuesta.data);
			setLoading(false);
			setError(null);
		} else {
			setDb([]);
			setLoading(false);
			setError(respuesta.error);
		}
	};

	useEffect(() => {
		askAccounts();
	}, []);

	const selectCompany = (el) => {
		localStorage.setItem("usuariosEmpresa", el.company);
		setGoTo("/process/manage/" + el.company);
		setCompany(el);
	};
	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />
					<h3 className="titleProcess">P R O C E S O S</h3>
					{loading && <Loader />}
					{company ? (
						<Redirect to={goTo} />
					) : error ? (
						<Message mensaje={error} bgColor="#dc3545" />
					) : (
						<div>
							<h3>SELECCIONE LA EMPRESA PARA GESTIONAR PROCESOS</h3>
							<table className="tabla-cuentas">
								<thead>
									<tr>
										<th className="titulo-tabla">Empresa</th>
										<th className="titulo-tabla">Seleccione</th>
									</tr>
								</thead>
								<tbody>
									{db.length > 0 ? (
										db.map((el) => (
											<tr className="fila-tabla-cuenstas" key={el.id}>
												<td className="col-tabla-cuenta">{el.company}</td>
												<td className="col-tabla-cuenta">
													<button
														onClick={() => selectCompany(el)}
														className="btn-editar"
													>
														Ver
													</button>
													{/* <button onClick={() => deleteData(id)}>Eliminar</button> */}
												</td>
											</tr>
										))
									) : (
										<tr>
											<td colSpan="3">Sin datos</td>
										</tr>
									)}
								</tbody>
							</table>
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default ProcessAdmin;
