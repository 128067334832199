import React, { useEffect, useState } from "react";
import { UsersproductionDetailTableRow } from "./userProductionDetailTableRow";

export const UserProductionDetailTable = ({
	setUserToShow,
	userToShow,
	activitiesList,
}) => {
	const [actRecived, setActRecived] = useState(activitiesList);
	useEffect(() => {
		setActRecived(activitiesList);
	}, [activitiesList]);

	const backTo = () => {
		setUserToShow(null);
	};

	return (
		<div>
			{/* {console.log("En Show --- lista de actividades: ", activitiesList)} */}
			<article className="titulo12">
				Nombre :{userToShow.name} -*- Usuario: {userToShow.email} -*- {""}
				<button onClick={() => backTo()} className="btnOpenRegprocesos">
					Regresar
				</button>
			</article>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-etapas1"># Proceso</th>
						<th className="titulo-tabla-etapas1">Producto</th>
						<th className="titulo-tabla-etapas1">Tarea</th>
						<th className="titulo-tabla-etapas1">Avance</th>
						<th className="titulo-tabla-etapas1">%</th>
						<th className="titulo-tabla-etapas1">Tiempo [hh:mm]</th>
					</tr>
				</thead>

				<tbody>
					{activitiesList ? (
						activitiesList.length > 0 ? (
							activitiesList.map((el) => (
								<UsersproductionDetailTableRow key={el.id} el={el} />
							))
						) : (
							<tr>
								<td colSpan="3">No hay Actividades para este usuario</td>
							</tr>
						)
					) : (
						<div>no</div>
					)}
				</tbody>
			</table>
		</div>
	);
};
