import React, { useEffect, useState } from "react";
import { updateDocumentById } from "../../../../utils/actions/firebase/firebaseActions";
import { TimeClocksTableRow } from "./timeClocksTableRow";

export const TimeClocksTable = ({ data, setLoading, user, company }) => {
	const [showData, setShowData] = useState(data);

	useEffect(() => {
		setShowData(data);
	}, [data]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setShowData(data);
		}
		setShowData(
			data.filter(
				(item) =>
					item.assignment.toLowerCase().includes(e.target.value) ||
					item.area.toLowerCase().includes(e.target.value) ||
					item.process.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.stage.toLowerCase().includes(e.target.value)
			)
		);
	}

	const stopParticular = async (assignment) => {
		setLoading(true);
		const fechita = new Date();
		const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
		const eventsTime = assignment.eventsTime;
		const totalEventsTime = eventsTime.length - 1;
		eventsTime[totalEventsTime].f = fechitaMlSeconds; //cambia el valor final de tiempo del evento último
		eventsTime[totalEventsTime].stopCause = "superior";
		eventsTime[totalEventsTime].comment = user;
		const timeMls =
			eventsTime[totalEventsTime].f - eventsTime[totalEventsTime].i;
		assignment.statusActingTime = "pausado";
		assignment.eventsTime = eventsTime;
		assignment.totalTime = assignment.totalTime + timeMls;
		await updateDocumentById(
			"assignments-" + company,
			assignment.id,
			assignment
		);
		setLoading(false);
	};

	function stopAll() {
		console.log("detener: ", showData);
		showData.map((assignment) => {
			stopParticular(assignment);
		});
	}

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<button onClick={stopAll} className="btnClearTimeAll">
				Detener los relojes mostrados
			</button>
			<h3 className="titulo1">Lista de Relojes Activos</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments03">Area</th>
						<th className="titleTableDocuments02">Usuario</th>
						<th className="titleTableDocuments02">Proceso</th>
						<th className="titleTableDocuments02"># Proceso</th>
						<th className="titleTableDocuments03">Etapa</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{showData.length > 0 ? (
						showData.map((el) => (
							<TimeClocksTableRow
								key={el.id}
								el={el}
								stopParticular={stopParticular}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
