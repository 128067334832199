import React, { useEffect, useState } from "react";
import { ParticularTransferInTable } from "./particularTransferInTable";

export const ParticularTransferInMake = ({
	assignments,
	inventoryTransfer,
}) => {
	const [processList, setProcessList] = useState(null);
	const [inventoryTransferList, setInventoryTransferList] = useState(null);

	useEffect(() => {
		createList();
	}, [inventoryTransfer, assignments]);

	const createList = () => {
		let processList = [];
		assignments.map((item) => {
			let processNumber = item.processNumber;
			if (processList.includes(processNumber)) {
			} else {
				processList.push(processNumber);
			}
		});
		setProcessList(processList);
		let inventoryTransferList = [];
		processList.map((process) => {
			let assignmentOrder = [];
			inventoryTransfer.map((transferItem) => {
				if (process === transferItem.processNumber) {
					assignmentOrder.push(transferItem);
				}
			});
			inventoryTransferList.push(assignmentOrder);
		});

		setInventoryTransferList(inventoryTransferList);
	};

	return (
		<div>
			{inventoryTransferList &&
				inventoryTransferList.map((item, index) => (
					<div key={index}>
						{item.length > 0 && (
							<div>
								<article className="titulo11">
									Proceso: {item[0].processNumber}
								</article>
								<ParticularTransferInTable
									inventoryTransfer={item}
									multiple={true}
								/>
								<hr />
							</div>
						)}
					</div>
				))}
		</div>
	);
};
