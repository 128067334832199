import React, { useState, useEffect } from "react";

const CrudSucursalesForm = ({
	createSucursal,
	updateSucursal,
	data2toEdit,
	setData2toEdit,
	company,
}) => {
	const initialForm = {
		id: null,
		agency: "",
	};
	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		if (data2toEdit) {
			setForm(data2toEdit);
		} else {
			setForm(initialForm);
		}
	}, [data2toEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.agency) {
			alert(
				"Para adicionar una sucursal hay que escribir un nombre de empresa..."
			);
			return;
		}
		if (form.id === null) {
			createSucursal(form);
		} else {
			updateSucursal(form);
		}

		handleReset();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setData2toEdit(null);
	};

	return (
		<div className="cont1">
			<h3 className="titulo1">
				{data2toEdit ? "Editar Sucursal" : "Agregar Nueva Sucursal"}
			</h3>
			<form onSubmit={handleSubmit} className="form-cuentas">
				<input
					type="text"
					name="agency"
					placeholder="Nombre de la Sucursal"
					onChange={handleChange}
					value={form.agency}
					className="input1"
				/>
				<input type="submit" value="Enviar" className="btn-send-accounts" />
				<input
					type="reset"
					value="Limpiar"
					onClick={handleReset}
					className="btn-clean-accounts"
				/>
			</form>
		</div>
	);
};

export default CrudSucursalesForm;
