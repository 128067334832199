import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { Redirect } from "react-router";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import "../../styles/MenuProcesos.css";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import {
	addDatakey1,
	askDataKeyValue,
	askDataKeyValue2,
	askDataSimple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import CrudProcessForm from "./CrudProcessForm";
import CrudPorcessTable from "./CrudProcessTable";

const CrudProcessApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [db1, setDb1] = useState(null); // procesos
	const [db2, setDb2] = useState(null); // areas
	const [processToEdit, setProcessToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [goStages, setGoStages] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "process-" + company;

		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDb1(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("resultado negativo", error);
				setDb1(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askAreas = async () => {
		setLoading(true);
		const nameCollection = "areas-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setDb2(respuesta.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		askProcess();
		askAreas();
	}, []);

	const createProcess = async (data) => {
		setLoading(true);

		const nameCollection = "process-" + company;
		const key1 = "process";
		const value1 = data.process;
		const response = await addDatakey1(nameCollection, data, key1, value1);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askProcess();
		setLoading(false);
	};

	const updateProcess = async (data) => {
		setLoading(true);
		const nameCollection = "process-" + company;
		const docId = data.id;
		const changes = data;
		const key2 = "process";
		const value2 = data.process;
		const response = await askDataKeyValue(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Este proceso ya existe");
				setLoading(false);
			} else {
				//sacar nombre inicial del proceso
				const processName01 = processToEdit.process;

				if (processName01 !== value2) {
					const response2 = await askDataKeyValue(
						"stages-" + company,
						key2,
						processName01
					);
					if (response2.statusResponse === true) {
						const stagesToModify = response2.data;
						for (const stage of stagesToModify) {
							stage.process = value2;

							await updateDocumentById("stages-" + company, stage.id, stage);
							const response3 = await askDataKeyValue2(
								"activities-" + company,
								"stage",
								stage.stage,
								key2,
								processName01
							);
							if (response3.statusResponse === true) {
								const activitiesTomodify = response3.data;
								activitiesTomodify.map(async (activity) => {
									activity.process = value2;
									await updateDocumentById(
										"activities-" + company,
										activity.id,
										activity
									);
								});
							}
						}
					}
				}
				await updateDocumentById(nameCollection, docId, changes);
				askProcess();
				setLoading(false);

				//consultar etapas del proceso y hacer el cambio de nombre del proceso en cada etapa

				//consultar actividades de cada etapa del proceso y cambiar e nombre del proceso en cada actividad
			}
		} else {
			askProcess();
			setLoading(false);
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	const clickCrear = () => {
		setProcessToEdit(null);
		openModal1();
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<article>
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h3 className="titleProcess">PROCESOS ({company})</h3>

						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudProcessForm
								db2={db2}
								createProcess={createProcess}
								updateProcess={updateProcess}
								processToEdit={processToEdit}
								setProcessToEdit={setProcessToEdit}
								company={company}
								closeModal={closeModal1}
							/>
						</Modal>
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{db1 ? (
									db2 && (
										<div>
											<button
												onClick={clickCrear}
												className="btn-crear-proceso"
											>
												Crear Nuevo Proceso
											</button>

											<CrudPorcessTable
												db2={db2}
												process={db1}
												setProcessToEdit={setProcessToEdit}
												openModal1={openModal1}
												setGoStages={setGoStages}
												company={company}
											/>
										</div>
									)
								) : (
									<h3>No hay procesos para mostrar</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
			{goStages === true && <Redirect to="/stages-list" />}
		</div>
	);
};

export default CrudProcessApp;
