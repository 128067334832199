import React, { useEffect, useState } from "react";
import CrudProcesosTableRow from "./CrudProcessTableRow";

const CrudPorcessTable = ({
	db2,
	process,
	setProcessToEdit,
	openModal1,
	setGoStages,
	company,
}) => {
	const initialForm = {
		area: "Area",
	};
	const [form, setForm] = useState(initialForm);
	const [processFilter, setProcessFilter] = useState(process);
	const [processFilter2, setProcessFilter2] = useState(process);
	// let processFilter = process;

	useEffect(() => {
		setProcessFilter(process);
		setProcessFilter2(process);
	}, [process]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.area !== "Area") {
			setProcessFilter(process.filter((proceso) => proceso.area === form.area));
			setProcessFilter2(
				process.filter((proceso) => proceso.area === form.area)
			);
		} else {
			setProcessFilter(process);
		}
	};

	const handleReset = (e) => {
		setProcessFilter(process);
		setProcessFilter2(process);
	};

	function handleSearch(e) {
		if (e.target.value === "") {
			return setProcessFilter2(processFilter);
		}
		setProcessFilter2(
			processFilter.filter(
				(item) =>
					item.process.toLowerCase().includes(e.target.value) ||
					item.descriptionProcess.toLowerCase().includes(e.target.value) ||
					item.area.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			{/* {console.log("areas en tabla", db2)} */}
			<form onSubmit={handleSubmit} className="filter-procesos">
				<select
					name="area"
					onChange={handleChange}
					value={form.area}
					className="input1"
				>
					<option defaultValue>Area</option>
					{db2.map((line) => (
						<option key={line.area} value={line.area}>
							{line.area}
						</option>
					))}
				</select>
				<input type="submit" value="Filtrar" className="btn-filter-procesos" />
				<input
					type="reset"
					value="Limpiar"
					onClick={handleReset}
					className="btnCleanFilterProcess"
				/>
			</form>

			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>

			<h3 className="titulo1">Lista de Procesos</h3>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos1">Area</th>
						<th className="titulo-tabla-procesos2">Proceso</th>
						<th className="titulo-tabla-procesos2">Descripcion</th>
						<th className="titulo-tabla-procesos3">Acción</th>
						<th className="titulo-tabla-procesos1">Activo</th>
					</tr>
				</thead>
				<tbody>
					{processFilter.length > 0 ? (
						processFilter2.map((el) => (
							<CrudProcesosTableRow
								key={el.id}
								el={el}
								setProcessToEdit={setProcessToEdit}
								openModal1={openModal1}
								setGoStages={setGoStages}
								company={company}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudPorcessTable;
