import React, { useState, useEffect } from "react";
import Loader from "../../generalComponents/Loader";
import "../../styles/MenuProcesos.css";
import { askDataSimple } from "../../../utils/actions/firebase/firebaseActions";

const CrudActivitiesAdminForm = ({
	createActivity,
	updateActivity,
	setActivityToEdit,
	activityToEdit,
	company,
	process,
	stage,
	closeModal,
	db,
}) => {
	let long = 1;
	if (db != null) {
		long = db.length + 1;
		// console.log("*** la nueva posición será:", long);
	}
	const initialForm = {
		id: null,
		process: process,
		stage: stage,
		activity: "",
		position: long,
		descriptionActivity: "",
		// responseType: "Cumple-NoCumple",
		responseType: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [tipoPregunta, setTipoPregunta] = useState([]);
	// const [error, setError] = useState(null);

	const askAnswerType = async () => {
		setLoading(true);
		const nameCollection = "TipoRespuesta";
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setTipoPregunta(respuesta.data);
			setLoading(false);
		} else {
			setLoading(false);
		}
	};

	useEffect(() => {
		askAnswerType();
		if (activityToEdit) {
			setForm(activityToEdit);
		} else {
			setForm(initialForm);
		}
	}, [activityToEdit, long]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.activity) {
			alert("Debe colocar una Actividad...");
			return;
		}
		if (!form.descriptionActivity) {
			alert("Debe llenar el campo de descripción de la Actividad...");
			return;
		}
		if (form.responseType === "" || form.responseType === "Tipo de Respuesta") {
			alert("Debe seleccionar un tipo de respuesta...");
			return;
		}
		// console.log("en long hay ahora:", long);
		setLoading(true);
		if (form.id === null) {
			createActivity(form);
		} else {
			updateActivity(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setActivityToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{activityToEdit ? "Editar Actividad" : "Agregar Nueva Actividad"}
				</h3>
				<h3 className="titulo2">
					{activityToEdit &&
						"ALERTA: Solo se recomienda editar una Actividad mientras se está creando, de lo contrario estaría creando una nueva Actividad."}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					<h2 className="title2-account">Proceso: {process}</h2>
					<h2 className="title2-account">Etapa: {stage}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="activity"
								placeholder="Actividad ó Tarea"
								onChange={handleChange}
								value={form.activity}
								className="input0"
							/>
							<input
								type="text"
								name="descriptionActivity"
								placeholder="Descripción de la Actividad"
								onChange={handleChange}
								value={form.descriptionActivity}
								className="input0"
							/>
							<select
								name="responseType"
								onChange={handleChange}
								value={form.responseType}
								className="input0"
							>
								<option defaultValue>Tipo de Respuesta</option>
								{tipoPregunta.map((linea) => (
									<option key={linea.responseType} value={linea.responseType}>
										{linea.responseType}
									</option>
								))}
							</select>
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudActivitiesAdminForm;
