import React, { useEffect, useState } from "react";
import { ViewEvaluationAnswer } from "./ViewEvaluationAnswer";

export const ViewEvaluationQuestion = ({
	totalQuestions,
	questionsList,
	answersList,
	answersQuiz,
	changeAnswers,
	setFinalButtonVisible,
}) => {
	const [position, setPosition] = useState(0);
	const [visibleFollow, setVisibleFollow] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
	};
	useEffect(() => {
		setVisibleFollow(false);
	}, []);

	const otherQuestion = () => {
		setPosition(position + 1);
		setVisibleFollow(false);
	};
	return (
		<div>
			<form onSubmit={handleSubmit}>
				{position === totalQuestions ? (
					<div>
						{setFinalButtonVisible(true)}
						<p>Gracias...</p>
					</div>
				) : (
					<div>
						<h2>
							pregunta {position + 1}:____ {questionsList[position].question}
						</h2>
						<ViewEvaluationAnswer
							answersList={answersList}
							question={questionsList[position].question}
							setVisibleFollow={setVisibleFollow}
							answersQuiz={answersQuiz}
							changeAnswers={changeAnswers}
						/>
						{visibleFollow && (
							<input
								type="reset"
								value="Siguiente"
								onClick={otherQuestion}
								className="btn-clean-reg-asign"
							/>
						)}
					</div>
				)}
			</form>
		</div>
	);
};
