import React, { useState } from "react";
import Loader from "../../generalComponents/Loader";

export const FormStops = ({ closeModal2, actionStop }) => {
	const initialForm = {
		stopCause: "",
		comment: "",
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleClose = (e) => {
		setForm(initialForm);
		closeModal2();
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (form.stopCause === "" || form.stopCause === "Seleccione un motivo") {
			alert("Debe seleccionar un motivo...");
			return;
		}
		if (form.stopCause === "Otro") {
			if (form.comment === "") {
				alert(
					"Por favor colocar comentario que describa el motivo de la parada..."
				);
				return;
			}
		}

		actionStop(form);
		closeModal2();
	};

	return (
		<div>
			<div className="formStop">
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="titulo2">Detención del proceso</h2>

					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="titulo1">Motivo de Detención:</label>

							<div>
								<select
									name="stopCause"
									onChange={handleChange}
									value={form.stopCause}
									className="input0"
								>
									<option defaultValue>Seleccione un motivo</option>
									<option value="Cambio de Asignación">
										Cambio de Asignación
									</option>
									<option value="Descanso">Descanso</option>
									<option value="Almuerzo">Almuerzo</option>
									<option value="Pausa Activa">Pausa Activa</option>
									<option value="Fin de la jornada">Fin de la jornada</option>
									<option value="Falta de materia prima">
										Falta de materia prima
									</option>
									<option value="Otro">Otro</option>
								</select>
							</div>

							<div>
								<input
									type="text"
									name="comment"
									placeholder="Comentario"
									onChange={handleChange}
									value={form.comment}
									className="input0"
								/>
							</div>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							{/* <input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/> */}
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
