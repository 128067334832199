import React, { useEffect, useState } from "react";
import { milisecondsToHHMM } from "../../../utils/actions/firebase/firebaseActions";

export const UsersproductionDetailTableRow = ({ el }) => {
	const [timeToShow, setTimeToShow] = useState(null);

	useEffect(() => {
		calculateTime();
	}, [el]);

	// const milisecondsToHHMM = (miliseconds) => {
	// 	let hour = miliseconds / 3600000;
	// 	let hourTrunc = Math.trunc(hour);
	// 	let res1 = hour - hourTrunc;
	// 	let min = res1 * 60;
	// 	let minTrunc = Math.trunc(min);
	// 	let hhmm = { hours: hourTrunc, minuts: minTrunc };
	// 	return hhmm;
	// };

	const calculateTime = () => {
		if (el.statusActingTime === "no") {
			setTimeToShow("[00:00]");
		} else {
			if (el.statusActingTime === "pausado") {
				let response2 = milisecondsToHHMM(el.totalTime);
				setTimeToShow("[" + response2.hours + ":" + response2.minuts + "]");
			} else {
				const fechita = new Date();
				const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
				let posTargetEvent = el.eventsTime.length - 1;
				let targetEvent = el.eventsTime[posTargetEvent];
				let addTime = fechitaMlSeconds - targetEvent.i;
				let newTotalTime = el.totalTime + addTime;
				let response3 = milisecondsToHHMM(newTotalTime);
				setTimeToShow("[" + response3.hours + ":" + response3.minuts + "]");
			}
		}
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col10p">{el.processNumber}</td>
			<td className="col20p">{el.refProductionProduct}</td>
			<td className="col30p">{el.activity}</td>
			<td className="col10p">
				{el.amount} / {el.totalAmount}{" "}
			</td>
			<td className="col10p">
				<label
					className={
						el.amount === 0
							? "avance0"
							: el.amount / el.totalAmount === 1
							? "avance100"
							: "avance50"
					}
				>
					{Math.trunc((el.amount * 100) / el.totalAmount)}%
				</label>
			</td>
			<td className="col10p">
				{timeToShow} {el.statusActingTime === "si" && <label>⏰</label>}
			</td>
		</tr>
	);
};
