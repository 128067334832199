import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Modal from "../../../../generalComponents/Modal";
import { PermisionsInventoryMasterForm } from "./permisionsInventoryMasterForm";
import { askDataKeyValue } from "../../../../../utils/actions/firebase/firebaseActions";
import { PermisionsInventoryMasterTable } from "./permisionsInventoryMasterTable";

export const PermisionsInventoryMasterApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [users, setUsers] = useState([]);
	const [userPermisions, setUserPermisions] = useState(null);
	const [userPermisionsToEdit, setUserPermisionsToEdit] = useState(null);

	useEffect(() => {
		askPermisions();
		askUsers();
		// askCourses();
	}, []);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";
		const key = "company";
		const value = company;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			setUsers(response.data);
			setLoading(false);
		} else {
			setUsers(null);
			setError(response.error);
			setLoading(false);
		}
	};

	const askPermisions = () => {
		setLoading(true);
		const collectionName = "inventoryPermisions-" + company;

		const q = query(
			collection(db, collectionName),
			where("typePermision", "==", "Master")
		);
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setUserPermisions(dataList);
			setLoading(false);
		});
	};

	const clickCrear = () => {
		let newUserPermisions = userPermisions.filter(
			(line) => line.typePermision === "Master"
		);
		if (newUserPermisions.length > 0) {
			setUserPermisionsToEdit(newUserPermisions[0]);
		} else {
			setUserPermisionsToEdit(null);
		}

		openModal1();
	};

	return (
		<div>
			<article>
				{users && (
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<PermisionsInventoryMasterForm
							users={users}
							userPermisionsToEdit={userPermisionsToEdit}
							setUserPermisionToEdit={setUserPermisionsToEdit}
							closeModal={closeModal1}
							company={company}
						/>
					</Modal>
				)}

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{userPermisions ? (
					userPermisions.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Modificar
							</button>
							<PermisionsInventoryMasterTable userPermisions={userPermisions} />
						</div>
					) : (
						<h3 className="alertEmpty">No hay Usuarios asignados</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
