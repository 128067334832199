import React from "react";

export const PermisionsTimeReportTable = ({ userPermisions }) => {
	return (
		<div>
			<table className="tabla-procesos">
				<tbody>
					{userPermisions.length > 0 ? (
						userPermisions[0].timeReportsPermision.map((el) => (
							<tr className="fila-tabla-cuenstas" key={el}>
								<td className="colTableDocuments1">{el}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
