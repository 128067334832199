import React from "react";

export const ProductionOrderViewTableRow = ({
	el,
	setProductionProductToEdit,
	openModal,
	deleteProductionProduct,
}) => {
	const editButton = (el) => {
		setProductionProductToEdit(el);
		openModal();
	};

	const deleteButton = (el) => {
		deleteProductionProduct(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.refProductionProduct}</td>
			<td className="colTableDocuments1">{el.amount}</td>
			<td className="colTableDocuments1">{el.measureUnit}</td>
			<td className="colTableDocuments1">{el.description}</td>
			<td className="colTableDocuments1">{el.generalFeatures}</td>
			<td className="colTableDocuments1">{el.specificFeatures}</td>
			<td className="colTableDocuments1">{el.onProcess}</td>
			<td className="colTableDocuments1">{el.finishedProduct}</td>
			<td className="colTableDocuments2">
				<button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
				</button>

				<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button>
			</td>
		</tr>
	);
};
