import React from "react";
import { Redirect } from "react-router";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import { StudentsApp } from "../students/StudentsApp";

const MainTrainingStudent = () => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	return (
		<div>
			{localUser.type === "3" ? (
				<NavbarBpw3 />
			) : localUser.type === "4" ? (
				<NavbarBpw4 />
			) : (
				<Redirect to="/intruso" />
			)}
			<StudentsApp />
		</div>
	);
};

export default MainTrainingStudent;
