import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import { askDataKeyValue2Simple } from "../../../utils/actions/firebase/firebaseActions";

export const CoursesForm = ({
	presentations,
	evaluations,
	companies,
	createCourse,
	updateCourse,
	courseToEdit,
	setCourseToEdit,
	closeModal,
	setError,
}) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	// console.log("localUsar:", localUser);

	const initialForm = {
		id: null,
		company: company,
		nameCourse: "",
		descriptionCourse: "",
		nameEvaluation: "",
		materialCourse: [],
		date: "",
		autorizedCompanies: [],
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [newPresentations, setNewPresentations] = useState(presentations);
	const [newEvaluations, setNewEvaluations] = useState(evaluations);
	const [newCompanies, setNewCompanies] = useState(companies);
	const [checkedState, setCheckedState] = useState([]); //cheqState true/false Material
	const [checkedState2, setCheckedState2] = useState([]); //cheqState true/false evaluaciones
	const [checkedState3, setCheckedState3] = useState([]); //cheqState true/false compañias autorizadas

	useEffect(() => {
		setNewPresentations(presentations);
		setNewEvaluations(evaluations);
		setNewCompanies(companies);
		if (courseToEdit) {
			setForm(courseToEdit);
			editCheckedState();
			editCheckedState2();
			editCheckedState3();
		} else {
			setForm(initialForm);
			setCheckedState(new Array(presentations.length).fill(false));
			setCheckedState2(new Array(evaluations.length).fill(false));
			setCheckedState3(new Array(companies.length).fill(false));
		}
	}, [courseToEdit, presentations, evaluations, companies]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const clicCheckBox = (id) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	const clicCheckBox2 = (id) => {
		const updatedCheckedState = checkedState2.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState2(updatedCheckedState);
	};

	const clicCheckBox3 = (id) => {
		const updatedCheckedState3 = checkedState3.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState3(updatedCheckedState3);
	};

	const materialAdd = () => {
		form.materialCourse = [];
		checkedState.map((item, index) => {
			if (item === true) {
				form.materialCourse.push(newPresentations[index].name);
			}
		});
	};

	const companiesAdd = () => {
		form.autorizedCompanies = [];
		checkedState3.map((item, index) => {
			if (item === true) {
				form.autorizedCompanies.push(newCompanies[index].company);
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!form.nameCourse) {
			alert("Debe colocar un nombre a la Curso...");
			return;
		}
		const totalTrue = checkedState.filter((item) => item === true);
		if (totalTrue.length === 0) {
			alert("Debe seleccionar por lo menos un MATERIAL para el Curso...");
			return;
		}
		let cont = 0;
		checkedState2.map((item, index) => {
			if (item === true) {
				form.nameEvaluation = newEvaluations[index].nameEvaluation;
				cont = cont + 1;
			}
		});
		if (cont === 0) {
			alert("Debe seleccionar por lo menos una EVALUACION para el Curso...");
			return;
		}
		if (cont > 1) {
			alert("Solo puede seleccionar una EVALUACION por el Curso...");
			return;
		}

		if (localUser.type === "1") {
			const totalTrue3 = checkedState3.filter((item) => item === true);
			if (totalTrue3.length === 0) {
				alert(
					"Debe seleccionar por lo menos una Empresa autorizada para el Curso..."
				);
				return;
			}
		} else {
			form.autorizedCompanies.push(company);
		}
		setLoading(true);

		const nameCollection = "coursesList";
		const key = "company";
		const value = form.company;
		const key2 = "nameCourse";
		const value2 = form.nameCourse;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		const keyBpw = "company";
		const valueBpw = form.company;
		const key2Bpw = "nameCourse";
		const value2Bpw = form.nameCourse;
		if (company !== "Código M SAS") {
			const responseBpw = await askDataKeyValue2Simple(
				nameCollection,
				keyBpw,
				valueBpw,
				key2Bpw,
				value2Bpw
			);
			if (responseBpw.statusResponse === true) {
				if (responseBpw.data.length > 0) {
					setError("Este Curso ya existe ofrecido por BPW...");
					handleReset();
					setLoading(false);
					closeModal();
				}
			}
		}
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Este Curso ya existe");
			} else {
				const fechita = new Date();
				const actualDate = fechita.toLocaleDateString();
				form.date = actualDate;
				materialAdd();
				companiesAdd();
				if (form.id === null) {
					createCourse(form);
				} else {
					updateCourse(form);
				}
			}
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setCourseToEdit(null);
		setCheckedState(new Array(presentations.length).fill(false));
		setCheckedState2(new Array(evaluations.length).fill(false));
	};

	const editCheckedState3 = () => {
		const newCheckedState3 = [];
		companies.map((item, index) => {
			if (courseToEdit.autorizedCompanies.includes(item.company)) {
				newCheckedState3.push(true);
			} else {
				newCheckedState3.push(false);
			}
		});
		setCheckedState3(newCheckedState3);
	};
	const editCheckedState2 = () => {
		const newCheckedState2 = [];
		evaluations.map((item, index) => {
			if (courseToEdit.nameEvaluation === item.nameEvaluation) {
				newCheckedState2.push(true);
			} else {
				newCheckedState2.push(false);
			}
		});
		setCheckedState2(newCheckedState2);
	};
	const editCheckedState = () => {
		const newCheckedState = [];
		presentations.map((item, index) => {
			if (courseToEdit.materialCourse.includes(item.name)) {
				newCheckedState.push(true);
			} else {
				newCheckedState.push(false);
			}
		});
		setCheckedState(newCheckedState);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{courseToEdit ? "Editar Curso" : "Agregar Nueva Curso"}
				</h3>
				<h3 className="titulo2">
					{courseToEdit &&
						"ALERTA: Solo se recomienda editar una Curso mientras se está creando, de lo contrario estaría creando un nuevo Curso"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="nameCourse"
								placeholder="Nombre del Curso"
								onChange={handleChange}
								value={form.nameCourse}
								className="input0"
							/>
							<input
								type="text"
								name="descriptionCourse"
								placeholder="Descripción del Curso"
								onChange={handleChange}
								value={form.descriptionCourse}
								className="input0"
							/>
							<h2 className="titulo3">Seleccione el material</h2>
							<div className="containerChequedListCourses">
								{newPresentations.map(({ name }, id) => {
									return (
										<div className="chequedlist" key={id}>
											<input
												type="checkbox"
												id={`custom-checkbox-${id}`}
												name={name}
												value={name}
												checked={checkedState[id]}
												onChange={() => clicCheckBox(id)}
											/>
											<label
												htmlFor={`custom-checkbox-${id}`}
												className="nameCheckbox"
											>
												{name}
											</label>
										</div>
									);
								})}
							</div>

							<h2 className="titulo3">Seleccione la Evaluación del curso</h2>
							<div className="containerChequedListCourses">
								{newEvaluations.map(({ nameEvaluation }, id) => {
									return (
										<div className="chequedlist" key={id}>
											<input
												type="checkbox"
												id={`custom-checkbox2-${id}`}
												name={nameEvaluation}
												value={nameEvaluation}
												checked={checkedState2[id]}
												onChange={() => clicCheckBox2(id)}
											/>
											<label
												htmlFor={`custom-checkbox2-${id}`}
												className="nameCheckbox"
											>
												{nameEvaluation}
											</label>
										</div>
									);
								})}
							</div>

							{localUser.type === "1" && (
								<div>
									<h2 className="titulo3">
										Seleccione Empresas Autorizadas para este Curso
									</h2>
									<div className="containerChequedListCourses">
										{newCompanies.map(({ company }, id) => {
											return (
												<div className="chequedlist" key={id}>
													<input
														type="checkbox"
														id={`custom-checkbox-${id}`}
														name={company}
														value={company}
														checked={checkedState3[id]}
														onChange={() => clicCheckBox3(id)}
													/>
													<label
														htmlFor={`custom-checkbox-${id}`}
														className="nameCheckbox"
													>
														{company}
													</label>
												</div>
											);
										})}
									</div>
								</div>
							)}

							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
