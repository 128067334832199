import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const CrudActivitiesTableRow = ({
	el,
	setActivityToEdit,
	openModal1,
	totalActivities,
	downPos,
	upPos,
}) => {
	const processRelease = localStorage.getItem("processRelease");
	const buttonAction = (el) => {
		setActivityToEdit(el);
		openModal1();
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-tarea1">{el.position}</td>
			<td className="col-tabla-tarea2">{el.activity}</td>
			<td className="col-tabla-tarea2">{el.descriptionActivity}</td>
			<td className="col-tabla-tarea3">{el.responseType}</td>
			<td className="col-tabla-tarea3">
				{processRelease === "no" && (
					<button
						onClick={() => buttonAction(el)}
						className="btn-editar-procesos"
					>
						Editar
					</button>
				)}
			</td>
			<td className="col-tabla-tarea3">
				{totalActivities > 1 &&
					(el.position === 1 ? (
						<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
							<ArrowDropDownIcon />
						</button>
					) : (el.position > 1) & (el.position < totalActivities) ? (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
								<ArrowDropDownIcon />
							</button>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
						</div>
					) : (
						<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
							<ArrowDropUpIcon />
						</button>
					))}
			</td>
		</tr>
	);
};

export default CrudActivitiesTableRow;
