import React, { useState } from "react";
import { Link } from "react-router-dom";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw2() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visible01, setVisible01] = useState(false);
	const [visibleProduction, setVisibleProduction] = useState(false);
	const [visibleInventroy, setVisibleInventroy] = useState(false);
	const [visibleConfiguration, setVisibleConfiguration] = useState(false);

	function openConfiguration() {
		setVisibleConfiguration(true);
	}
	function closeConfiguration() {
		setVisibleConfiguration(false);
	}

	function openProduction() {
		setVisibleProduction(true);
	}
	function closeProduction() {
		setVisibleProduction(false);
	}
	function openInventory() {
		setVisibleInventroy(true);
	}
	function closeInventrory() {
		setVisibleInventroy(false);
	}
	function openData() {
		setVisible01(true);
	}
	function closeData() {
		setVisible01(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>

			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeConfiguration}>
					<div className="linkNavbar" onMouseEnter={openConfiguration}>
						Configurar
					</div>
					{visibleConfiguration === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/process-list" className="linkNavbar">
									Procesos
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/clients" className="linkNavbar">
									Clientes
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/general-inventory" className="linkNavbar">
									Inventarios
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/time-management" className="linkNavbar">
									Tiempos
								</Link>
							</div>

							{/* <div onMouseLeave={closeInventrory}>
								<div className="linkNavbar" onMouseEnter={openInventory}>
									Inventarios
								</div>
								{visibleInventroy === true && (
									<div className="dropdownMenu2">
										<div className="div01Navbar01">
											<Link to="/inventories" className="linkNavbar">
												Maestro de Inventario
											</Link>
										</div>
										<div className="div01Navbar01">
											<Link to="/general-inventory" className="linkNavbar">
												Almacén General
											</Link>
										</div>
										<div className="div01Navbar01">
											<Link to="/inventory_permisions" className="linkNavbar">
												Permisos x Usuario
											</Link>
										</div>
										<div className="div01Navbar01">
											<Link
												to="/supervisor-company/reg_procesos"
												className="linkNavbar"
											>
												Autorizar Ajustes
											</Link>
										</div>
										<div className="div01Navbar01">
											<Link
												to="/supervisor-company/reg_procesos"
												className="linkNavbar"
											>
												Inventario Propio
											</Link>
										</div>
										<div className="div01Navbar01">
											<Link
												to="/supervisor-company/reg_procesos"
												className="linkNavbar"
											>
												Movimientos
											</Link>
										</div>
									</div>
								)}
							</div>
						 */}
						</div>
					)}
				</div>
			)}

			{ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeProduction}>
					<div className="div01Navbar">
						<Link to="/process-management" className="linkNavbar">
							Producción
						</Link>
					</div>
					{/* <div className="linkNavbar" onMouseEnter={openProduction}>
						Producción
					</div>
					{visibleProduction === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/production-orders" className="linkNavbar">
									Ordenes de Producción
								</Link>
							</div>
						</div>
					)} */}
				</div>
			)}

			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{/* {ModuleKeyProcess === "si" && (
				<div onMouseLeave={closeData}>
					<div className="linkNavbar" onMouseEnter={openData}>
						Data
					</div>
					{visible01 === true && (
						<div className="dropdownMenu">
							<div className="div01Navbar01">
								<Link to="/indicators" className="linkNavbar">
									Indicadores
								</Link>
							</div>
							<div className="div01Navbar01">
								<Link to="/DataBase" className="linkNavbar">
									DataBase
								</Link>
							</div>
						</div>
					)}
				</div>
			)} */}

			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar">
					<Link to="/admin_training_main_company" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}
			<div className="div02Navbar">
				<Link to="/admin_user" className="linkNavbar">
					{localUser.email}
				</Link>
			</div>
			<div className="divCloseNavbar">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
