import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect } from "react-router-dom";
import { useModal } from "../../generalComponents/hooks/useModal";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Modal from "../../generalComponents/Modal";
import NavbarBpw4 from "../../generalComponents/NavbarBpw4";
import Loader from "../../generalComponents/Loader";
import FormResponseAssignments from "./FormResponseAssignments";
import Message from "../../generalComponents/Message";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import { TableProcessCauses } from "./TableProcessCauses";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
	updateDocumentByIdResponse,
} from "../../../utils/actions/firebase/firebaseActions";
import { FormStops } from "./FormStops";
import { ParticularTransferInTable } from "../../production/inventories/particularInventory/particularTransferIn/particularTransferInTable";
// import EmailApp from "../../bpwEmailing/EmailApp";

export default function DetailActivitiesAssignmentTable() {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [activities, setActivities] = useState([]);
	const [activityToEdit, setActivityToEdit] = useState(null);
	const [process, setProcess] = useState(null);
	const [stopDate, setStopDate] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const jsonAssignment = localStorage.getItem("assignment");
	const assignment = JSON.parse(jsonAssignment);
	const processNumber = assignment.processNumber;
	const stage = assignment.stage;
	const dateAssignment = assignment.date;
	const [acting, setActing] = useState(assignment.statusActingTime); //estados: si, no, pausado
	const [inventoryTransfer, setInventoryTransfer] = useState(null);
	const [particularInventory, setParticularInventory] = useState(null);
	const company = localStorage.getItem("usuariosEmpresa");
	const [goToInventory, setGoToInventory] = useState(false);
	const goToInventory4 = "/particular-inventory";
	const goToInventory3 = "/inventory-user3";
	const [productionProduct, setProductionProduct] = useState(null);
	const [goTo, setGoTo] = useState(null);
	const [timeStopBySuperior, setTimeStopBySuperior] = useState(false);
	const [lastStage, setLastStage] = useState(false);
	const [activitiesLength, setActivitiesLength] = useState(0);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	useEffect(() => {
		askActivities();
		askProcess();
		askActiveClocks();
	}, []);

	const askActiveClocks = async () => {
		setLoading(true);
		const nameCollection = "assignments-" + company;

		const q = query(
			collection(db, nameCollection),
			where("assignment", "==", localUser.email),
			where("statusActingTime", "==", "si")
		);

		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				if (dataList.length === 0) {
					if (acting === "si") {
						const key1 = "id";
						const value1 = assignment.id;

						askDataKeyValue(nameCollection, key1, value1).then(
							(confirmAssign) => {
								const pos = confirmAssign.data[0].eventsTime.length - 1;
								const lastEventCause =
									confirmAssign.data[0].eventsTime[pos].stopCause;
								if (lastEventCause === "superior") {
									setGoTo(
										"/stop-clock/" +
											confirmAssign.data[0].eventsTime[pos].comment
									);
									setTimeStopBySuperior(true);
								}
							}
						);
					}
				}

				setLoading(false);
			},
			(error) => {
				setError(error);
				setLoading(false);
			}
		);
	};

	const askActivities = async () => {
		setLoading(true);
		const nameCollection = "activitiesRegister-" + company;
		const key = "processNumber";
		const value = processNumber;
		const key2 = "stage";
		const value2 = stage;
		const q = query(
			collection(db, nameCollection),
			where(key, "==", value),
			where(key2, "==", value2),
			orderBy("position", "asc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setActivities(dataList);
				// console.log("--- dataList: ", dataList);
				setActivitiesLength(dataList.length);

				askDataKeyValue(
					"stages-" + company,
					"process",
					dataList[0].process
				).then((resp) => {
					// console.log("resp: ", resp);
					let stagesListLength = resp.data.length;
					// console.log("Cantidad de etapas: ", stagesListLength);
					// console.log("posición etapa: ", assignment.position);
					if (stagesListLength === assignment.position) {
						setLastStage(true);
					}
				});

				// let resp = askDataKeyValue2Simple(
				// 	"stages-" + company,
				// 	"process",
				// 	processNumber,
				// 	"stage",
				// 	stage
				// );
				// let stagesListLength = resp.data.length;
				// console.log("Cantidad de etapas: ", stagesListLength);

				setLoading(false);
			},
			(error) => {
				// console.log("resultado negativo", error);
				setActivities(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "processRegister-" + company;
		const key = "processNumber";
		const value = processNumber;
		const q = query(collection(db, nameCollection), where(key, "==", value));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setProcess(dataList[0]);
				// console.log("process:", dataList[0]);
				if (dataList[0].inventoryControl === "SI") {
					let nameCollection = "productionProduct-" + company;
					askDataKeyValue2Simple(
						nameCollection,
						"productionOrder",
						dataList[0].productionOrder,
						"refProductionProduct",
						dataList[0].refProductionProduct
					).then((response) => {
						setProductionProduct(response.data[0]);
					});
					// askInventoryTransfer();
					// askParticularInventory();
				}
				setLoading(false);
			},
			(error) => {
				setProcess(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askInventoryTransfer = async () => {
		const nameCollection = "inventoryTransfer-" + company;
		const q = query(
			collection(db, nameCollection),
			where("userTo", "==", localUser.email),
			where("processNumber", "==", processNumber),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setInventoryTransfer(dataList);

				setLoading(false);
			},
			(error) => {
				setInventoryTransfer(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askParticularInventory = async () => {
		const nameCollection = "inventoryParticular-" + company;
		// console.log("colección: ", nameCollection);
		const q = query(
			collection(db, nameCollection),
			where("user", "==", localUser.email),
			where("processNumber", "==", processNumber)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setParticularInventory(dataList);

				setLoading(false);
			},
			(error) => {
				setParticularInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	function buttonAction(el) {
		setActivityToEdit(el);
		openModal1();
	}

	const askStatusActingTime = async () => {
		const collectionName = "assignments-" + company;
		const key2 = "assignment";
		const value2 = localUser.email;
		const key3 = "statusActingTime";
		const value3 = "si";
		const resp = await askDataKeyValue2Simple(
			collectionName,
			key2,
			value2,
			key3,
			value3
		);
		return resp.data;
	};

	const buttonInicio = async () => {
		setLoading(true);
		const statusActingTime = await askStatusActingTime();
		if (statusActingTime.length > 0) {
			const warn =
				"Ya tiene reloj activo en: " +
				statusActingTime[0].processNumber +
				", debe detener el reloj allí primero...";
			alert(warn);
			setLoading(false);
			return;
		}

		const fechita = new Date();
		const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
		const initialRange = {
			i: fechitaMlSeconds,
			f: fechitaMlSeconds,
			stopCause: "",
			comment: "",
		};
		const eventsTime = assignment.eventsTime;
		eventsTime.push(initialRange);
		assignment.eventsTime = eventsTime;
		assignment.statusActingTime = "si";
		// console.log("eventsTime: ", eventsTime);
		// console.log("assignmet para grabar: ", assignment);

		// const currentDateObj = new Date();
		// const numberOfMlSeconds = currentDateObj.getTime();
		// console.log("number of milseconds: ", numberOfMlSeconds);
		// const addMlSeconds = 60 * 60000;
		// const newDateObj = new Date(numberOfMlSeconds + addMlSeconds);
		// console.log("newDateObj:", newDateObj);

		const response = await updateDocumentByIdResponse(
			"assignments-" + company,
			assignment.id,
			assignment
		);
		if (response.statusResponse === true) {
			setActing("si");
			localStorage.setItem("assignment", JSON.stringify(assignment)); //por si se hace update de la página 😉
			window.location.replace(""); //REFRESCA LA PAGINA
		} else {
			setError(response.error);
		}
		setLoading(false);
	};

	const actionStop = async (form) => {
		// const fechita = new Date();
		setLoading(true);
		const fechitaMlSeconds = stopDate.getTime(); // para pasar a milisegundos
		const eventsTime = assignment.eventsTime;
		// console.log("eventsTime: ", eventsTime);
		const totalEventsTime = eventsTime.length - 1;
		eventsTime[totalEventsTime].f = fechitaMlSeconds; //cambia el valor final de tiempo del evento último
		eventsTime[totalEventsTime].stopCause = form.stopCause;
		eventsTime[totalEventsTime].comment = form.comment;
		//dependiendo de cause, lanzar el correo y colocar form.comment
		const timeMls =
			eventsTime[totalEventsTime].f - eventsTime[totalEventsTime].i;
		assignment.statusActingTime = "pausado";
		assignment.eventsTime = eventsTime;
		assignment.totalTime = assignment.totalTime + timeMls;
		const response = await updateDocumentByIdResponse(
			"assignments-" + company,
			assignment.id,
			assignment
		);
		if (response.statusResponse === true) {
			setActing("pausado");
			localStorage.setItem("assignment", JSON.stringify(assignment)); //por si se hace update de la página 😉
		} else {
			setError(response.error);
		}
		// console.log("response: ", response);
		window.location.replace(""); //REFRESCA LA PAGINA
		setLoading(false);
		// falta hacer que se muestre el loading
		// falta que se despliegue el poruqé la detención
	};

	const buttonStop = () => {
		const fechita = new Date();
		setStopDate(fechita);
		openModal2();
	};

	const buttonEnd = async () => {
		setLoading(true);
		const fechita = new Date();
		const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
		const eventsTime = assignment.eventsTime;
		const totalEventsTime = eventsTime.length - 1;
		eventsTime[totalEventsTime].f = fechitaMlSeconds; //cambia el valor final de tiempo del evento último
		const timeMls =
			eventsTime[totalEventsTime].f - eventsTime[totalEventsTime].i;
		assignment.statusActingTime = "pausado";
		assignment.eventsTime = eventsTime;
		assignment.totalTime = assignment.totalTime + timeMls;
		const response = await updateDocumentByIdResponse(
			"assignments-" + company,
			assignment.id,
			assignment
		);
		if (response.statusResponse === true) {
			setActing("no");
			localStorage.setItem("assignment", JSON.stringify(assignment)); //por si se hace update de la página 😉
		} else {
			setError(response.error);
		}
		// console.log("response: ", response);
		window.location.replace(""); //REFRESCA LA PAGINA
		setLoading(false);
	};

	const redirectToInventory = () => {
		setGoToInventory(true);
	};

	return (
		<div>
			{localUser.type === "4" || localUser.type === "3" ? (
				loading ? (
					<Loader />
				) : (
					<div>
						{goTo && timeStopBySuperior && <Redirect to={goTo} />}
						{localUser.type === "4" ? <NavbarBpw4 /> : <NavbarBpw3 />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{inventoryTransfer && inventoryTransfer.length > 0 ? (
							<ParticularTransferInTable
								inventoryTransfer={inventoryTransfer}
							/>
						) : (
							<div>
								<Modal isOpen={isOpenModal2} closeModal2={closeModal2}>
									<FormStops
										closeModal2={closeModal2}
										actionStop={actionStop}
									/>
								</Modal>
								{activityToEdit && (
									<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
										{activitiesLength && (
											<FormResponseAssignments
												closeModal1={closeModal1}
												activityToEdit={activityToEdit}
												setActivityToEdit={setActivityToEdit}
												company={company}
												process={process}
												lastStage={lastStage}
												activitiesLength={activitiesLength}
											/>
										)}
									</Modal>
								)}
								<div className="bloque2">
									<h3 className="titleProcess">ACTIVIDADES ({company})</h3>
									<table className="tableProcess">
										<thead>
											<tr className="fila-tabla-procesos">
												<th className="titulo-tabla-procesos">Fecha</th>
												<th className="titulo-tabla-procesos"># Proceso</th>
												<th className="titulo-tabla-procesos">
													Nombre Proceso
												</th>
												<th className="titulo-tabla-procesos">Nombre Etapa</th>
												{process && process.inventoryControl == "SI" && (
													<th className="titulo-tabla-procesos">Producto</th>
												)}
											</tr>
										</thead>
										<tbody>
											<tr>
												<td className="col-tabla-regtarea1">
													{dateAssignment}
												</td>
												<td className="col-tabla-regtarea2">{processNumber}</td>
												<td className="col-tabla-regtarea2">
													{activities[0] && activities[0].process}
												</td>
												<td className="col-tabla-regtarea2">
													{activities[0] && activities[0].stage}
												</td>
												{process &&
													process.inventoryControl == "SI" &&
													productionProduct && (
														<td className="col-tabla-regtarea2">
															{productionProduct.description} -- Ordenado:{" "}
															{process.quantity} -- Producido{" "}
															{process.finishProduct}
														</td>
													)}
											</tr>
										</tbody>
									</table>

									{particularInventory && particularInventory.length > 0 && (
										<div>
											<a
												className="titulo21"
												onClick={() => redirectToInventory()}
											>
												Tramitar inventario pendiente...
											</a>
										</div>
									)}

									{/* <hr />
							<EmailApp />
							<hr /> */}

									{/* esto es para cuando hay gestion de calidad */}

									{/* {localUser.type === "3" && process && (
										<TableProcessCauses process={process} company={company} />
									)} */}

									{assignment.eventsTime && (
										<div>
											<hr />
											<h2 className="titulo3">Control de Tiempos:</h2>
											<br />
											{acting === "si" ? (
												<div>
													<button
														onClick={() => buttonStop()}
														className="btnReinicio"
													>
														DETENER
													</button>
													<button
														onClick={() => buttonEnd()}
														className="btnCloseRegprocesos"
													>
														TERMINAR
													</button>
												</div>
											) : (
												<div>
													{acting === "pausado" ? (
														<div>
															<button
																onClick={() => buttonInicio()}
																className="btnReinicio"
															>
																REINICIAR
															</button>
														</div>
													) : (
														<div>
															<button
																onClick={() => buttonInicio()}
																className="btnOpenRegprocesos"
															>
																INICIO
															</button>
														</div>
													)}
												</div>
											)}

											{assignment.bossComment && (
												<div>
													<hr className="hrRed" />
													<label className="titulo1">Comentario:</label>
													<label className="titulo13">
														{assignment.bossComment}
													</label>
													<hr className="hrRed" />
												</div>
											)}
										</div>
									)}
									<table className="bloque2">
										<thead>
											<tr>
												<th className="th1">#</th>
												<th className="th1">Actividad</th>
												{/* <th className="th1">Descripcion</th> */}
												<th className="th1">Cantidad</th>
												<th className="th1">Avance</th>
												<th className="th1">Comentario</th>
												<th className="th1">Fecha</th>
												<th className="th1">Acción</th>
											</tr>
										</thead>
										<tbody className="bloque2">
											{activities.map((activity) => (
												<tr
													className={
														(activity.amount > 0) &
														(activity.amount < activity.totalAmount)
															? "amountPartial"
															: activity.amount === activity.totalAmount
															? "amountTotal"
															: "amountCero"
													}
													key={activity.id}
												>
													<td className="tarea-col0">{activity.position}</td>
													<td className="tarea-col1">{activity.activity}</td>
													{/* <td className="tarea-col1">
														{activity.descriptionActivity}
													</td> */}
													<td className="tarea-col2">{activity.amount}</td>
													<td className="tarea-col2">
														{Math.trunc(
															(activity.amount * 100) / activity.totalAmount
														)}{" "}
														%
													</td>
													<td className="tarea-col4">{activity.comment}</td>
													<td className="tarea-col2">{activity.date}</td>
													<td className="tarea-col3">
														{acting === "si" && (
															<button
																onClick={() => buttonAction(activity)}
																className="btn-ver-regprocesos"
															>
																Modificar
															</button>
														)}
														{!assignment.eventsTime && (
															<button
																onClick={() => buttonAction(activity)}
																className="btn-ver-regprocesos"
															>
																Modificar
															</button>
														)}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								</div>
							</div>
						)}
					</div>
				)
			) : (
				<Redirect to="/intruso" />
			)}
			{goToInventory === true && (
				<div>
					{localUser.type === "4" ? (
						<Redirect to={goToInventory4} />
					) : (
						<Redirect to={goToInventory3} />
					)}
				</div>
			)}
		</div>
	);
}
