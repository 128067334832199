import React, { useState, useEffect } from "react";
import { Redirect, useParams } from "react-router";
import { borrarUsuario } from "../../../utils/actions";
import {
	askDataKeyValue,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import CrudUsuariosForm from "./CrudUsuariosForm";
import CrudUsuariosTable from "./CrudUsuariosTable";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";

const CrudUsuariosApp = (props) => {
	let { company } = useParams();
	const [db, setDb] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const empresa = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";
		const key = "company";
		const value = company;
		const respuesta = await askDataKeyValue(nameCollection, key, value);
		// const respuesta = await askUsuarios(empresa);
		if (respuesta.statusResponse === true) {
			setDb(respuesta.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askUsers();
	}, []);

	const updateUser = async (data) => {
		setLoading(true);
		const collection = "users";
		const docId = data.id;
		const changes = data;
		await updateDocumentById(collection, docId, changes);
		await askUsers();
		setLoading(false);
	};

	const deleteUser = async (id) => {
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar el registro con el id '${id}'?`
		);

		if (isDelete) {
			setLoading(true);
			await borrarUsuario(id);
			await askUsers();
			setLoading(false);
		} else {
			return;
		}

		// if (isDelete) {
		// 	let newData = db.filter((el) => el.id !== id);
		// 	setDb(newData);
		// } else {
		// 	return;
		// }
	};

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />
					<article>
						<CrudUsuariosForm
							updateUser={updateUser}
							userToEdit={userToEdit}
							setUserToEdit={setUserToEdit}
							company={company}
						/>
						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{db && (
							<CrudUsuariosTable
								company={company}
								user={db}
								setUserToEdit={setUserToEdit}
								deleteUser={deleteUser}
							/>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default CrudUsuariosApp;
