import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import Modal from "../../generalComponents/Modal";
import { EvaluationsForm } from "./EvaluationsForm";
import {
	addDataKey2,
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { EvaluationsTable } from "./EvaluationsTable";
import { ViewEvaluation } from "./ViewEvaluation";

const EvaluationsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [db1, setDb1] = useState(null); // Lista de Evaluaciones
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [evaluationToEdit, setEvaluationToEdit] = useState(null);
	const [viewEvaluation, setViewEvaluation] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");

	const askEvaluations = async () => {
		setLoading(true);
		const nameCollection = "courseEvaluations";
		const key = "company";
		const value = company;
		const q = query(collection(db, nameCollection), where(key, "==", value));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDb1(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("resultado negativo", error);
				setDb1(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askEvaluations();
	}, []);

	const clickCrear = () => {
		setEvaluationToEdit(null);
		openModal1();
	};

	const createEvaluation = async (data) => {
		setLoading(true);

		const nameCollection = "courseEvaluations";
		const key1 = "company";
		const value1 = company;
		const key2 = "nameEvaluation";
		const value2 = data.nameEvaluation;
		const response = await addDataKey2(
			nameCollection,
			data,
			key1,
			value1,
			key2,
			value2
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askEvaluations();
		setLoading(false);
	};

	const updateEvaluation = async (data) => {
		setLoading(true);
		const nameCollection = "courseEvaluations";
		const docId = data.id;
		const changes = data;
		const key = "company";
		const value = company;
		const key2 = "nameEvaluation";
		const value2 = data.nameEvaluation;
		const response = await askDataKeyValue2Simple(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta Evaluación ya existe");
				setLoading(false);
			} else {
				//sacar nombre inicial de la evaluación
				const evaluationName01 = evaluationToEdit.nameEvaluation;
				if (evaluationName01 !== value2) {
					const response2 = await askDataKeyValue2Simple(
						"coursesEvaluationQuestions",
						key,
						value,
						key2,
						evaluationName01
					);
					if (response2.statusResponse === true) {
						const questionsToModify = response2.data;
						for (const question of questionsToModify) {
							question.nameEvaluation = value2;
							await updateDocumentById(
								"coursesEvaluationQuestions",
								question.id,
								question
							);
							const response3 = await askDataKeyValue3Simple(
								"coursesEvaluationAnswers",
								key,
								value,
								"question",
								question.question,
								key2,
								evaluationName01
							);
							if (response3.statusResponse === true) {
								const coursesEvaluationAnswersTomodify = response3.data;
								coursesEvaluationAnswersTomodify.map(async (answer) => {
									answer.nameEvaluation = value2;
									await updateDocumentById(
										"coursesEvaluationAnswers",
										answer.id,
										answer
									);
								});
							}
						}
					}
				}
				await updateDocumentById(nameCollection, docId, changes);
				askEvaluations();
				setLoading(false);
			}
		} else {
			askEvaluations();
			setLoading(false);
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	return (
		<div>
			{!viewEvaluation ? (
				<div>
					{/* <p>Aquí se gestionan listas de procesos</p> */}
					<h3 className="titleProcess">Evaluaciones de Cursos</h3>

					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<EvaluationsForm
							createEvaluation={createEvaluation}
							updateEvaluation={updateEvaluation}
							evaluationToEdit={evaluationToEdit}
							setEvaluationToEdit={setEvaluationToEdit}
							closeModal={closeModal1}
						/>
					</Modal>
					{loading ? (
						<Loader />
					) : (
						<div>
							{error && <Message mensaje={error} bgColor="#dc3545" />}
							{db1 ? (
								<div>
									<button onClick={clickCrear} className="btn-crear-proceso">
										Crear Nueva Evaluación
									</button>

									{/* <button onClick={clickCrear} className="btn-crear-proceso">
									Crear Nueva Evaluación
								</button> */}

									<EvaluationsTable
										evaluations={db1}
										setEvaluationToEdit={setEvaluationToEdit}
										openModal1={openModal1}
										setViewEvaluation={setViewEvaluation}
										company={company}
									/>
								</div>
							) : (
								<h3>No hay Evaluciones de Cursos para mostrar</h3>
							)}
						</div>
					)}
				</div>
			) : (
				<ViewEvaluation setViewEvaluation={setViewEvaluation} />
			)}
		</div>
	);
};

export default EvaluationsApp;
