import Reac, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	where,
	query,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
	addDoc,
	getDocs,
	updateDoc,
	doc,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import Select from "react-select";

import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";

export const ProcessReplication = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const initialForm = {
		originCompany: null,
		process: null,
		destinationCompany: null,
	};
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const [form, setForm] = useState(initialForm);
	const [selectedCompanyOrigen, setSelectedCompanyOrigen] = useState(null);
	const [selectedCompanDestination, setSelectedCompanyDestination] =
		useState(null);
	const [companyList, setCompanyList] = useState(null);
	const [selectedProcess, setSelectedProcess] = useState(null);
	const [processList, setProcessList] = useState(null);

	useEffect(() => {
		askCompanies();
	}, []);

	const handleReset = (e) => {
		setForm(initialForm);
	};

	// 1. seleccionar una empresa

	const askCompanies = async () => {
		const nameCollection = "accounts";

		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setCompanyList(dataList);
				convertSelectCompaniesOrigen(dataList);
				setLoading(false);
			},
			(error) => {
				setCompanyList(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const convertSelectCompaniesOrigen = (companies) => {
		let selectCompany = [];
		companies.map((item) => {
			const lineCompany = {
				value: item.company,
				label: item.company,
			};
			selectCompany.push(lineCompany);
		});
		setSelectedCompanyOrigen(selectCompany);
	};

	const handleSelectOriginCompany = async (e) => {
		setForm({
			...form,
			originCompany: e.value,
		});

		await askProcess(e.value);
	};
	// 2. Seleccionar un proceso de la empresa

	const askProcess = async (compay) => {
		const nameCollection = "process-" + compay;

		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				console.log("1. processList: ", dataList);
				setProcessList(dataList);
				convertSelectProcess(dataList);
				setLoading(false);
			},
			(error) => {
				setProcessList(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const convertSelectProcess = (process) => {
		let selectProcess = [];
		process.map((item) => {
			const lineProcess = {
				value: item.process,
				label: item.process,
			};
			selectProcess.push(lineProcess);
		});
		setSelectedProcess(selectProcess);
	};

	const handleSelectProcess = async (e) => {
		setForm({
			...form,
			process: e.value,
		});

		convertSelectCompaniesDestination(companyList);
	};

	// 3. Seleccionar empresa de destino, no va a poder mostrar la misma empresa

	const convertSelectCompaniesDestination = (companies) => {
		let selectCompany = [];
		companies.map((item) => {
			if (item.company != form.originCompany) {
				const lineCompany = {
					value: item.company,
					label: item.company,
				};
				selectCompany.push(lineCompany);
			}
		});
		setSelectedCompanyDestination(selectCompany);
	};

	const handleSelectDestinationCompany = async (e) => {
		setForm({
			...form,
			destinationCompany: e.value,
		});
	};

	// 4. Grabar el proceso en la nueva empresa.

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// verificar que el nombre del proceso no exista en la empresa destino
		console.log("form: ", form);
		const nameCollection1 = "process-" + form.destinationCompany;
		const key1 = "process";
		const value1 = form.process;
		const resp1 = await askDataKeyValue(nameCollection1, key1, value1);
		console.log("resp1: ", resp1.data);
		if (resp1.data.length > 0) {
			const alert1 =
				"El proceso: " +
				form.process +
				", ya existe en la empresa: " +
				form.destinationCompany;
			alert(alert1);
			handleReset();
			return;
		}
		//consultar el proceso en procesos origen y grabarlo en la nueva empresa colocar el proceso realease:"no" active:"no"

		console.log("processList:", processList);
		let selectedProcess = processList.filter(
			(process) => process.process == form.process
		)[0];
		selectedProcess.active = "no";
		selectedProcess.release = "no";
		delete selectedProcess["id"];

		const nameCollection2 = "process-" + form.destinationCompany;

		addDoc(collection(db, nameCollection2), selectedProcess).then(
			async (res) => {
				if (res.id) {
					const docRef = doc(db, nameCollection2, res.id);
					await updateDoc(docRef, {
						id: res.id,
					});
				}
			}
		);

		// consultar stages del proceso y grabar en la nueva empresa
		const nameCollection3 = "stages-" + form.originCompany;
		const nameCollection4 = "stages-" + form.destinationCompany;
		const resp2 = await askDataKeyValue(nameCollection3, key1, value1);
		resp2.data.map(async (stage) => {
			delete stage["id"];
			addDoc(collection(db, nameCollection4), stage).then(async (res) => {
				if (res.id) {
					const docRef = doc(db, nameCollection4, res.id);
					await updateDoc(docRef, {
						id: res.id,
					});
				}
			});
		});

		// consultar activities del proceso y grabar en la nueva empresa
		const nameCollection5 = "activities-" + form.originCompany;
		const nameCollection6 = "activities-" + form.destinationCompany;

		const resp3 = await askDataKeyValue(nameCollection5, key1, value1);
		console.log("Activides: ", resp3.data);
		resp3.data.map(async (activity) => {
			delete activity["id"];
			addDoc(collection(db, nameCollection6), activity).then(async (res) => {
				if (res.id) {
					const docRef = doc(db, nameCollection6, res.id);
					await updateDoc(docRef, {
						id: res.id,
					});
				}
			});
		});

		const alert2 =
			"El proceso: " +
			form.process +
			", se ha grabado en: " +
			form.destinationCompany;
		alert(alert2);
		handleReset();
		setLoading(false);
	};

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />
					<div className="titulo1">
						<article className="titleProcess">
							REPLICAR UN PROCESO CREADO
						</article>
						{loading ? (
							<Loader />
						) : (
							<form onSubmit={handleSubmit} className="form-cuentas">
								<div>
									{!form.originCompany ? (
										selectedCompanyOrigen &&
										selectedCompanyOrigen.length > 0 ? (
											<Select
												className="input1_3"
												options={selectedCompanyOrigen}
												name="refProductionProduct"
												onChange={handleSelectOriginCompany}
												placeholder="Seleccione la Compañia de Origen"
											/>
										) : (
											<article> No hay Compañías para mostrar... </article>
										)
									) : (
										<div>
											<article className="titulo10">
												Compañía de Origen: {form.originCompany}
											</article>
											{!form.process ? (
												selectedProcess && selectedProcess.length > 0 ? (
													<Select
														className="input1_3"
														options={selectedProcess}
														name="refProductionProduct"
														onChange={handleSelectProcess}
														placeholder="Seleccione un proceso"
													/>
												) : (
													<article> No hay Pocesos... </article>
												)
											) : (
												<div>
													<article className="titulo10">
														Proceso: {form.process}
													</article>
													{!form.destinationCompany ? (
														selectedCompanDestination &&
														selectedCompanDestination.length > 0 ? (
															<Select
																className="input1_3"
																options={selectedCompanDestination}
																name="destinationCompany"
																onChange={handleSelectDestinationCompany}
																placeholder="Seleccione Compañía de Destino"
															/>
														) : (
															<article> No hay Compañía... </article>
														)
													) : (
														<div>
															<article className="titulo10">
																Compañia Destino: {form.destinationCompany}
															</article>
														</div>
													)}
												</div>
											)}
										</div>
									)}
								</div>

								<hr />

								<hr />
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
								{/* <input
									type="reset"
									value="Cerrar"
									onClick={handleClose}
									className="btn-close-reg-asign"
								/> */}
							</form>
						)}

						{error && <Message mensaje={error} bgColor="#dc3545" />}
					</div>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
