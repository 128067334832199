import React from "react";

const CrudAreasTableRow = ({ el, setData2toEdit, deleteData }) => {
	let { id, area, responsable } = el;
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-cuenta">{area}</td>
			<td className="col-tabla-cuenta">{responsable}</td>
			<td className="col-tabla-cuenta">
				<button onClick={() => setData2toEdit(el)} className="btn-editar">
					Editar
				</button>
				<button onClick={() => deleteData(id)} className="btn-close-accounts">
					Eliminar
				</button>
			</td>
		</tr>
	);
};

export default CrudAreasTableRow;
