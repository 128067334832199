import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { InventoryTransferInTable } from "./inventoryTransferInTable";

export const InventoryTransferInApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [inventoryTransfer, setInventoryTransfer] = useState(null); //transferencias de ingreso almacén principal
	const [error, setError] = useState(null); //transferencias de ingreso almacén principal
	const [loading, setLoading] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		askInventoryTransfer();
	}, []);

	const askInventoryTransfer = async () => {
		const nameCollection = "inventoryTransfer-" + company;
		const q = query(
			collection(db, nameCollection),
			where("to", "==", "warehouse"),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setInventoryTransfer(dataList);

				setLoading(false);
			},
			(error) => {
				setInventoryTransfer(null);
				setError(error);
				setTimeout(function () {
					setLoading(false);
				}, 1000);
			}
		);
	};
	return (
		<div className="conten0">
			<div className="conten1">
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{loading ? (
					<Loader />
				) : inventoryTransfer && inventoryTransfer.length > 0 ? (
					<div>
						<article className="titulo11">
							Inventario Pendiente por Recibir
						</article>
						<InventoryTransferInTable inventoryTransfer={inventoryTransfer} />
					</div>
				) : (
					<article className="titulo11">
						No hay Transferencias de Inventario pendientes por recibir...
					</article>
				)}
			</div>
		</div>
	);
};
