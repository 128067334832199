import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
	askDataKeyValue3Simple,
	askDataKeyValue4Simple,
	deleteDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudActivitiesAdminTableRow = ({
	el,
	setActivityToEdit,
	openModal1,
	totalActivities,
	downPos,
	upPos,
	setRefresh,
	company,
}) => {
	const processRelease = localStorage.getItem("processRelease");
	const buttonAction = (el) => {
		setActivityToEdit(el);
		openModal1();
	};

	const activitieDelete = async (el) => {
		let isDelete = window.confirm(
			"¿Está segur@ de Borrar esta actividad? ... Esto borrará toda información relacionada con la actividad y sus registros..."
		);

		if (isDelete) {
			let isDeleteConfirmation = window.confirm(
				"¿C o m p l e t a m e n t e   s e g u r @ ?"
			);
			if (isDeleteConfirmation) {
				try {
					let activity = el;
					const resp1 = await askDataKeyValue3Simple(
						"activitiesRegister-" + company,
						"process",
						activity.process,
						"stage",
						activity.stage,
						"activity",
						activity.activity
					);
					let activitiesList = resp1.data;

					for (const activity of activitiesList) {
						deleteDocumentById("activitiesRegister-" + company, activity.id);
					}

					deleteDocumentById("activities-" + company, activity.id);
					setRefresh(true);
				} catch (error) {
					alert("Esta tarea está subiéndose a la base de datos");
					setRefresh(true);
				}
			} else {
				return;
			}
		} else {
			return;
		}
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-tarea1">{el.position}</td>
			<td className="col-tabla-tarea2">{el.activity}</td>
			<td className="col-tabla-tarea2">{el.descriptionActivity}</td>
			<td className="col-tabla-tarea3">{el.responseType}</td>
			<td className="col-tabla-tarea3">
				<div>
					{processRelease === "no" && (
						<button
							onClick={() => buttonAction(el)}
							className="btn-editar-procesos"
						>
							Editar
						</button>
					)}
				</div>
			</td>
			<td className="col-tabla-tarea3">
				{totalActivities > 1 ? (
					el.position === 1 ? (
						<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
							<ArrowDropDownIcon />
						</button>
					) : (el.position > 1) & (el.position < totalActivities) ? (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
								<ArrowDropDownIcon />
							</button>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
						</div>
					) : (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
							<button
								className="btn-delete-process"
								onClick={() => activitieDelete(el)}
							>
								Borrar Actividad
							</button>
						</div>
					)
				) : (
					<button
						className="btn-delete-process"
						onClick={() => activitieDelete(el)}
					>
						Borrar Actividad
					</button>
				)}
			</td>
		</tr>
	);
};

export default CrudActivitiesAdminTableRow;
