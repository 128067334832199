import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import Loader from "../../generalComponents/Loader";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";

export const AsignationForm = ({ closeModal, createAssignment }) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const initialForm = {
		company: company,
		course: "",
		status: "abierto",
		creationDate: null,
		initialDate: null,
		finalDate: null,
		studentList: [],
		calification: 0,
		assignedBy: localUser.email,
		califiactionDAte: null,
		intents: 0,
		maxIntents: 2,
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [users, setUsers] = useState(null); //lista de estudiantes
	const [coursesList, setCoursesList] = useState(null);
	const [userToEdit, setUserToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [checkedState, setCheckedState] = useState([]); //cheqState true/false cursos
	const [checkedState2, setCheckedState2] = useState([]); //cheqState true/false Estudiantes

	const askCourses = async () => {
		setLoading(true);
		const q = query(
			collection(db, "coursesList"),
			where("company", "==", company)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				const q2 = query(
					collection(db, "coursesList"),
					where("company", "==", "Código M SAS"),
					where("autorizedCompanies", "array-contains", company)
				);
				onSnapshot(
					q2,
					(querySnapshot2) => {
						let dataList2 = querySnapshot2.docs.map((d2) => ({
							...d2.data(),
						}));
						const suma = dataList.concat(dataList2);
						setCoursesList(suma);
						setCheckedState(new Array(suma.length).fill(false));
						setLoading(false);
					},
					(error) => {
						setCoursesList(null);
						setError(error);
						setLoading(false);
					}
				);
			},
			(error) => {
				setCoursesList(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askUsers = async () => {
		setLoading(true);
		const nameCollection = "users";
		const key = "company";
		const value = company;
		const respuesta = await askDataKeyValue(nameCollection, key, value);
		// const respuesta = await askUsuarios(empresa);
		if (respuesta.statusResponse === true) {
			setUsers(respuesta.data);
			setCheckedState2(new Array(respuesta.data.length).fill(false));
			setLoading(false);
		} else {
			setUsers(null);
			setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askCourses();
		askUsers();
	}, [createAssignment]);

	const clicCheckBox = (id) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	const clicCheckBox2 = (id) => {
		const updatedCheckedState = checkedState2.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState2(updatedCheckedState);
	};

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const studentsAdd = () => {
		form.studentList = [];
		checkedState2.map((item, index) => {
			if (item === true) {
				form.studentList.push(users[index].email);
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let cont = 0;
		checkedState.map((item, index) => {
			if (item === true) {
				form.course = coursesList[index].nameCourse;
				cont = cont + 1;
			}
		});
		if (cont === 0) {
			alert("Debe seleccionar un CURSO...");
			return;
		}
		if (cont > 1) {
			alert("Solo puede seleccionar un CURSO...");
			return;
		}
		const totalTrue = checkedState2.filter((item) => item === true);
		if (totalTrue.length === 0) {
			alert(
				"Debe seleccionar por lo menos un USUARIO para que realice el curso..."
			);
			return;
		}
		if (form.initialDate === null) {
			alert("Debe seleccionar una fecha inicial para el Curso");
			return;
		}
		if (form.finalDate === null) {
			alert("Debe seleccionar una fecha final para el Curso");
			return;
		}
		if (form.initialDate > form.finalDate) {
			alert("La fecha final No puede ser anterior a la  Fecha inicial...");
			return;
		}

		setLoading(true);

		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		form.creationDate = actualDate;

		const res = new Date(form.initialDate);
		res.setDate(res.getDate() + 1);
		form.initialDate = res;
		const res2 = new Date(form.finalDate);
		res2.setDate(res2.getDate() + 1);
		form.finalDate = res2;

		// form.initialDate = new Date(form.initialDate);
		// form.finalDate = new Date(form.finalDate);
		studentsAdd();
		createAssignment(form);
		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setCheckedState(new Array(coursesList.length).fill(false));
		setCheckedState2(new Array(users.length).fill(false));
	};

	return (
		<div>
			<h3 className="titulo1">Asignación de cursos</h3>

			<form onSubmit={handleSubmit} className="form-procesos">
				<h2 className="title2-account">{company}</h2>
				{loading ? (
					<Loader />
				) : (
					<div>
						<div className="containerChecks2">
							<div className="container1Check">
								<h2 className="titulo3">Seleccione un CURSO a asignar</h2>
								{coursesList && (
									<div className="containerChequedList">
										{coursesList.map(({ nameCourse }, id) => {
											return (
												<div className="chequedlist" key={id}>
													<input
														type="checkbox"
														id={`custom-checkbox2-${id}`}
														name={nameCourse}
														value={nameCourse}
														Checked={checkedState[id]}
														onChange={() => clicCheckBox(id)}
													/>
													<label
														htmlFor={`custom-checkbox2-${id}`}
														className="nameCheckbox"
													>
														{nameCourse}
													</label>
												</div>
											);
										})}
									</div>
								)}
							</div>

							<div className="container1Check">
								<h2 className="titulo3">Seleccione los usuarios</h2>
								{users && (
									<div className="containerChequedList">
										{users.map(({ name, email }, id) => {
											return (
												<div className="chequedlist" key={id}>
													<input
														type="checkbox"
														id={`custom-checkbox-${id}`}
														name={email}
														value={email}
														checked={checkedState2[id]}
														onChange={() => clicCheckBox2(id)}
													/>
													<label
														htmlFor={`custom-checkbox-${id}`}
														className="nameCheckbox"
													>
														<p>
															{email} --- {name}
														</p>
													</label>
												</div>
											);
										})}
									</div>
								)}
							</div>
						</div>
						<hr />
						<h2 className="titulo3">
							Seleccione cuándo se debe realizar el curso
						</h2>

						<label className="title3-account">Fecha Inicial</label>
						<input
							type="date"
							name="initialDate"
							placeholder="Fecha Inicial"
							onChange={handleChange}
							value={form.initialDate}
							className="input1"
						/>
						<label className="title3-account">Fecha Final</label>
						<input
							type="date"
							name="finalDate"
							placeholder="Fecha final"
							onChange={handleChange}
							value={form.finalDate}
							className="input1"
						/>
						<hr />
						<input
							type="submit"
							value="Enviar"
							className="btn-send-reg-asign"
						/>
						<input
							type="reset"
							value="Limpiar"
							onClick={handleReset}
							className="btn-clean-reg-asign"
						/>
						<input
							type="reset"
							value="Cerrar"
							onClick={closeModal}
							className="btn-close-reg-asign"
						/>
					</div>
				)}
			</form>
		</div>
	);
};
