import React, { useState, useEffect } from "react";
// import ModulesApp from "../modules/ModulesApp";
import CrudAreasApp from "../areas/CrudAreasApp";
import CrudSucursalesApp from "../sucursales/CrudSucursalesApp";
import { BlocksApp } from "../blocks/BlocksApp";

const initialForm = {
	pos: null,
	company: "",
	date: "",
	IdType: "",
	IdNumber: "",
	country: "",
	city: "",
	direction: "",
	email: "",
	phone: "",
	contactName: "",
};

export const CrudCuentasForm = ({
	createData,
	updateData,
	dataToEdit,
	setDataToEdit,
}) => {
	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		if (dataToEdit) {
			setForm(dataToEdit);
		} else {
			setForm(initialForm);
		}
	}, [dataToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.company) {
			alert("Para crear una empresa hay que escribir un nombre de empresa...");
			return;
		}
		if (form.pos === null) {
			createData(form);
		} else {
			updateData(form);
		}

		handleReset();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setDataToEdit(null);
	};

	return (
		<div className="cont1">
			<h3 className="titulo1">
				{dataToEdit
					? `Editar cuenta: ${dataToEdit.company}`
					: "Agregar Nueva Cuenta"}
			</h3>
			<form onSubmit={handleSubmit} className="form-cuentas">
				<input
					type="text"
					name="company"
					placeholder="Empresa"
					onChange={handleChange}
					value={form.company}
					className="input1"
				/>
				<select
					name="IdType"
					onChange={handleChange}
					value={form.IdType}
					className="input1"
				>
					<option defaultValue>Tipo Identificación</option>
					<option>NIT</option>
					<option>CC</option>
					<option>PASAPORTE</option>
				</select>
				<input
					type="text"
					name="IdNumber"
					placeholder="Identificación"
					onChange={handleChange}
					value={form.IdNumber}
					className="input1"
				/>
				<select
					name="country"
					onChange={handleChange}
					value={form.country}
					className="input1"
				>
					<option defaultValue>Pais</option>
					<option>Colombia</option>
					<option>Otro...</option>
				</select>
				<input
					type="text"
					name="city"
					placeholder="Ciudad"
					onChange={handleChange}
					value={form.city}
					className="input1"
				/>
				<input
					type="text"
					name="direction"
					placeholder="Dirección"
					onChange={handleChange}
					value={form.direction}
					className="input1"
				/>
				<input
					type="email"
					name="email"
					placeholder="E-mail"
					onChange={handleChange}
					value={form.email}
					className="input1"
				/>
				<input
					type="text"
					name="phone"
					placeholder="Teléfono"
					onChange={handleChange}
					value={form.phone}
					className="input1"
				/>
				<input
					type="text"
					name="contactName"
					placeholder="Contacto en la empresa"
					onChange={handleChange}
					value={form.contactName}
					className="input1"
				/>
				<input type="submit" value="Enviar" className="btn-send-accounts" />
				<input
					type="reset"
					value="Limpiar"
					onClick={handleReset}
					className="btn-form-cuentas"
				/>
			</form>
			{dataToEdit && (
				<div>
					<br />
					<hr />
					<hr />
					<CrudSucursalesApp company={dataToEdit.company} />
					<br />
					<hr />
					<hr />
					<CrudAreasApp company={dataToEdit.company} />
					<br />
					<hr />
					<hr />
					<BlocksApp company={dataToEdit.company} />
				</div>
			)}
		</div>
	);
};
