import React, { useEffect, useState } from "react";
import { DetailQuestion } from "./DetailQuestion";

export const ViewQuestions = ({ questions, setQuestionToEdit, openModal1 }) => {
	const [questionFilter, setQuestionFilter] = useState(questions);

	useEffect(() => {
		setQuestionFilter(questions);
	}, [questions]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setQuestionFilter(questions);
		}
		setQuestionFilter(
			questions.filter((item) =>
				item.question.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar Preguntas</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h2 className="titulo1">Lista de preguntas</h2>
			{questionFilter.map((question) => (
				<DetailQuestion
					key={question.id}
					question={question}
					setQuestionToEdit={setQuestionToEdit}
					openModal1={openModal1}
					// setRefresh={setRefresh}
				/>
			))}
		</div>
	);
};
