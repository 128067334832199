import React from "react";

export const HistoricalUsersProductionTableRow = ({ el }) => {
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col20p">{el.user}</td>
			<td className="col20p">{el.processNumber}</td>
			<td className="col20p">{el.product}</td>
			<td className="col30p">{el.activity}</td>
			<td className="col10p">
				{el.amount} / {el.totalAmount}{" "}
			</td>
			<td className="col10p">
				<label
					className={
						el.amount === 0
							? "avance0"
							: el.amount / el.totalAmount === 1
							? "avance100"
							: "avance50"
					}
				>
					{Math.trunc((el.amount * 100) / el.totalAmount)}%
				</label>
			</td>
		</tr>
	);
};
