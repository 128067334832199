import React from "react";
import CrudCuentasTableRow from "./CrudCuentasTableRow";

const CrudCuentasTable = ({ data, setDataToEdit, deleteData }) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Cuentas</h3>
			<table className="tabla-cuentas">
				<thead>
					<tr>
						<th className="titulo-tabla">Número Cuenta</th>
						<th className="titulo-tabla">Empresa</th>
						<th className="titulo-tabla">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => (
							<CrudCuentasTableRow
								key={el.id}
								el={el}
								setDataToEdit={setDataToEdit}
								deleteData={deleteData}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudCuentasTable;
