import React, { useState } from "react";

export const AsignationTableRow = ({
	el,
	deleteCourse,
	setCourseAsignmentToEdit,
	openModal2,
	deleteAsignment,
}) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const buttonAction = (el) => {
		setCourseAsignmentToEdit(el);
		openModal2();
	};

	const buttonAction2 = (el) => {
		deleteAsignment(el);
	};
	const date1 = new Date(el.initialDate.seconds * 1000).toLocaleDateString();
	const date2 = new Date(el.finalDate.seconds * 1000).toLocaleDateString();

	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.course}</td>
			<td className="colTableDocuments1">{el.student}</td>
			<td className="colTableDocuments1">{date1}</td>
			<td className="colTableDocuments1">{date2}</td>
			<td className="colTableDocuments1">{Math.round(el.calification)}</td>
			<td className="colTableDocuments1">{el.califiactionDAte}</td>
			<td className="colTableDocuments1">{el.status}</td>

			<td className="colTableDocuments2">
				<button onClick={() => buttonAction(el)} className="btnEditDocuments">
					Editar
				</button>

				<button
					onClick={() => buttonAction2(el)}
					className="btnDeleteDocuments"
				>
					Borrar
				</button>
			</td>
		</tr>
	);
};
