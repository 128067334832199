import React from "react";

const CrudCuentasTableRow = ({ el, setDataToEdit, deleteData }) => {
	let { pos, company } = el;
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-cuenta">{pos}</td>
			<td className="col-tabla-cuenta">{company}</td>
			<td className="col-tabla-cuenta">
				<button onClick={() => setDataToEdit(el)} className="btn-editar">
					Editar
				</button>
				{/* <button onClick={() => deleteData(id)}>Eliminar</button> */}
			</td>
		</tr>
	);
};

export default CrudCuentasTableRow;
