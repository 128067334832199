import React, { useState, useEffect } from "react";

const CrudAreasForm = ({
	createArea,
	updateArea,
	data2toEdit,
	setData2toEdit,
}) => {
	const initialForm = {
		id: null,
		area: "",
		responsable: "",
	};
	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		if (data2toEdit) {
			setForm(data2toEdit);
		} else {
			setForm(initialForm);
		}
	}, [data2toEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.area) {
			alert("Para adicionar una Area hay que escribir un nombre de area...");
			return;
		}
		if (form.id === null) {
			createArea(form);
		} else {
			updateArea(form);
		}

		handleReset();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setData2toEdit(null);
	};

	return (
		<div className="cont1">
			<form onSubmit={handleSubmit} className="form-cuentas">
				<h3 className="title2-account">
					{data2toEdit ? "Editar Area" : "Agregar Nueva Area"}
				</h3>
				<input
					type="text"
					name="area"
					placeholder="Nombre del Area"
					onChange={handleChange}
					value={form.area}
					className="input1"
				/>
				<input
					type="text"
					name="responsable"
					placeholder="Nombre del Responsable del Area"
					onChange={handleChange}
					value={form.responsable}
					className="input1"
				/>
				<hr />
				<input type="submit" value="Enviar" className="btn-send-accounts" />
				<input
					type="reset"
					value="Limpiar"
					onClick={handleReset}
					className="btn-clean-accounts"
				/>
			</form>
		</div>
	);
};

export default CrudAreasForm;
