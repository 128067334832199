import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
} from "@firebase/firestore";
import { Link } from "react-router-dom";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export const ProcessOrdersTableRow = ({
	el,
	setProductionOrderToEdit,
	openModal,
	deleteProductionOrder,
	company,
}) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [assignmentList, setAssignmentList] = useState(null);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		askAssignments();
	}, [el]);

	const askAssignments = async () => {
		setLoading(true);
		let resp1 = await askDataKeyValue(
			"processRegister-" + company,
			"productionOrder",
			el.productionOrder
		);
		let pRegister = resp1.data;
		await Promise.all(
			pRegister.map(async (pReg) => {
				let resp2 = await askDataKeyValue(
					"activitiesRegister-" + company,
					"processNumber",
					pReg.processNumber
				);
				let totalAmount = resp2.data[0].totalAmount * resp2.data.length;
				let sumAmount = 0;
				resp2.data.map((act) => {
					sumAmount = sumAmount + act.amount;
				});
				pReg.complianceActivities = sumAmount / totalAmount;
			})
		);
		console.log("pRegister: ", pRegister);
		setAssignmentList(pRegister);

		setLoading(false);
	};

	const editButton = (el) => {
		setProductionOrderToEdit(el);
		openModal();
	};
	const date1 = new Date(el.promiseDate.seconds * 1000).toLocaleDateString();

	const deleteButton = (el) => {
		deleteProductionOrder(el);
	};
	return (
		<>
			{loading ? (
				<Loader />
			) : (
				<tr className="fila-tabla-cuenstas">
					{/* <td className="colTableDocuments0">{el.area}</td> */}
					<td className="col10p">{el.productionOrder}</td>
					<td className="col20p">{el.client}</td>

					<td className="col10p">{el.date1}</td>
					<td className="col10p">{date1}</td>
					{assignmentList && (
						<td className="col40p">
							{assignmentList.map((assign) => (
								<label>
									Producto: {assign.refProductionProduct} -*- Avance:{" "}
									{assign.finishProduct} / {assign.quantity}
									<label
										className={
											assign.quantity === 0
												? "avance0"
												: assign.finishProduct / assign.quantity === 1
												? "avance100"
												: "avance50"
										}
									>
										{Math.trunc((assign.finishProduct * 100) / assign.quantity)}
										%
									</label>
									<article>
										Avance Actividades=
										<label
											className={
												assign.complianceActivities === 0
													? "avance0"
													: assign.complianceActivities === 1
													? "avance100"
													: "avance50"
											}
										>
											{Math.trunc(assign.complianceActivities * 100)} %
										</label>
									</article>
								</label>
							))}
						</td>
					)}

					<td className="col10p">
						{/* <button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
					</button>
					
					<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button> */}
						{/* <button onClick={() => deleteButton(el)} className="btnViewDocuments">
					Ver
				</button> */}
						<Link to={"/production-process/order/" + el.productionOrder}>
							<button className="btnViewDocuments">Ingresar</button>
						</Link>
					</td>
				</tr>
			)}
		</>
	);
};
