import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import ExportExcel from "react-export-excel";
import {
	query,
	where,
	orderBy,
	getDocs,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { Redirect } from "react-router";
import {
	askDataKeyValue,
	askDataKeyValue3Simple,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import Chart from "react-google-charts";

const ExcelFile = ExportExcel.ExcelFile; //el archivo que se va a crear
const ExcelSheet = ExportExcel.ExcelSheet; //la hoja
const ExcelColumn = ExportExcel.ExcelColumn; //una columna

export const ViewDataBase = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [goBack, setGoBack] = useState(false);
	const [listActivities, setListActivities] = useState(null);
	const [loading0, setLoading0] = useState(false);
	const [loading, setLoading] = useState(false);
	const [complianceProcess, setComplianceProcess] = useState(null);
	const [listStagesOriginal, setListStagesOriginal] = useState(null);
	const [showStages, setShowStages] = useState(false);
	const [listStagesCompliance, setListStagesCompliance] = useState([]);
	const [stagesComplianceToShow, setStagesComplianceToShow] = useState(null);
	const [listStagesComlianceGraph, setListStagesComlianceGraph] = useState([
		["Etapa", "", { role: "style" }],
	]);
	const [form01, setForm01] = useState(true);
	const [form02, setForm02] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localStorage.getItem("usuariosEmpresa");
	const processToDataBase = localStorage.getItem("processToDataBase");
	const area = localStorage.getItem("areaToDataBase");
	const initialDate = localStorage.getItem("initialDate");
	const finalDate = localStorage.getItem("finalDate");
	let datex1 = new Date(initialDate);
	let datex2 = new Date(finalDate);

	const askListProcess = async () => {
		setLoading0(true);
		const q1 = query(
			collection(db, "processRegister-" + company),
			where("process", "==", processToDataBase),
			where("status", "==", "cerrado"),
			where("date", ">=", datex1),
			where("date", "<=", datex2),
			orderBy("date", "asc")
		);

		await getDocs(q1)
			.then(async (querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				let listNumProcess = [];
				dataList.map((elemento) => listNumProcess.push(elemento.processNumber));
				let listActivities = [];

				await Promise.all(
					listNumProcess.map(async (processNumber) => {
						const q2 = query(
							collection(db, "activitiesRegister-" + company),
							where("process", "==", processToDataBase),
							where("processNumber", "==", processNumber),
							where("area", "==", area)
						);
						await getDocs(q2).then((querySnapshot2) => {
							let dataList2 = querySnapshot2.docs.map((d) => ({
								...d.data(),
							}));
							dataList2.map((el) => listActivities.push(el));
						});
					})
				);
				setListActivities(listActivities);
			})
			.catch((error) => {
				setError(error);
			});
		setLoading0(false);
	};

	const askListStages = async () => {
		setLoading(true);
		const nameCollecion = "stages-" + company;
		const key2 = "process";
		const value2 = processToDataBase;
		const response = await askDataKeyValue(nameCollecion, key2, value2);
		if (response.statusResponse === true) {
			const originalSatages = response.data;
			setListStagesCompliance([]);
			setListStagesOriginal(response.data);
			let stagesComplianceSum = 0;
			let stagesQuantity = 0;
			for (const stage of originalSatages) {
				for (const proc of listActivities) {
					const response2 = await askDataKeyValue3Simple(
						"assignments-" + company,
						key2,
						value2,
						"processNumber",
						proc.processNumber,
						"stage",
						stage.stage
					);
					const response3 = response2.data[0];
					stagesComplianceSum = stagesComplianceSum + response3.complianceStage;
					stagesQuantity = stagesQuantity + 1;
				}
				const newStages = {
					stage: stage.stage,
					stageComplianceSum: stagesComplianceSum,
					stageQuantity: stagesQuantity,
				};
				const complianceGraph = (stagesComplianceSum * 100) / stagesQuantity;
				let colorBar = "grey";
				if (complianceGraph < 65) {
					if (complianceGraph < 50) {
						colorBar = "red";
					} else {
						colorBar = "gold";
					}
				} else {
					colorBar = "green";
				}
				listStagesCompliance.push(newStages);
				const newStagesGraph = [stage.stage, complianceGraph, colorBar];
				listStagesComlianceGraph.push(newStagesGraph);
				stagesComplianceSum = 0;
				stagesQuantity = 0;
			}
			setLoading(false);
			setShowStages(true);
		} else {
			setListStagesCompliance(null);
			// setError(response.error);
			setLoading(false);
		}

		setStagesComplianceToShow(listStagesCompliance);
	};

	const complianceProcessCalculate = async () => {
		setLoading0(true);
		if (listActivities) {
			let complianceSum = 0;
			listActivities.map(
				(item) => (complianceSum = complianceSum + item.complianceProcess)
			);
			try {
				let calculate = (complianceSum * 100) / listActivities.length;
				setComplianceProcess(calculate);
			} catch (error) {
				setComplianceProcess(0);
			}
		}
		setLoading0(false);
	};

	useEffect(() => {
		askListProcess();
	}, []);

	const goAgain = () => {
		setGoBack(true);
	};

	const showIndicatorsProcess = async () => {
		await complianceProcessCalculate();
		setForm01(false);
		setForm02(true);
	};

	const showIndicatorsStages = async () => {
		await askListStages();
		setForm02(false);
	};

	const options = {
		title: "CUMPLIMIENTO DE LAS ETAPAS",
		chartArea: { width: "50%" },
		hAxis: {
			title: "Cumplimientos [%] ",
			minValue: 0,
		},
		vAxis: {
			title: "Etapas",
		},
	};

	return (
		<div>
			{console.log("------- la lista es:", listActivities)}
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					{loading0 ? (
						<Loader />
					) : (
						<div>
							<h3 className="titleProcess">BASE DE DATOS ({company})</h3>

							<h2 className="titulo1">Proceso: {processToDataBase}</h2>
							<h3 className="titulo2">
								Fecha inicial: {initialDate} -------- Fecha final: {finalDate}
							</h3>
							{listActivities && (
								<h3 className="titulo2">
									cantidad de Actividades en el rango de fecha seleccionado:
									{"  "}
									{listActivities.length}
								</h3>
							)}
							<hr />
							<hr />

							{loading && <Loader />}
							{listActivities && (
								<div className="cajaBotonExcel">
									<ExcelFile
										element={
											<button className="btn-send-reg-asign2">
												Exportar a Excel
											</button>
										}
										filename="Listado Actividades"
									>
										<ExcelSheet data={listActivities} name={processToDataBase}>
											<ExcelColumn label="EMPRESA" value="company" />
											<ExcelColumn label="AREA" value="area" />
											<ExcelColumn label="SUCURSAL" value="agency" />
											<ExcelColumn label="PROCESO" value="process" />
											<ExcelColumn
												label="NUMERO DE PROCESO"
												value="processNumber"
											/>
											<ExcelColumn label="ESTAPA" value="stage" />
											<ExcelColumn label="ACTIVIDAD" value="activity" />
											<ExcelColumn
												label="DESCRIPCION ACTIVIDAD"
												value="descriptionActivity"
											/>
											<ExcelColumn
												label="TIPO RESPUESTA"
												value="responseType"
											/>
											<ExcelColumn label="RESPUESTA" value="response" />
											<ExcelColumn label="COMENTARIO" value="comment" />
											<ExcelColumn label="ID" value="id" />
											<ExcelColumn label="FECHA" value="date" />
											<ExcelColumn label="HORA" value="hour" />
											<ExcelColumn label="OPERARIO" value="userChanges" />
										</ExcelSheet>
									</ExcelFile>
									{/* <table className="tabla-procesos">
										<thead>
											<tr className="fila-tabla-procesos">
												<th className="titulo-tabla-procesos2">Usuario</th>
												<th className="titulo-tabla-procesos1">Num Proc</th>
												<th className="titulo-tabla-procesos1">respuesta</th>
												<th className="titulo-tabla-procesos1">Etapa</th>
											</tr>
										</thead>
										<tbody>
											{listActivities.map((actividad) => (
												<tr className="fila-tabla-cuenstas">
													<td className="col-tabla-procesos2">
														{actividad.userChanges}
													</td>
													<td className="col-tabla-procesos2">
														{actividad.processNumber}
													</td>
													<td className="col-tabla-procesos2">
														{actividad.response}
													</td>
													<td className="col-tabla-procesos2">
														{actividad.stage}
													</td>
												</tr>
											))}
										</tbody>
									</table>
								 */}
								</div>
							)}
							<hr />
							<hr />
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
