import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import "../../styles/MenuProcesos.css";

const CrudDataBaseForm = ({
	setDates,
	processToEdit,
	company,
	closeModal,
	setGoToDataBase,
}) => {
	const initialForm = {
		initialDate: null,
		finalDate: null,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	useEffect(() => {}, []);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log("fecha inicial:", form.initialDate);
		if (form.initialDate === null) {
			alert("Debe seleccionar un fecha inicial");
			return;
		}
		if (form.finalDate === null) {
			alert("Debe seleccionar un fecha final");
			return;
		}
		if (form.initialDate > form.finalDate) {
			alert("No puede ser la fecha final anterior a la inicial...");
			return;
		}
		localStorage.setItem("initialDate", form.initialDate);
		localStorage.setItem("finalDate", form.finalDate);
		setGoToDataBase(true);
		setLoading(true);

		// handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		// setForm(initialForm);
		// setProcessToEdit(null);
	};

	return (
		<div className="conten0">
			{/* {console.log("areas en formulario", db2)} */}
			{/* {console.log("areas en formulario variable areas", areas)} */}
			<div className="conten1">
				<h3 className="titulo1"></h3>

				<form onSubmit={handleSubmit} className="form-procesos">
					{processToEdit && (
						<h2 className="title2-account">
							Generando Base de Datos: {processToEdit.process}
						</h2>
					)}
					<h2 className="title3-account">
						Seleccione Rango de fecha para generar la Base de Datos
					</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<label className="title3-account">Fecha Inicial</label>
							<input
								type="date"
								name="initialDate"
								placeholder="Fecha Inicial"
								onChange={handleChange}
								value={form.initialDate}
								className="input1"
							/>
							<label className="title3-account">Fecha Final</label>
							<input
								type="date"
								name="finalDate"
								placeholder="Fecha final"
								onChange={handleChange}
								value={form.finalDate}
								className="input1"
							/>

							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudDataBaseForm;
