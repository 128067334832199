import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";

export const ClientsForm = ({
	company,
	createClient,
	updateClient,
	clientToEdit,
	setClientToEdit,
	closeModal,
}) => {
	const initialForm = {
		id: null,
		client: "",
		IdType: "",
		IdNumber: "",
		country: "",
		city: "",
		direction: "",
		email: "",
		phone: "",
		contactName: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (clientToEdit) {
			setForm(clientToEdit);
		} else {
			setForm(initialForm);
		}
	}, [clientToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.client) {
			alert("Para crear un cliente hay que escribir un nombre del cliente...");
			return;
		}
		if (form.IdType === "Tipo Identificación" || form.IdType === "") {
			alert("Por favor seleccionar Tipo de Identifiación");
			return;
		}
		if (!form.IdNumber) {
			alert("Por favor colocar un número de Identificación del Cliente.");
			return;
		}
		if (!form.country) {
			alert("Por favor colocar el país de ubicación del Cliente");
			return;
		}
		if (!form.city) {
			alert("Por favor colocar la ciudad de ubicación del Cliente");
			return;
		}
		if (!form.direction) {
			alert("Por favor colocar la dirección del Cliente");
			return;
		}
		if (!form.email) {
			alert("Por favor colocar el correo electrónico del Cliente");
			return;
		}
		if (!form.phone) {
			alert("Por favor colocar el número de teléfono del Cliente");
			return;
		}
		if (!form.contactName) {
			alert("Por favor colocar nombre de la persona de contacto ");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			createClient(form);
		} else {
			updateClient(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setClientToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{clientToEdit ? "Editar Cliente" : "Agregar Nuevo Cliente"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="client"
								placeholder="Nombre del Cliente"
								onChange={handleChange}
								value={form.client}
								className="input1"
								required
							/>
							<select
								name="IdType"
								onChange={handleChange}
								value={form.IdType}
								className="input1"
							>
								<option defaultValue>Tipo Identificación</option>
								<option>NIT</option>
								<option>CEDULA DE CIUDADANIA</option>
								<option>CEDULA DE EXTRANJERIA</option>
							</select>
							<input
								type="text"
								name="IdNumber"
								placeholder="Identificación"
								onChange={handleChange}
								value={form.IdNumber}
								className="input1"
								required
							/>
							<input
								type="text"
								name="country"
								placeholder="Pais"
								onChange={handleChange}
								value={form.country}
								className="input1"
								required
							/>
							<input
								type="text"
								name="city"
								placeholder="Ciudad"
								onChange={handleChange}
								value={form.city}
								className="input1"
								required
							/>
							<input
								type="text"
								name="direction"
								placeholder="Dirección"
								onChange={handleChange}
								value={form.direction}
								className="input1"
								required
							/>
							<input
								type="email"
								name="email"
								placeholder="E-mail"
								onChange={handleChange}
								value={form.email}
								className="input1"
								required
							/>
							<input
								type="text"
								name="phone"
								placeholder="Teléfono"
								onChange={handleChange}
								value={form.phone}
								className="input1"
								required
							/>
							<input
								type="text"
								name="contactName"
								placeholder="Contacto en la empresa"
								onChange={handleChange}
								value={form.contactName}
								className="input1"
								required
							/>
							<br />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
