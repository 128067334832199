import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import { firebaseConfig } from "../../../config/firebaseConfig";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import { ClientsForm } from "./clientsForm";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { ClientsTable } from "./clientsTable";
import {
	addDatakey1,
	addDataKey2,
	askDataKeyValue,
	askDataKeyValue2Simple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

export const ClientsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado de Clientes de la empresa
	const [clientToEdit, setClientToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const askClients = async () => {
		setLoading(true);
		const q = query(collection(db, "clients-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askClients();
	}, []);

	const clickCrear = () => {
		setClientToEdit(null);
		openModal1();
	};

	async function createClient(data) {
		setLoading(true);
		const response = await addDatakey1(
			"clients-" + company,
			data,
			"client",
			data.client
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askClients();
		setLoading(false);
	}

	async function updateClient(data) {
		setLoading(true);
		const nameCollection = "clients-" + company;
		const docId = data.id;
		const changes = data;
		const key2 = "client";
		const value2 = data.client;
		console.log("valure2", value2);
		const response = await askDataKeyValue(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Empresa ya existe");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		// await askAccounts();
		setLoading(false);
	}

	async function deleteClient(data) {
		const nameCollection = "clients-" + company;
		const docId = data.id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar este Cliente ?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<article>
						{/* <p>Aquí se gestionan listas de clientes</p> */}
						<h3 className="titleProcess">CLIENTES ({company})</h3>
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<ClientsForm
								company={company}
								createClient={createClient}
								updateClient={updateClient}
								clientToEdit={clientToEdit}
								setClientToEdit={setClientToEdit}
								closeModal={closeModal1}
							/>
						</Modal>
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{dataBase ? (
									dataBase.length >= 0 ? (
										<div>
											<button
												onClick={clickCrear}
												className="btn-create-document"
											>
												Nuevo Cliente
											</button>
											<ClientsTable
												data={dataBase}
												setClientToEdit={setClientToEdit}
												deleteClient={deleteClient}
												openModal={openModal1}
											/>
										</div>
									) : (
										<h3 className="alertEmpty">No hay Clientes para mostrar</h3>
									)
								) : (
									<h3 className="alertEmpty">Buscando...</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
