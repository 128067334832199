import React, { useEffect, useState } from "react";
import Loader from "../../../generalComponents/Loader";

export const InventoriesForm = ({
	company,
	crateInventory,
	updateInventory,
	inventoryToEdit,
	setInventoryToEdit,
	closeModal,
}) => {
	const initialForm = {
		id: null,
		refInventory: "",
		description: "",
		features: "",
		measureUnit: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (inventoryToEdit) {
			setForm(inventoryToEdit);
		} else {
			setForm(initialForm);
		}
	}, [inventoryToEdit]);

	const handleChange = (e) => {
		if (e.target.name === "amount") {
			console.log("target:", e.target.name);
			setForm({
				...form,
				[e.target.name]: parseInt(e.target.value),
			});
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (form.amount < 0) {
			alert("No se puede iniciar con inventario negativo....");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			crateInventory(form);
		} else {
			updateInventory(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setInventoryToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{inventoryToEdit ? "Editar Producto" : "Agregar Nuevo Producto"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					{loading ? (
						<Loader />
					) : (
						<div>
							{inventoryToEdit ? (
								<h3 className="titulo8">Referencia: {form.refInventory}</h3>
							) : (
								<input
									type="text"
									name="refInventory"
									placeholder="Referencia"
									onChange={handleChange}
									value={form.refInventory}
									className="input1"
									required
								/>
							)}
							<input
								type="text"
								name="description"
								placeholder="Descripcion"
								onChange={handleChange}
								value={form.description}
								className="input1"
								required
							/>
							<input
								type="text"
								name="features"
								placeholder="Característias"
								onChange={handleChange}
								value={form.features}
								className="input1"
								required
							/>
							{/* <input
								type="number"
								name="amount"
								placeholder="Cantidad"
								onChange={handleChange}
								value={form.amount}
								className="input1"
								required
							/> */}
							<input
								type="text"
								name="measureUnit"
								placeholder="Unidad de Medida"
								onChange={handleChange}
								value={form.measureUnit}
								className="input1"
								required
							/>

							<br />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
