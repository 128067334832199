import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";

import {
	addDoc,
	collection,
	doc,
	getFirestore,
	updateDoc,
} from "@firebase/firestore";

import { firebaseConfig } from "../../../../../config/firebaseConfig";
import { updateDocumentById } from "../../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../../generalComponents/Loader";

export const PermisionsInventoryMasterForm = ({
	users,
	userPermisionsToEdit,
	createPermisions,
	closeModal,
}) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const initialForm = {
		inventoryMasterPermision: [],
		typePermision: "Master",
		id: null,
	};

	const [form, setForm] = useState(initialForm);
	const [newUsers, setNewUsers] = useState(users);
	const [loading, setLoading] = useState(false);
	const [checkedState, setCheckedState] = useState([]); //cheqState true/false usuarios autorizados

	useEffect(() => {
		setNewUsers(users);
		if (userPermisionsToEdit) {
			setForm(userPermisionsToEdit);
			editCheckedState();
		} else {
			setForm(initialForm);
			setCheckedState(new Array(users.length).fill(false));
		}
	}, [userPermisionsToEdit, createPermisions, users]);

	const clicCheckBox = (id) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	const permisionAdd = () => {
		form.inventoryMasterPermision = [];
		checkedState.map((item, index) => {
			if (item === true) {
				form.inventoryMasterPermision.push(newUsers[index].email);
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let cont = 0;
		checkedState.map((item, index) => {
			if (item === true) {
				cont = cont + 1;
			}
		});
		if (cont === 0) {
			alert("Debe seleccionar por lo menos un usuario...");
			return;
		}

		setLoading(true);
		permisionAdd();

		const collectionName = "inventoryPermisions-" + company;

		if (userPermisionsToEdit) {
			updateDocumentById(collectionName, form.id, form);
		} else {
			await addDoc(collection(db, collectionName), form)
				.then(async (res) => {
					if (res.id) {
						const docRef = doc(db, collectionName, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				})
				.catch((error) => {
					console.log("error: ", error);
				});
		}
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setCheckedState(new Array(users.length).fill(false));
	};

	const editCheckedState = () => {
		const newCheckedState = [];
		users.map((item, index) => {
			if (userPermisionsToEdit.inventoryMasterPermision.includes(item.email)) {
				newCheckedState.push(true);
			} else {
				newCheckedState.push(false);
			}
		});
		setCheckedState(newCheckedState);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<h2 className="titulo3">
								Usuarios Autorizados para acceder al MAESTRO DE INVENTARIO
							</h2>
							<div className="containerChequedListCourses">
								{users.map(({ email, name }, id) => {
									return (
										<div className="chequedlist" key={id}>
											<input
												type="checkbox"
												id={`custom-checkbox-${id}`}
												name={email}
												value={email}
												checked={checkedState[id]}
												onChange={() => clicCheckBox(id)}
											/>
											<label
												htmlFor={`custom-checkbox-${id}`}
												className="nameCheckbox"
											>
												{email}--{name}
											</label>
										</div>
									);
								})}
							</div>

							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
