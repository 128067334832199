import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import Loader from "../../generalComponents/Loader";
import { firebaseConfig } from "../../../config/firebaseConfig";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";

export const ProductionOrdersForm = ({
	company,
	createProductionOrder,
	updateProductionOrder,
	productionOrderToEdit,
	setProductionOrderToEdit,
	closeModal,
}) => {
	const initialForm = {
		id: null,
		productionOrder: "",
		creationDate: "",
		client: "",
		promiseDate: "",
		purshaseOrder: "",
		status: "abierto",
	};

	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [clientsList, setClientsList] = useState(null);

	const askClients = async () => {
		setLoading(true);
		const q = query(collection(db, "clients-" + company));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setClientsList(dataList);
				setLoading(false);
			},
			(error) => {
				setClientsList(null);
				// setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askClients();
		if (productionOrderToEdit) {
			setForm(productionOrderToEdit);
		} else {
			setForm(initialForm);
		}
	}, [productionOrderToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.client || form.client === "Nombre del Cliente") {
			alert(
				"Para crear una Orden de producción hay que seleccionar un nombre del cliente..."
			);
			return;
		}
		const res = new Date(form.promiseDate);
		res.setDate(res.getDate() + 1);
		form.promiseDate = res;
		setLoading(true);
		if (form.id === null) {
			createProductionOrder(form);
		} else {
			updateProductionOrder(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setProductionOrderToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{productionOrderToEdit
						? "Editar Orden de Producción"
						: "Agregar Nueva Orden de producción"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					{loading ? (
						<Loader />
					) : (
						clientsList && (
							<div>
								<label className="titulo5">Orden de Producción</label>
								{form.id === null ? (
									<input
										type="text"
										name="productionOrder"
										placeholder="Orden de Producción"
										onChange={handleChange}
										value={form.productionOrder}
										className="input1"
										required
									/>
								) : (
									<label className="titulo2">{form.productionOrder}</label>
								)}
								<label className="titulo5">Cliente</label>
								<select
									name="client"
									onChange={handleChange}
									value={form.client}
									className="input1"
								>
									<option value="">Nombre del Cliente</option>
									{clientsList.map((client) => (
										<option key={client.id} value={client.client}>
											{client.client}
										</option>
									))}
								</select>

								<br />
								<label className="titulo5">Fecha de Entrega</label>
								<input
									type="date"
									name="promiseDate"
									placeholder="Fecha de entrega"
									onChange={handleChange}
									value={form.promiseDate}
									className="input1"
									required
								/>
								<label className="titulo5">Orden de Compra</label>
								<input
									type="text"
									name="purshaseOrder"
									placeholder="Orden de Compra"
									onChange={handleChange}
									value={form.purshaseOrder}
									className="input1"
									required
								/>

								<br />
								<input
									type="submit"
									value="Enviar"
									className="btn-send-reg-asign"
								/>
								<input
									type="reset"
									value="Limpiar"
									onClick={handleReset}
									className="btn-clean-reg-asign"
								/>
								<input
									type="reset"
									value="Cerrar"
									onClick={closeModal}
									className="btn-close-reg-asign"
								/>
							</div>
						)
					)}
				</form>
			</div>
		</div>
	);
};
