import React, { useState, useEffect } from "react";
import Loader from "../../generalComponents/Loader";
import "../../styles/MenuProcesos.css";

const CrudStagesForm = ({
	createStage,
	updateStage,
	setStageToEdit,
	stageToEdit,
	company,
	process,
	closeModal,
	db,
}) => {
	let long = 1;
	if (db != null) {
		long = db.length + 1;
	}
	const initialForm = {
		id: null,
		process: process,
		stage: "",
		descriptionStage: "",
		position: long,
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (stageToEdit) {
			setForm(stageToEdit);
		} else {
			setForm(initialForm);
		}
	}, [stageToEdit, long]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.stage) {
			alert("Debe colocar una etapa...");
			return;
		}
		if (!form.descriptionStage) {
			alert("Debe llenar el campo de descripción de la Etapa...");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			createStage(form);
		} else {
			updateStage(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setStageToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{stageToEdit ? "Editar Etapa" : "Agregar Nueva Etapa al Proceso"}
				</h3>
				<h3 className="titulo2">
					{stageToEdit &&
						"ALERTA: Solo se recomienda editar una Etapa mientras se está creando, de lo contrario estaría creando una nueva Etapa."}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					<h2 className="title2-account">Proceso: {process}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="stage"
								placeholder="Etapa"
								onChange={handleChange}
								value={form.stage}
								className="input0"
							/>
							<input
								type="text"
								name="descriptionStage"
								placeholder="Descripción de la etapa"
								onChange={handleChange}
								value={form.descriptionStage}
								className="input0"
							/>
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudStagesForm;
