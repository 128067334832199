import React, { useEffect, useState } from "react";

export const ViewEvaluationAnswer = ({
	answersList,
	question,
	setVisibleFollow,
	answersQuiz,
	changeAnswers,
}) => {
	const [newAnswerList, setNewAnswerList] = useState(
		answersList.filter((item) => item.question === question)
	);
	const [visibleSubmit, setVisibleSubmit] = useState(true);
	const [checkedState, setCheckedState] = useState([]); //cheqState true/false answers
	const [newAnswerQuiz, setNewAnswerQuiz] = useState([]);
	const initialForm = {
		// question: question,
		answer: "",
		calification: 0,
	};

	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		const large = answersList.filter((item) => item.question === question);
		setNewAnswerList(large);
		setCheckedState(new Array(large.length).fill(false));
		setVisibleSubmit(true);
		setNewAnswerQuiz(answersQuiz);
	}, [answersList, question]);

	const clicCheckBox = (id) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	const handleSubmit = () => {
		let cont = 0;
		checkedState.map((item, index) => {
			if (item === true) {
				form.answer = newAnswerList[index].answer;
				cont = cont + 1;
			}
		});
		if (cont === 0) {
			alert("Debe seleccionar por lo menos una RESPUESTA para la pregunta...");
			handleReset();
			return;
		}
		if (cont > 1) {
			alert("Solo puede seleccionar una RESPUESTA por Pregunta...");
			handleReset();
			return;
		}

		const resp = newAnswerList.filter((item) => item.answer === form.answer);
		console.log("--- resp", resp[0].correctAnswer);
		if (resp[0].correctAnswer === "si") {
			form.calification = 1;
		} else {
			form.calification = 0;
		}
		changeAnswers(form.answer, form.calification);
		setVisibleFollow(true);
		setVisibleSubmit(false);
	};

	const handleReset = () => {
		const large = answersList.filter((item) => item.question === question);

		setCheckedState(new Array(large.length).fill(false));
	};

	return (
		<div>
			{visibleSubmit && (
				<div>
					<div className="containerResponses">
						{console.log("checkedState inicial:", checkedState)}
						{newAnswerList &&
							newAnswerList.map(({ answer }, id) => {
								return (
									<div className="chequedlist" key={id}>
										<input
											type="checkbox"
											id={`custom-checkbox2-${id}`}
											name={answer}
											value={answer}
											checked={checkedState[id]}
											onChange={() => clicCheckBox(id)}
										/>
										<label
											htmlFor={`custom-checkbox2-${id}`}
											className="nameCheckbox"
										>
											{answer}
										</label>
									</div>
								);
							})}
					</div>

					<input
						type="reset"
						value="Enviar"
						onClick={handleSubmit}
						className="btn-clean-reg-asign"
					/>
				</div>
			)}
		</div>
	);
};
