import React from "react";
import "../components/styles/Error404.css";

const Error404 = () => {
	return (
		<div className="error404">
			<h3>Error 404</h3>
			<p>Not Found by... BEST PRACTICES WORKING</p>
		</div>
	);
};

export default Error404;
