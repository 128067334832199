import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import {
	askDataKeyValue2Simple,
	askDataKeyValue3Simple,
	deleteDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudStagesAdminTableRow = ({
	el,
	setStageToEdit,
	openModal1,
	setIrActivity,
	totalStages,
	downPos,
	upPos,
	setRefresh,
	company,
}) => {
	const processRelease = localStorage.getItem("processRelease");

	const buttonAction = (el) => {
		setStageToEdit(el);
		openModal1();
	};
	const irActividades = () => {
		localStorage.setItem("etapaEditar", el.stage);
		setIrActivity(true);
	};

	const stagesDelete = async (el) => {
		console.log("va a borrar etapa:", el.stage);
		console.log("va a borrar proceso:", el.process);
		let isDelete = window.confirm(
			`¿Está segur@ de Borrar esta Etapa? ..esto borrará toda información relacionada con la etapa seleccionado, actividades y registros hechos con esta etapa...`
		);

		if (isDelete) {
			let isDeleteConfirmation = window.confirm(
				"¿C o m p l e t a m e n t e   s e g u r @ ?"
			);
			if (isDeleteConfirmation) {
				let stage = el;
				const resp1 = await askDataKeyValue2Simple(
					"activities-" + company,
					"process",
					stage.process,
					"stage",
					stage.stage
				);
				let activitiesList = resp1.data;

				for (const activity of activitiesList) {
					deleteDocumentById("activities-" + company, activity.id);
				}
				const resp2 = await askDataKeyValue2Simple(
					"activitiesRegister-" + company,
					"process",
					stage.process,
					"stage",
					stage.stage
				);
				let activitiesList2 = resp2.data;
				for (const activity of activitiesList2) {
					deleteDocumentById("activitiesRegister-" + company, activity.id);
				}
				const resp3 = await askDataKeyValue2Simple(
					"assignments-" + company,
					"process",
					stage.process,
					"stage",
					stage.stage
				);
				for (const assignment of resp3.data) {
					deleteDocumentById("assignments-" + company, assignment.id);
				}
				deleteDocumentById("stages-" + company, stage.id);
				setRefresh(true);
			} else {
				return;
			}
		} else {
			return;
		}
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-etapas1">{el.position}</td>
			<td className="col-tabla-etapas2">{el.stage}</td>
			<td className="col-tabla-etapas2">{el.descriptionStage}</td>
			<td className="col-tabla-etapas3">
				<div>
					<button className="btn-show-stage" onClick={() => irActividades()}>
						Ver Actividades
					</button>
					{processRelease === "no" && (
						<button
							onClick={() => buttonAction(el)}
							className="btn-editar-procesos"
						>
							Editar
						</button>
					)}
				</div>
			</td>
			<td className="col-tabla-etapas4">
				{totalStages > 1 ? (
					el.position === 1 ? (
						<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
							<ArrowDropDownIcon />
						</button>
					) : (el.position > 1) & (el.position < totalStages) ? (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
								<ArrowDropDownIcon />
							</button>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
						</div>
					) : (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
							<button
								className="btn-delete-process"
								onClick={() => stagesDelete(el)}
							>
								Borrar Etapa
							</button>
						</div>
					)
				) : (
					<button
						className="btn-delete-process"
						onClick={() => stagesDelete(el)}
					>
						Borrar Etapa
					</button>
				)}
			</td>
		</tr>
	);
};

export default CrudStagesAdminTableRow;
