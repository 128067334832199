import Modal from "../../generalComponents/Modal";
import React, { useState, useEffect } from "react";
import { useModal } from "../../generalComponents/hooks/useModal";
import DetalleRegProcesosFormAsign from "./DetalleRegProcesosFormAsign";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import {
	askDataKeyValue2,
	milisecondsToHHMM,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const DetalleRegProcesosEtapa = ({
	assignment,
	localUser,
	setRefresh,
	company,
}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [activities, setActivities] = useState([]);
	const [visible, setVisible] = useState(true);
	const [refresh2, setRefresh2] = useState(false);
	const [responses, setResponses] = useState(0);
	const [questions, setQuestions] = useState(null);
	const [timeToShow, setTimeToShow] = useState(null);
	let hours = assignment.totalTime / 3600000;
	let hoursTrunc = Math.trunc(hours);
	let minuts = (hours - hoursTrunc) * 60;
	let minutsTrunc = Math.trunc(minuts);

	const askActivities = async () => {
		setLoading(true);
		// console.log("company:", company);
		// console.log("processNumber:", assignment.processNumber);
		const nameCollection = "activitiesRegister-" + company;
		const key = "processNumber";
		const value = assignment.processNumber;
		const key2 = "stage";
		const value2 = assignment.stage;
		const response = await askDataKeyValue2(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			setActivities(response.data);
			setQuestions(response.data.length);
			let answers = 0;
			response.data.map((line) => {
				if (line.response !== "") {
					answers = answers + 1;
				}
			});
			setResponses(answers);
			setLoading(false);
		} else {
			setActivities([]);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		setRefresh(false);
		askActivities();
		calculateTime();
	}, [refresh2, assignment]);

	const updateUsuarioAsignado = async (data) => {
		console.log("**** va agrabar: ", data);
		setLoading(true);
		const collection = "assignments-" + company;
		const docId = data.id;
		const changes = data;
		const response = await updateDocumentById(collection, docId, changes);
		setLoading(false);
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const clickAsignar = () => {
		openModal1();
		setRefresh2(true);
	};

	const verTareas = () => {
		setVisible(true);
	};

	const ocultarTareas = () => {
		setVisible(false);
	};

	const calculateTime = () => {
		if (assignment.statusActingTime === "no") {
			console.log("no");
			setTimeToShow("[00:00]");
		} else {
			if (assignment.statusActingTime === "pausado") {
				console.log("pausado");
				let response2 = milisecondsToHHMM(assignment.totalTime);
				setTimeToShow("[" + response2.hours + ":" + response2.minuts + "]");
				console.log("respnse2: ", response2);
			} else {
				console.log("si");
				const fechita = new Date();
				const fechitaMlSeconds = fechita.getTime(); // para pasar a milisegundos
				let posTargetEvent = assignment.eventsTime.length - 1;
				let targetEvent = assignment.eventsTime[posTargetEvent];
				let addTime = fechitaMlSeconds - targetEvent.i;
				let newTotalTime = assignment.totalTime + addTime;
				let response3 = milisecondsToHHMM(newTotalTime);
				setTimeToShow("[" + response3.hours + ":" + response3.minuts + "]");
				console.log("respnse3: ", response3);
			}
		}
	};

	return (
		<div>
			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<DetalleRegProcesosFormAsign
					localUser={localUser}
					closeModal={closeModal1}
					assignment={assignment}
					updateUsuarioAsignado={updateUsuarioAsignado}
					setRefresh={setRefresh}
				/>
			</Modal>
			<div
			// className={
			// 	assignment.assignment === "" ? "tituloetapas1" : "tituloetapas2"
			// }
			>
				{/* <table>
					<tbody>
						<tr>
							<td className="etapa-col1">{assignment.stage}</td>
							<td className="etapa-col1">
								Cumplimiento: {Math.round(assignment.complianceStage * 100)}%
							</td>
							{assignment.assignment ? (
								<td className="etapa-col2">{assignment.assignment}</td>
							) : (
								<td className="etapa-col2">
									<p>------</p>
								</td>
							)}
							<td className="etapa-col3">
								<button onClick={clickAsignar} className="btnAsignar">
									{assignment.assignment === "" ||
									assignment.assignment === null
										? "Asignar"
										: "Reasignar"}
								</button>
							</td>
							<td>
								{visible === true ? (
									<button onClick={ocultarTareas} className="btnVisible">
										<VisibilityOffIcon style={{ fontSize: 20 }} />
									</button>
								) : (
									<button onClick={verTareas} className="btnVisible">
										<VisibilityIcon style={{ fontSize: 20 }} />
									</button>
								)}
							</td>
							<td className="etapa-col3">
								{responses === questions ? (
									<div className="responseColorFull">
										<p>{Math.round((responses * 100) / questions)}%</p>
									</div>
								) : (
									<div className="responseColorEmpty">
										<p>{Math.round((responses * 100) / questions)}%</p>
									</div>
								)}
							</td>
						</tr>
					</tbody>
				</table> */}
			</div>
			{visible === true && (
				<div>
					<table className="bloque2">
						<thead>
							{/* <tr>
								<th className="th1">Actividad</th>
								<th className="th1">Descripcion</th>
								<th className="th1">Respuesta</th>
								<th className="th1">Comentario</th>
								<th className="th1">Modificó</th>
								<th className="th1">fecha</th>
								<th className="th1">hora</th>
							</tr> */}
						</thead>
						<tbody className="bloque2">
							{activities.map((activity) => (
								<tr
									className={
										(activity.amount > 0) &
										(activity.amount < activity.totalAmount)
											? "amountPartial"
											: activity.amount === activity.totalAmount
											? "amountTotal"
											: "amountCero"
									}
									key={activity.id}
								>
									<td className="col30p">{activity.activity}</td>
									{activity.position === 1 ? (
										<td className="col20p">
											{localUser.area === "Gerencia" ||
											localUser.area === "Producción" ? (
												<button onClick={clickAsignar} className="btnAsignar2">
													{assignment.assignment === "" ||
													assignment.assignment === null
														? "Asignar"
														: assignment.assignment}
												</button>
											) : (
												<article className="btnAsignar2">
													{assignment.assignment === "" ||
													assignment.assignment === null
														? "Asignar"
														: assignment.assignment}
												</article>
											)}
										</td>
									) : (
										<td className="col20p"></td>
									)}

									<td className="col10p">{activity.amount}</td>
									<td className="col10p">
										{Math.round((activity.amount / activity.totalAmount) * 100)}{" "}
										%
									</td>
									<td className="col20p">{activity.comment}</td>
									{activity.position === 1 ? (
										<td className="col10p">
											{timeToShow}{" "}
											{assignment.statusActingTime === "si" && (
												<label>⏰</label>
											)}
										</td>
									) : (
										<td className="col20p"></td>
									)}
									{/* {activity.position === 1 ? (
										<td className="col10p">
											[{hoursTrunc} : {minutsTrunc}]
										</td>
									) : (
										<td className="col20p"></td>
									)} */}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			)}
		</div>
	);
};

export default DetalleRegProcesosEtapa;
