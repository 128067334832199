import React from "react";

const Message = ({ mensaje, bgColor }) => {
	let styles = {
		margin: "1rem",
		padding: "1rem",
		marginBottom: "1rem",
		textAling: "center",
		backgroundColor: bgColor,
		color: "#fff",
		fontWeight: "bold",
	};
	return (
		<div style={styles}>
			<p>{mensaje}</p>
		</div>
	);
};

export default Message;
