import React from "react";

export const InventoryTransferOutTable = ({ dataBase }) => {
	return (
		<div className="containerInventory04">
			<table className="tabla-process">
				<thead>
					<tr className="fila-tabla-inventory">
						<th className="titulo-tabla-inventory">Fecha</th>
						<th className="titulo-tabla-inventory">Orden de producción</th>
						<th className="titulo-tabla-inventory"># Proceso</th>
						<th className="titulo-tabla-inventory">Etapa</th>
						<th className="titulo-tabla-inventory">Responsable</th>
						<th className="titulo-tabla-inventory">Productos</th>
					</tr>
				</thead>

				<tbody>
					{dataBase.map((transfer, index) => (
						<tr key={index} className="fila-tabla-cuenstas">
							<td className="col-tabla-inventory1">{transfer.date}</td>
							<td className="col-tabla-inventory1">
								{transfer.productionOrder}
							</td>
							<td className="col-tabla-inventory1">{transfer.processNumber}</td>
							<td className="col-tabla-inventory1">{transfer.stage}</td>
							<td className="col-tabla-inventory1">{transfer.userTo}</td>
							<td className="col-tabla-inventory2">
								{transfer.transferProducts.map((product) => (
									<h3 className="titulo4">
										{product.amount} - {product.description}
									</h3>
								))}
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
