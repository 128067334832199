import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import Modal from "../../../../generalComponents/Modal";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import { PermisionsTimeReportForm } from "./permisionsTimeReportForm";
import { PermisionsTimeReportTable } from "./permisionsTimeReportTable";

export const PermisionsTimeReportApp = ({ users }) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const company = localStorage.getItem("usuariosEmpresa");
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [loading, setLoading] = useState(false);
	const [userPermisions, setUserPermisions] = useState(null);
	const [userPermisionsToEdit, setUserPermisionsToEdit] = useState(null);

	useEffect(() => {
		askPermisions();
	}, []);

	const askPermisions = () => {
		setLoading(true);
		const collectionName = "timePermisions-" + company;

		const q = query(
			collection(db, collectionName),
			where("typePermision", "==", "Reports")
		);
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setUserPermisions(dataList);
			setLoading(false);
		});
	};

	const clickCrear = () => {
		let newUserPermisions = userPermisions.filter(
			(line) => line.typePermision === "Reports"
		);
		if (newUserPermisions.length > 0) {
			setUserPermisionsToEdit(newUserPermisions[0]);
		} else {
			setUserPermisionsToEdit(null);
		}

		openModal1();
	};

	return (
		<div>
			<article>
				{users && (
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
						<PermisionsTimeReportForm
							users={users}
							userPermisionsToEdit={userPermisionsToEdit}
							setUserPermisionToEdit={setUserPermisionsToEdit}
							closeModal={closeModal1}
							company={company}
						/>
					</Modal>
				)}

				{loading && <Loader />}

				{userPermisions ? (
					userPermisions.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Modificar
							</button>
							{userPermisions && (
								<PermisionsTimeReportTable userPermisions={userPermisions} />
							)}
						</div>
					) : (
						<h3 className="alertEmpty">No hay Usuarios asignados</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
