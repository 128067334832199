import React from "react";
import { UsersproductionTableRow } from "./usersproductionTableRow";

export const UsersProductionTable = ({ users, setUserToShow, askUserWork }) => {
	return (
		<div>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-etapas1">nombre</th>
						<th className="titulo-tabla-etapas1">Usuario</th>
						<th className="titulo-tabla-etapas1">Acción</th>
					</tr>
				</thead>

				<tbody>
					{users.length > 0 ? (
						users.map((el) => (
							<UsersproductionTableRow
								key={el.id}
								el={el}
								setUserToShow={setUserToShow}
								askUserWork={askUserWork}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">No hay usuarios</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
