import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";

export const EvaluationsForm = ({
	createEvaluation,
	updateEvaluation,
	evaluationToEdit,
	setEvaluationToEdit,
	closeModal,
}) => {
	const company = localStorage.getItem("usuariosEmpresa");

	const initialForm = {
		id: null,
		company: company,
		nameEvaluation: "",
		instructions: "",
		date: "",
		maxTime: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (evaluationToEdit) {
			setForm(evaluationToEdit);
		} else {
			setForm(initialForm);
		}
	}, [evaluationToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!form.nameEvaluation) {
			alert("Debe colocar un nombre a la Evaluación...");
			return;
		}
		if (!form.instructions) {
			alert("Debe llenar el cambpo de INSTRUCCIONES de la evaluación...");
			return;
		}
		if (!form.maxTime) {
			alert("Debe colocar un tiempo máximo para completar la evaluación...");
			return;
		}
		setLoading(true);

		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		form.date = actualDate;

		if (form.id === null) {
			createEvaluation(form);
		} else {
			updateEvaluation(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setEvaluationToEdit(null);
	};

	const handleClose = () => {
		handleReset();
		closeModal();
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{evaluationToEdit ? "Editar Evaluación" : "Agregar Nueva Evaluación"}
				</h3>
				<h2 className="titulo2">
					Es muy importante que la evaluaicón se suba completamente con todas
					sus pregunts y respuestas antes de ser utilizada en un curso
				</h2>
				<h3 className="titulo2">
					{evaluationToEdit &&
						"ALERTA: Solo se recomienda editar una Evaluación mientras se está creando, de lo contrario estaría creando una nueva evaluación"}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<h2 className="titulo5">Nombre de la Evaluación</h2>
							<input
								type="text"
								name="nameEvaluation"
								placeholder="Nombre de la Evaluación"
								onChange={handleChange}
								value={form.nameEvaluation}
								className="input0"
							/>

							<h2 className="titulo5">
								Instrucciones para realizar la evaluación
							</h2>
							<input
								type="text"
								name="instructions"
								placeholder="Instrucciones para realizar la evaluación"
								onChange={handleChange}
								value={form.instructions}
								className="input0"
							/>

							<h2 className="titulo5">
								Máximo tiempo para elaborar el examen [minutos]
							</h2>
							<input
								type="text"
								name="maxTime"
								placeholder="Máximo tiempo para elaborar el examen [minutos]"
								onChange={handleChange}
								value={form.maxTime}
								className="input0"
							/>
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
