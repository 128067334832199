import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { useModal } from "../../generalComponents/hooks/useModal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import Modal from "../../generalComponents/Modal";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { AsignationForm } from "./AsignationForm";
import { AsignationTable } from "./AsignationTable";
import {
	assingCourses,
	deleteDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { AsignationFormEdit } from "./AsignationFormEdit";

export const AsignationApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [isOpenModal2, openModal2, closeModal2] = useModal(false);

	const [dataBase, setDataBase] = useState(null); //listado de Cursos
	const [courseAsignmentToEdit, setCourseAsignmentToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [presentations, setPresentations] = useState([]); //material de cursos
	const [evaluations, setEvaluations] = useState([]);
	const [companies, setCompanies] = useState([]);
	const [studentCourses, setStudentCourses] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askSutudentCourses = () => {
		setLoading(true);
		const collectionName = "coursesAsignment";
		const key = "company";
		const value = company;

		const q = query(collection(db, collectionName), where(key, "==", value));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setStudentCourses(dataList);
			setLoading(false);
		});
	};

	const askCourses = async () => {
		setLoading(true);
		const q = query(
			collection(db, "coursesList"),
			where("company", "==", company)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				const q2 = query(
					collection(db, "coursesList"),
					where("company", "==", "Código M SAS"),
					where("autorizedCompanies", "array-contains", company)
				);
				onSnapshot(
					q2,
					(querySnapshot2) => {
						let dataList2 = querySnapshot2.docs.map((d2) => ({
							...d2.data(),
						}));
						const suma = dataList.concat(dataList2);
						setDataBase(suma);
						setLoading(false);
					},
					(error) => {
						setDataBase(null);
						setError(error);
						setLoading(false);
					}
				);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askSutudentCourses();
		// askCourses();
	}, []);

	const createAssignment = async (data) => {
		setLoading(true);
		const response = await assingCourses(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
	};

	const clickCrear = () => {
		setCourseAsignmentToEdit(null);
		openModal1();
	};

	const deleteAsignment = async (data) => {
		const nameCollection = "coursesAsignment";
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar esta asingación ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	return (
		<div>
			<article>
				<h2 className="titleProcess">CURSOS ASIGNADOS </h2>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<AsignationForm
						createAssignment={createAssignment}
						closeModal={closeModal1}
					/>
				</Modal>
				{courseAsignmentToEdit && (
					<Modal isOpen={isOpenModal2} closeModal={closeModal2}>
						<AsignationFormEdit
							courseAsignmentToEdit={courseAsignmentToEdit}
							closeModal2={closeModal2}
						/>
					</Modal>
				)}

				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}

				{studentCourses ? (
					studentCourses.length >= 0 ? (
						<div>
							<button onClick={clickCrear} className="btn-create-document">
								Asignar Cursos
							</button>
							<AsignationTable
								studentCourses={studentCourses}
								setCourseAsignmentToEdit={setCourseAsignmentToEdit}
								openModal2={openModal2}
								deleteAsignment={deleteAsignment}
							/>
						</div>
					) : (
						<h3 className="alertEmpty">No hay Cursos para mostrar</h3>
					)
				) : (
					<h3 className="alertEmpty">Buscando...</h3>
				)}
			</article>
		</div>
	);
};
