import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import "../../styles/MenuProcesos.css";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import CrudStagesForm from "./CrudStagesForm";
import CrudStagesTable from "./CrudStagesTable";
import {
	addDataKey2,
	askDataKeyValue,
	askDataKeyValue2,
	askDataKeyValueOrder,
	downPositionStages,
	updateDocumentById,
	upPositionStages,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudStagesApp = () => {
	const [db, setDb] = useState(null);
	const [stageToEdit, setStageToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [irActivity, setIrActivity] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const process = localStorage.getItem("procesoEditar");
	const area = localStorage.getItem("areaProcesoEditar");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askStages = async () => {
		setLoading(true);
		const nameCollecion = "stages-" + company;
		const key2 = "process";
		const value2 = process;
		const response = await askDataKeyValueOrder(nameCollecion, key2, value2);
		if (response.statusResponse === true) {
			setDb(response.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		setDb(null);
		askStages();
		setRefresh(false);
	}, [refresh]);

	const createStage = async (data) => {
		setLoading(true);
		const nameCollection = "stages-" + company;
		const key2 = "process";
		const key3 = "stage";
		const value2 = process;
		const value3 = data.stage;
		const response = await addDataKey2(
			nameCollection,
			data,
			key2,
			value2,
			key3,
			value3
		);
		// const response = await addEtapa(data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askStages();
		setLoading(false);
	};

	const updateStage = async (data) => {
		setLoading(true);
		// console.log("va a editar el id:", data.id);
		const nameCollection = "stages-" + company;
		const docId = data.id;
		const changes = data;
		const key2 = "process";
		const value2 = data.process;
		const key3 = "stage";
		const value3 = data.stage;
		const response = await askDataKeyValue2(
			nameCollection,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta etapa ya existe");
				setLoading(false);
			} else {
				const stageName01 = stageToEdit.stage;

				if (stageName01 !== value3) {
					const response2 = await askDataKeyValue2(
						"activities-" + company,
						key2,
						value2,
						key3,
						stageName01
					);
					if (response2.statusResponse === true) {
						const activitiesTomodify = response2.data;
						activitiesTomodify.map(async (activity) => {
							activity.stage = value3;
							await updateDocumentById(
								"activities-" + company,
								activity.id,
								activity
							);
						});
					}
				}
				await updateDocumentById(nameCollection, docId, changes);
				askStages();
				setLoading(false);
			}
		} else {
			askStages();
			setLoading(false);
		}
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const clickCreate = () => {
		setStageToEdit(null);
		openModal1();
	};

	const downPos = async (data) => {
		setLoading(true);
		const response = await downPositionStages(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
		setRefresh(true);
	};

	const upPos = async (data) => {
		setLoading(true);
		const response = await upPositionStages(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setLoading(false);
		setRefresh(true);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<article>
						<h3 className="titleProcess">ETAPAS ({company})</h3>

						<h3 className="titulo3">Area: {area}</h3>
						<h3 className="titulo3">Proceso: {process}</h3>
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudStagesForm
								createStage={createStage}
								updateStage={updateStage}
								setStageToEdit={setStageToEdit}
								stageToEdit={stageToEdit}
								company={company}
								process={process}
								closeModal={closeModal1}
								db={db}
							/>
						</Modal>
						{loading ? (
							<Loader />
						) : (
							<div>
								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{db ? (
									<div>
										<button onClick={clickCreate} className="btn-crear-proceso">
											Crear Etapa
										</button>
										<CrudStagesTable
											stages={db}
											setStageToEdit={setStageToEdit}
											openModal1={openModal1}
											setIrActivity={setIrActivity}
											downPos={downPos}
											upPos={upPos}
										/>
									</div>
								) : (
									<h3>No hay etapas para mostrar</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
			{/* {refresh && <Redirect to="/process-list" />} */}
			{irActivity === true && <Redirect to="/activities-list" />}
		</div>
	);
};

export default CrudStagesApp;
