import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import Loader from "../../../../generalComponents/Loader";
import Select from "react-select";
import {
	where,
	query,
	onSnapshot,
	collection,
	getFirestore,
	orderBy,
	addDoc,
	getDocs,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";

export const FormSelectProcess = ({
	listProcess,
	closeModal,
	setAviableInventory,
	openModal1,
}) => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const initialForm = {
		processNumber: null,
	};

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [loading, setLoading] = useState(false);
	const [selectListProcess, setSelectListProcess] = useState(null);
	const [form, setForm] = useState(initialForm);
	const [error, setError] = useState(null);

	useEffect(() => {
		convertListProcess();
	}, [listProcess]);

	const convertListProcess = () => {
		let selectListProv = [];
		listProcess.map((item) => {
			const lineInventory = {
				value: item,
				label: item,
			};
			selectListProv.push(lineInventory);
		});
		setSelectListProcess(selectListProv);
	};

	const handleSelectProcessNumber = async (e) => {
		setForm({
			...form,
			processNumber: e.value,
		});
	};

	const handleClose = (e) => {
		setForm(initialForm);
		closeModal();
	};

	const askInvetoryInTransit = async (processNumber) => {
		let result = null;
		const nameCollection = "inventoryInTransit-" + company;
		const q = query(
			collection(db, nameCollection),
			where("from", "==", localUser.email),
			where("processNumber", "==", processNumber)
		);
		await getDocs(q)
			.then((querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				result = dataList;
			})
			.catch((error) => {
				result.error = error;
				result = [];
			});
		return result;
	};

	const askParticularInventory = async () => {
		setLoading(true);
		const pendingTransfer = await askInvetoryInTransit(form.processNumber);
		const nameCollection = "inventoryParticular-" + company;
		const q = query(
			collection(db, nameCollection),
			where("user", "==", localUser.email),
			where("processNumber", "==", form.processNumber)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// setParticularInventory(dataList);
				if (pendingTransfer.length === 0) {
					setAviableInventory(dataList);
				} else {
					pendingTransfer.map((productPending) => {
						const productToModify = dataList.filter(
							(product) => product.refInventory === productPending.refInventory
						);
						const newAviability = dataList.filter(
							(product) => product.refInventory !== productPending.refInventory
						);
						productToModify[0].amount =
							productToModify[0].amount - productPending.amount;
						newAviability.push(productToModify[0]);
						dataList = newAviability;
					});

					setAviableInventory(dataList);
				}

				setLoading(false);
				closeModal();
				openModal1();
			},
			(error) => {
				// setParticularInventory(null);
				setError(error);
				setLoading(false);
				closeModal();
			}
		);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);

		if (!form.processNumber) {
			alert("No ha seleccionado un proceso...");
			setLoading(false);
			return;
		}

		console.log("para ejecutar form:", form);

		// const response = await recTransferInventory(form, company);
		// if (response.statusResponse) {
		// 	handleReset();
		// 	alert("Se ha grabado correctamente la transferencia...");
		// } else {
		// 	setError(response.error);
		// }
		// window.location.replace(""); //REFRESCA LA PAGINA

		await askParticularInventory();
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className="form-procesos">
				<aritcle className="title2-account">
					Seleccione Número de Proceso desde el cual desea hacer la trasferecia
					de invnetario
				</aritcle>
				{loading ? (
					<Loader />
				) : (
					<div>
						{selectListProcess && selectListProcess.length > 0 ? (
							<Select
								className="input1_3"
								options={selectListProcess}
								onChange={handleSelectProcessNumber}
								placeholder="Seleccione número de proceso"
							/>
						) : (
							<article>No hay Inventario disponible para transferir...</article>
						)}

						<hr />
						<input
							type="submit"
							value="Enviar"
							className="btn-send-reg-asign"
						/>

						<input
							type="reset"
							value="Cerrar"
							onClick={handleClose}
							className="btn-close-reg-asign"
						/>
					</div>
				)}
			</form>
		</div>
	);
};
