import React, { useEffect, useState } from "react";
import { storage } from "../../../config/firebaseConfig";
import { getDownloadURL, ref, uploadBytesResumable } from "@firebase/storage";
import Loader from "../../generalComponents/Loader";
import ProgressBar from "../../generalComponents/ProgressBar";

const PresentationsForm2 = ({
	updateDocument,
	documentToEdit,
	setDocumentToEdit,
	company,
	closeModal,
	setError,
}) => {
	const [form, setForm] = useState(documentToEdit);
	const [loading, setLoading] = useState(false);
	const [progressLoad, setProgressLoad] = useState(-1);

	useEffect(() => {
		setForm(documentToEdit);
	}, [documentToEdit]);

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (!form.presentationsResource) {
			alert("Debe seleccionar un archivo para subir..");
			return;
		}

		setLoading(true);
		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		form.date = actualDate;
		updateDocument(form);
		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleChangeFile = (e) => {
		setLoading(true);
		const file = e.target.files[0];

		const storageRef = ref(
			storage,
			`${e.target.name}` + "/" + form.company + "/" + file.name
		);
		const uploadTask = uploadBytesResumable(storageRef, file);

		uploadTask.on(
			"state_changed",
			(snapshot) => {
				const progress = Math.floor(
					(snapshot.bytesTransferred / snapshot.totalBytes) * 100
				);
				setProgressLoad(progress);

				switch (snapshot.state) {
					case "paused":
						console.log("Upload is paused");
						break;
					case "running":
						console.log("Upload is running");
						break;
				}
			},
			(error) => {
				setError(error);
				console.log(error);
			},
			() => {
				getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
					console.log("File available at", downloadURL);
					setForm({
						...form,
						[e.target.name]: downloadURL,
					});
				});
			}
		);
		setLoading(false);
	};

	const handleReset = (e) => {
		setDocumentToEdit(null);
	};

	const handleClose = () => {
		handleReset();
		closeModal();
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">{documentToEdit && "Cambio de Archivo"}</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">Empresa: {company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							{!form ? (
								<div>
									<p>
										No se puedo leer la base de datos, por favor cumunicarse con
										el Administrador IT
									</p>
								</div>
							) : (
								<div>
									{/* <h2 className="title2-account">Area: {form.area}</h2> */}
									<h2 className="title2-account">Nombre: {form.name}</h2>
									<h2 className="title2-account">Versión: {form.version}</h2>
									<input
										type="file"
										accept=".pdf, .mp4"
										name="presentationsResource"
										id=""
										onChange={handleChangeFile}
										className="btnLoadFile"
									/>
									{(progressLoad > 0) & (progressLoad < 100) && (
										<ProgressBar
											bgcolor={"green"}
											fontcolor={"white"}
											progress={progressLoad}
											height={"30px"}
										/>
									)}
									{progressLoad !== 100 ? (
										progressLoad >= 0 && (
											<p className="loadingFile">Cargando...{progressLoad} %</p>
										)
									) : (
										<p className="loadedFile">Archivo Cargado</p>
									)}
								</div>
							)}
							<hr />
							{(progressLoad === -1 || progressLoad === 100) && (
								<div>
									<input
										type="submit"
										value="Enviar"
										className="btn-send-reg-asign"
									/>
									<input
										type="reset"
										value="Cerrar"
										onClick={handleClose}
										className="btn-close-reg-asign"
									/>
								</div>
							)}
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default PresentationsForm2;
