import { initializeApp } from "@firebase/app";
import React, { useState, useEffect } from "react";
import {
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	getFirestore,
	doc,
	setDoc,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import { ParticularTransferInMake } from "./particularTransferInMake";

export const ParticularTransferInApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [dataBase, setDataBase] = useState(null);
	const [assignments, setAssignments] = useState(null); //asignaciones del usuario
	const [inventoryTransfer, setInventoryTransfer] = useState(null); //transferencias de ingreso al usuario
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		askAssignmentsUser();
		askInventoryTransfer();
	}, []);
	const askAssignmentsUser = async () => {
		setLoading(true);
		const q = query(
			collection(db, "assignments-" + company),
			where("status", "==", "abierto"),
			orderBy("date", "desc")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setAssignments(dataList);
				// setRefresh(true);
				setLoading(false);
			},
			(error) => {
				setAssignments(null);
				setError(error);
				setTimeout(function () {
					setLoading(false);
				}, 1000);
			}
		);
	};

	const askInventoryTransfer = async () => {
		const nameCollection = "inventoryTransfer-" + company;
		const q = query(
			collection(db, nameCollection),
			where("userTo", "==", localUser.email),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setInventoryTransfer(dataList);

				setLoading(false);
			},
			(error) => {
				setInventoryTransfer(null);
				setError(error);
				setTimeout(function () {
					setLoading(false);
				}, 1000);
			}
		);
	};

	// // // const pruebaBoton = async () => {
	// // // 	console.log("prueba grabar en base de datos");
	// // // 	// const docData = {
	// // // 	// 	stringExample: "Hello world!",
	// // // 	// 	booleanExample: true,
	// // // 	// 	numberExample: 3.14159265,
	// // // 	// 	arrayExample: [5, true, "hello"],
	// // // 	// 	nullExample: null,
	// // // 	// 	objectExample: {
	// // // 	// 		a: 5,
	// // // 	// 		b: {
	// // // 	// 			nested: "foo",
	// // // 	// 		},
	// // // 	// 	},
	// // // 	// };
	// // // 	const idDocument =
	// // // 		company + "-" + localUser.email + "-" + assignments[0].processNumber;
	// // // 	console.log("idDocument:", idDocument);
	// // // 	const docData = {
	// // // 		algo: "algo",
	// // // 		id: idDocument,
	// // // 	};
	// // // 	await setDoc(doc(db, "data", docData.id), docData);
	// // // };
	return (
		<div className="conten0">
			<div className="conten1">
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{loading ? (
					<Loader />
				) : (
					assignments &&
					inventoryTransfer && (
						<div>
							<article className="titulo11">
								Inventario Pendiente por Recibir
							</article>
							{/* <div>
								<h2>prueba para borrar</h2>
								<button className="btn" onClick={pruebaBoton}>
									hacer
								</button>
							</div> */}
							{inventoryTransfer.length > 0 ? (
								<ParticularTransferInMake
									assignments={assignments}
									inventoryTransfer={inventoryTransfer}
								/>
							) : (
								<div>
									<article className="titulo2">
										No hay inventario pendiente por recibir...
									</article>
								</div>
							)}
						</div>
					)
				)}
			</div>
		</div>
	);
};
