import React, { useEffect, useState } from "react";
import { onAuthStateChanged, getAuth, signOut } from "@firebase/auth";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import LoginForm from "./components/generalComponents/LoginForm";
import Error404 from "./pages/Error404";
import AdminEmpresa from "./pages/AdminEmpresa";
import Usuarios from "./pages/Usuarios";
import CrudUsuariosApp from "./components/administrator/usuarios/CrudUsuariosApp";
import DetalleRegProcesos from "./components/processes/regProcess/DetalleRegProcesos";
import CrudDocumentsApp from "./components/Documents/CrudDocumentsApp";
import CrudCuentasApp from "./components/administrator/cuentas/CrudCuentasApp";
import CrudProcessApp from "./components/processes/process/CrudProcessApp";
import CrudStagesApp from "./components/processes/stages/CrudStagesApp";
import CrudActivitiesApp from "./components/processes/activities/CrudActivitiesApp";
import CrudRegProcessApp from "./components/processes/regProcess/CrudRegProcessApp";
import Worker from "./pages/Worker";
import CrudRegAssignmentsApp from "./components/processes/regAssignments/CrudRegAssignmentsApp";
import DetailActivitiesAssignmentTable from "./components/processes/regAssignments/DetailActivitiesAssignmentTable";
import ViewDocuments from "./components/Documents/ViewDocuments";
import { CrudIndicatorsApp } from "./components/processes/indicators/CrudIndicatorsApp";
import { ViewIndicator } from "./components/processes/indicators/ViewIndicator";
import ProcessAdmin from "./components/processes/processAdmin/ProcessAdmin";
import CrudProcessAdminApp from "./components/processes/processAdmin/CrudProcessAdminApp";
import CrudStagesAdminApp from "./components/processes/stagesAdmin/CrudStagesAdminApp";
import CrudActivitiesAdminApp from "./components/processes/activitiesAdmin/CrudActivitiesAdminApp";
import { CrudDataBaseApp } from "./components/processes/dataBase/CrudDataBaseApp";
import { ViewDataBase } from "./components/processes/dataBase/ViewDataBase";
import { MainTraining } from "./components/training/mainTraining/MainTraining";
import ViewPresentations from "./components/training/presentations/ViewPresentations";
import { MainTrainingCompany } from "./components/training/mainTraining/MainTrainingCompany";
import MainTrainingStudent from "./components/training/mainTraining/MainTrainingStudent";
import { ChangePassword } from "./components/administrator/usersPaswords/ChangePassword";
import SuperAdministrator from "./pages/SuperAdministrator";
import Supervisor from "./pages/Supervisor";
import { ClientsApp } from "./components/production/clients/clientsApp";
import { InventoriesApp } from "./components/production/inventories/masterInventory/inventoriesApp";
import { ProductionOrdersApp } from "./components/production/productionOrders/productionOrdersApp";
import { ProductionOrderViewApp } from "./components/production/productionOrderView/productionOrderViewApp";
import { ProcessOrderApp } from "./components/production/processOrders/processOrdersApp";
import { ProcessOrderViewApp } from "./components/production/processOrdersView/processOrderViewApp";
import { RegProcessAdmin } from "./components/processes/regProcessAdmin/RegProcessAdmin";
import { RegProcessAdminApp } from "./components/processes/regProcessAdmin/RegProcessAdminApp";
import InventoryPermisionsApp from "./components/production/inventories/inventroyPermisions/inventoryPermisionsApp";
import { GeneralInventoryApp } from "./components/production/inventories/generalInventroy/generalInventoryApp";
import { ParticularInventoryApp } from "./components/production/inventories/particularInventory/particularInventoryApp";
import { GeneralInventoryUser3App } from "./components/production/inventories/generalInventroy/generalInventoryUser3App";
import { TimeManagementApp } from "./components/production/timeManagement/timeManagementApp";
import StopClock from "./pages/stopClock";
import { DataBasesOrganization } from "./pages/DataBasesOrganization";
import { ProcessReplication } from "./components/processes/processReplication/processReplication";
import { ProductionManagementApp } from "./components/production/productionManagement/productionManagementApp";
import { DataVersionApp } from "./components/dataVersion/dataVersionApp";

function App() {
	const [isLoggedIn, setIsLoggedIn] = useState(false);
	const [checking, setChecking] = useState(true);

	const auth = getAuth();

	useEffect(() => {
		onAuthStateChanged(auth, async (user) => {
			if (user?.uid) {
				// console.log("que hay en user:", user);
				setIsLoggedIn(true);
			} else {
				setIsLoggedIn(false);
			}
			setChecking(false);
		});
	}, [setChecking]);

	return (
		<div>
			<Router>
				{/* <div className="titulo">
					<img src={imagen} className="icono" />
				</div> */}
				<div className="separador"></div>
				<Switch>
					<Route exact path="/" component={LoginForm} />
					{/* <Route exact path="/login" component={LoginForm} /> */}
					{/* <Route
						exact
						path="/account/administrator"
						component={AccountAdminstrator}
					/> */}
					<Route exact path="/accounts" component={CrudCuentasApp} />
					<Route exact path="/documents" component={CrudDocumentsApp} />
					<Route exact path="/indicators" component={CrudIndicatorsApp} />
					<Route exact path="/DataBase" component={CrudDataBaseApp} />
					<Route
						exact
						path="/super_administrator"
						component={SuperAdministrator}
					/>
					<Route exact path="/administrator_company" component={AdminEmpresa} />
					<Route exact path="/supervisor_company" component={Supervisor} />
					<Route exact path="/worker-company" component={Worker} />
					<Route exact path="/users" component={Usuarios} />
					<Route exact path="/processAdmin" component={ProcessAdmin} />
					<Route exact path="/stop-clock/:user" component={StopClock} />
					<Route
						exact
						path="/register-processAdmin"
						component={RegProcessAdmin}
					/>
					<Route
						exact
						path="/dataBase-Reorganizated"
						component={DataBasesOrganization}
					/>
					<Route exact path="/process-replied" component={ProcessReplication} />
					<Route
						exact
						path="/users/manage/:company"
						component={CrudUsuariosApp}
					/>
					<Route
						exact
						path="/process/manage/:company"
						component={CrudProcessAdminApp}
					/>
					<Route
						exact
						path="/register-process/manage/:company"
						component={RegProcessAdminApp}
					/>
					<Route
						exact
						path="/production-orders"
						component={ProductionOrdersApp}
					/>
					<Route
						exact
						path="/production-process-orders"
						component={ProcessOrderApp}
					/>
					<Route
						exact
						path="/production-orders/order/:productionOrder"
						component={ProductionOrderViewApp}
					/>
					<Route
						exact
						path="/production-process/order/:productionOrder"
						component={ProcessOrderViewApp}
					/>
					<Route exact path="/clients" component={ClientsApp} />
					<Route exact path="/inventories" component={InventoriesApp} />
					<Route
						exact
						path="/general-inventory"
						component={GeneralInventoryApp}
					/>
					<Route
						exact
						path="/inventory-user3"
						component={GeneralInventoryUser3App}
					/>
					<Route exact path="/bpw-version" component={DataVersionApp} />
					<Route
						exact
						path="/particular-inventory"
						component={ParticularInventoryApp}
					/>
					<Route exact path="/time-management" component={TimeManagementApp} />
					<Route
						exact
						path="/process-management"
						component={ProductionManagementApp}
					/>
					<Route
						exact
						path="/stages/manage/:company"
						component={CrudStagesAdminApp}
					/>
					<Route
						exact
						path="/activities/manage/:company"
						component={CrudActivitiesAdminApp}
					/>
					<Route exact path="/process-list" component={CrudProcessApp} />
					<Route exact path="/stages-list" component={CrudStagesApp} />
					<Route exact path="/activities-list" component={CrudActivitiesApp} />
					<Route
						exact
						path="/supervisor-company/reg_procesos"
						component={CrudRegProcessApp}
					/>
					<Route
						exact
						path="/supervisor-company/reg_procesos/ver_orden"
						component={DetalleRegProcesos}
					/>
					<Route
						exact
						path="/worker-company/assignments"
						component={CrudRegAssignmentsApp}
					/>
					<Route
						exact
						path="/worker-company/assignments/view-activities"
						component={DetailActivitiesAssignmentTable}
					/>
					<Route
						exact
						path="/bpw/document-view/:id"
						component={ViewDocuments}
					/>
					<Route
						exact
						path="/bpw/presentation-view/:id"
						component={ViewPresentations}
					/>
					<Route exact path="/view_indicator" component={ViewIndicator} />
					<Route exact path="/view_DataBase" component={ViewDataBase} />
					<Route exact path="/admin_training_main" component={MainTraining} />
					<Route
						exact
						path="/admin_training_main_company"
						component={MainTrainingCompany}
					/>
					<Route
						exact
						path="/students_training_main_company"
						component={MainTrainingStudent}
					/>
					<Route exact path="/admin_user" component={ChangePassword} />
					<Route
						exact
						path="/inventory_permisions"
						component={InventoryPermisionsApp}
					/>

					<Route path="*" component={Error404} />
				</Switch>
			</Router>
		</div>
	);
}

export default App;
