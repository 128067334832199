import React from "react";
import CrudSucursalesTableRow from "./CrudSucursalesTableRow";

const CrudSucursalesTable = ({ data, setData2toEdit, deleteData }) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Sucursales</h3>
			<table className="tabla-cuentas">
				<thead>
					<tr>
						<th className="titulo-tabla">Sucursal</th>
						<th className="titulo-tabla">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => (
							<CrudSucursalesTableRow
								key={el.id}
								el={el}
								setData2toEdit={setData2toEdit}
								deleteData={deleteData}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudSucursalesTable;
