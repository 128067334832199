import React from "react";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const CrudStagesTableRow = ({
	el,
	setStageToEdit,
	openModal1,
	setIrActivity,
	totalStages,
	downPos,
	upPos,
}) => {
	const processRelease = localStorage.getItem("processRelease");

	const buttonAction = (el) => {
		setStageToEdit(el);
		openModal1();
	};
	const irActividades = () => {
		localStorage.setItem("etapaEditar", el.stage);
		setIrActivity(true);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-etapas1">{el.position}</td>
			<td className="col-tabla-etapas2">{el.stage}</td>
			<td className="col-tabla-etapas2">{el.descriptionStage}</td>
			<td className="col-tabla-etapas3">
				<div>
					<button className="btn-show-stage" onClick={() => irActividades()}>
						Ver Actividades
					</button>
					{processRelease === "no" && (
						<button
							onClick={() => buttonAction(el)}
							className="btn-editar-procesos"
						>
							Editar
						</button>
					)}
				</div>
			</td>
			<td className="col-tabla-etapas4">
				{totalStages > 1 &&
					(el.position === 1 ? (
						<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
							<ArrowDropDownIcon />
						</button>
					) : (el.position > 1) & (el.position < totalStages) ? (
						<div>
							<button className="btn-cambiar-etapa" onClick={() => downPos(el)}>
								<ArrowDropDownIcon />
							</button>
							<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
								<ArrowDropUpIcon />
							</button>
						</div>
					) : (
						<button className="btn-cambiar-etapa" onClick={() => upPos(el)}>
							<ArrowDropUpIcon />
						</button>
					))}
			</td>
		</tr>
	);
};

export default CrudStagesTableRow;
