import React, { useState, useEffect } from "react";
import CrudSucursalesForm from "./CrudSucursalesForm";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import CrudSucursalesTable from "./CrudSucursalesTable";
import {
	addAgency,
	askDataKeyValue,
	askDataKeyValue2,
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudSucursalesApp = ({ company }) => {
	const [db, setDb] = useState(null);
	const [data2toEdit, setData2toEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const collectionName = "agencies-" + company;

	const askingAgencies = async () => {
		setLoading(true);
		const respuesta = await askDataSimple(collectionName);
		if (respuesta.statusResponse === true) {
			setDb(respuesta.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askingAgencies();
	}, [company, error]);

	const createSucursal = async (data) => {
		const response = await addAgency(collectionName, data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askingAgencies();
	};

	const updateSucursal = async (data) => {
		setLoading(true);
		setError(null);
		const docId = data.id;
		const changes = data;
		const key = "agency";
		const value = data.agency;
		const response = await askDataKeyValue(collectionName, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta sucursal ya existe");
			} else {
				await updateDocumentById(collectionName, docId, changes);
			}
		}
		await askingAgencies();
		setLoading(false);
	};

	const deleteSucursal = async (id) => {
		const docId = id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar la sucursal?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(collectionName, docId);
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	return (
		<div>
			<h3 className="titulo1">SUCURSALES de {company}</h3>
			<article>
				<CrudSucursalesForm
					createSucursal={createSucursal}
					updateSucursal={updateSucursal}
					data2toEdit={data2toEdit}
					setData2toEdit={setData2toEdit}
					company={company}
				/>
				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{db && (
					<CrudSucursalesTable
						data={db}
						setData2toEdit={setData2toEdit}
						deleteData={deleteSucursal}
					/>
				)}
			</article>
		</div>
	);
};

export default CrudSucursalesApp;
