import React, { useEffect, useState } from "react";
import ViewCoursesTableRow from "./ViewCoursesTableRow";

export const ViewCoursesTable = ({ materialList }) => {
	const [data, setData] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	useEffect(() => {
		setData(materialList);
	}, []);

	return (
		<div>
			{console.log("--data:", materialList)}
			<h3 className="titulo1">Material del Curso</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleTableDocuments01">Area</th> */}
						<th className="titleTableDocuments02">Nombre</th>
						<th className="titleTableDocuments02">Descripcion</th>
						<th className="titleTableDocuments03">Acción</th>
					</tr>
				</thead>
				<tbody>
					{materialList.length > 0 ? (
						materialList.map((el) => (
							<ViewCoursesTableRow key={el.id} el={el} />
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
