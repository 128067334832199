import React, { useState, useEffect } from "react";
import { initializeApp } from "@firebase/app";
import {
	addDoc,
	collection,
	doc,
	getFirestore,
	updateDoc,
	where,
	limit,
	startAfter,
	query,
	getDocs,
} from "@firebase/firestore";
import { Redirect } from "react-router";
import Loader from "../components/generalComponents/Loader";
import Message from "../components/generalComponents/Message";
import NavbarBpw from "../components/generalComponents/NavbarBpw";
import { firebaseConfig } from "../config/firebaseConfig";

export const DataBasesOrganization = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [goTo, setGoTo] = useState(null);

	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const initialForm = {
		coleccion: null,
		company: null,
	};
	const [form, setForm] = useState(initialForm);

	useEffect(() => {
		setForm(initialForm);
	}, []);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async () => {
		setLoading(true);
		console.log("colección:", form.coleccion);
		console.log("company:", form.company);

		const nameCollection = form.coleccion;
		const key = "company";
		const value = form.company;

		let firstQuery = true;
		let consulting = true;
		let stepLength = 10000;
		let lastVisible = null;
		let totalConsulting = 0;

		while (consulting) {
			if (firstQuery) {
				const first = query(
					collection(db, nameCollection),
					where(key, "==", value),
					limit(stepLength)
				);
				await getDocs(first)
					.then((querySnapshot) => {
						let dataList = querySnapshot.docs.map((d) => ({
							...d.data(),
						}));
						console.log("datos leidos:", dataList);
						firstQuery = false;

						// filtrar quitándole la compañía y el id, luego grabar en la nueva coleeción

						dataList.map(async (item) => {
							let document = item;
							const propToBeDeleted = "company";
							delete document[propToBeDeleted];
							delete document["id"];
							// delete document["position"];
							const newCN = form.coleccion + "-" + form.company;
							console.log("datos a grabar: ", document);
							await addDoc(collection(db, newCN), document).then(
								async (res) => {
									if (res.id) {
										console.log("--- grabó...");
										const docRef = doc(db, newCN, res.id);
										await updateDoc(docRef, {
											id: res.id,
										});
									}
								}
							);
						});

						totalConsulting = totalConsulting + dataList.length;
						if (dataList.length < stepLength) {
							consulting = false;
						} else {
							lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
							// console.log("last primer lote", lastVisible);
						}
					})
					.catch((error) => {
						console.log("hay error:", error);
						consulting = false;
						setError("Error: se interrumpió la lectura de datos iniciales");
					});
			} else {
				const next = query(
					collection(db, nameCollection),
					where(key, "==", value),
					startAfter(lastVisible),
					limit(stepLength)
				);
				await getDocs(next)
					.then((querySnapshot) => {
						let dataList = querySnapshot.docs.map((d) => ({
							...d.data(),
						}));
						// filtrar quitándole la compañía y el id, luego grabar en la nueva coleeción

						dataList.map(async (item) => {
							let document = item;
							const propToBeDeleted = "company";
							delete document[propToBeDeleted];
							delete document["id"];
							// delete document["position"];
							const newCN = form.coleccion + "-" + form.company;
							await addDoc(collection(db, newCN), document).then(
								async (res) => {
									if (res.id) {
										const docRef = doc(db, newCN, res.id);
										await updateDoc(docRef, {
											id: res.id,
										});
									}
								}
							);
						});
						totalConsulting = totalConsulting + dataList.length;
						if (dataList.length < stepLength) {
							consulting = false;
						} else {
							lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
							// console.log("last siguiente lote", lastVisible);
						}
					})
					.catch((error) => {
						console.log("hay error:", error);
						consulting = false;
						setError("Error: se interrumpió la lectura de datos iniciales");
					});
			}
		}

		console.log("Consulta Total: ", totalConsulting);
		handleReset();

		setLoading(false);
	};

	const handleReset = () => {
		setForm(initialForm);
	};
	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />
					{localUser.email === "john.mejia@codigom.com.co" && (
						<div className="titulo1">
							<article className="titleProcess">
								REORGANIZACIÓN DE BASE DE DATOS
							</article>
							{loading ? (
								<Loader />
							) : (
								<form onSubmit={handleSubmit} className="form-cuentas">
									<input
										type="text"
										name="coleccion"
										placeholder="Colección a replicar"
										onChange={handleChange}
										value={form.coleccion}
										className="input1"
									/>
									<input
										type="text"
										name="company"
										placeholder="Empresa"
										onChange={handleChange}
										value={form.company}
										className="input1"
									/>
									<hr />
									{/* <article className="titulo11">
										Recuerde cambiar datos en DataBasesOrganization.js
									</article> */}
									<hr />
									<input
										type="submit"
										value="Enviar"
										className="btn-send-accounts"
									/>
									<input
										type="reset"
										value="Limpiar"
										onClick={handleReset}
										className="btn-clean-accounts"
									/>
								</form>
							)}

							{/* <div>
								<article>Colección: areas</article>
								
								<button onClick={GenerarAreas}>Generar Nuevas</button>
								<button>Borrar Antiguas</button>
							</div> */}

							{error && <Message mensaje={error} bgColor="#dc3545" />}
						</div>
					)}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
