import "../styles/Modal.css";

const Modal = ({ children, isOpen, closeModal }) => {
	const handleModalContainerClick = (e) => e.stopPropagation();
	return (
		<article className={`modalito ${isOpen && "is-open"}`} onClick={closeModal}>
			<div className="modal-container" onClick={handleModalContainerClick}>
				{/* <p>bienvendio al modal</p> */}
				{/* <button className="modal-close" onClick={closeModal}>
					X
				</button> */}
				{children}
			</div>
		</article>
	);
};

export default Modal;
