import React, { useState, useEffect } from "react";
import { Redirect } from "react-router";
import {
	addAccount,
	askData,
	askDataKeyValue,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import { CrudCuentasForm } from "./CrudCuentasForm";
import CrudCuentasTable from "./CrudCuentasTable";
import "../../../components/styles/AccountAdministrator.css";

const CrudCuentasApp = () => {
	const [db, setDb] = useState(null);
	const [dataToEdit, setDataToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askAccounts = async () => {
		setLoading(true);
		// const respuesta = await askCuentas();
		const nameCollection = "accounts";
		const response = await askData(nameCollection);
		// console.log("acaba de devolver estatus response", response.statusResponse);
		if (response.statusResponse === true) {
			setDb(response.data);
			setError(null);
			setLoading(false);
		} else {
			setDb(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askAccounts();
	}, []);

	const createData = async (data) => {
		data.pos = db.length + 1;
		const nameCollection = "accounts";
		// data.pos = Date.now();
		// setDb([...db, data]);
		setLoading(true);
		console.log("Va a intentar adicionar cuenta con data:", data);
		const response = await addAccount(nameCollection, data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askAccounts();
		setLoading(false);
	};

	const updateData = async (data) => {
		setLoading(true);
		const nameCollection = "accounts";
		const docId = data.id;
		const changes = data;
		const key = "company";
		const value = data.company;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta Empresa ya existe");
			} else {
				await updateDocumentById(nameCollection, docId, changes);
			}
		}
		await askAccounts();
		setLoading(false);
	};

	const deleteData = (id) => {
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar el registro con el id '${id}'?`
		);

		if (isDelete) {
			let newData = db.filter((el) => el.id !== id);
			setDb(newData);
		} else {
			return;
		}
	};

	return (
		<div>
			{localUser.type === "1" ? (
				<div>
					<NavbarBpw />
					<h3 className="titulo">C U E N T A S</h3>
					<article>
						{!dataToEdit && (
							<CrudCuentasForm
								createData={createData}
								updateData={updateData}
								dataToEdit={dataToEdit}
								setDataToEdit={setDataToEdit}
							/>
						)}
						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{db && (
							<CrudCuentasTable
								data={db}
								setDataToEdit={setDataToEdit}
								deleteData={deleteData}
							/>
						)}
						{dataToEdit && (
							<div>
								<br />
								<hr />
								<hr />
								<CrudCuentasForm
									createData={createData}
									updateData={updateData}
									dataToEdit={dataToEdit}
									setDataToEdit={setDataToEdit}
								/>
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default CrudCuentasApp;
