import React, { useEffect, useState } from "react";
import {
	addData,
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../generalComponents/Loader";

export const TransformationPermitTable = ({ transformations }) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localStorage.getItem("usuariosEmpresa");
	// const [inventoryInTransit, setInventoryInTransit] = useState(null);
	const [inventoryFrom, setInventoryFrom] = useState(null);
	const [loading, setLoading] = useState(false);
	const collectionName = "inventoryParticular-" + company;
	const collectionName2 = "inventoryTransform-" + company;
	const collectionName3 = "inventoryInTransit-" + company;

	const askInventoryInTransit = async (user, processNumber) => {
		console.log("1. empieza la consulta");
		setLoading(true);
		let res = null;
		await askDataKeyValue2Simple(
			collectionName3,
			"from",
			user,
			"processNumber",
			processNumber
		).then((result) => {
			console.log("2. result.data: ", result.data);
			// setInventoryInTransit(result.data);
			res = result.data;
			setLoading(false);
		});
		return res;
	};

	const askInventoryParticular = async (user, processNumber) => {
		setLoading(true);
		let res = null;
		await askDataKeyValue2Simple(
			collectionName,
			"user",
			user,
			"processNumber",
			processNumber
		).then((result) => {
			// setInventoryFrom(result.data);
			setLoading(false);
			res = result.data;
		});
		return res;
	};

	const acceptAction = async (transformation) => {
		setLoading(true);
		console.log("transformation: ", transformation);
		let particularInventory = [];
		particularInventory = await askInventoryParticular(
			transformation.user,
			transformation.processNumber
		);
		//modifica el inventario disminuyendo la cantidad transformada
		transformation.initialProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario
			let inventoryToModify = particularInventory.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			inventoryToModify[0].amount =
				inventoryToModify[0].amount - trProduct.amount;
			if (inventoryToModify[0].amount === 0) {
				await deleteDocumentById(collectionName, inventoryToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName,
					inventoryToModify[0].id,
					inventoryToModify[0]
				);
			}
		});

		let inventoryInTransit = [];
		inventoryInTransit = await askInventoryInTransit(
			transformation.user,
			transformation.processNumber
		);
		//modifica el tránsito disminuyendo la cantidad autorizada
		transformation.initialProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario en tránsito
			let iitToModify = inventoryInTransit.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			iitToModify[0].amount = iitToModify[0].amount - trProduct.amount;
			if (iitToModify[0].amount === 0) {
				await deleteDocumentById(collectionName3, iitToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName3,
					iitToModify[0].id,
					iitToModify[0]
				);
			}
		});

		let particularInventory2 = [];
		particularInventory2 = await askInventoryParticular(
			transformation.user,
			transformation.processNumber
		);
		//modifica el inventario aumentando la cantidad producto de la transformación
		transformation.finalProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario
			let inventoryToModify = particularInventory2.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			if (inventoryToModify.length === 0) {
				//crea el item en el inventario
				let newProduct = {
					amount: trProduct.amount,
					description: trProduct.description,
					id: null,
					processNumber: transformation.processNumber,
					refInventory: trProduct.refInventory,
					user: transformation.user,
				};
				addData(collectionName, newProduct);
			} else {
				//aumenta la cantidad del inventario
				inventoryToModify[0].amount =
					inventoryToModify[0].amount + trProduct.amount;
				await updateDocumentById(
					collectionName,
					inventoryToModify[0].id,
					inventoryToModify[0]
				);
			}
		});

		// modifica el estatus en la colección de transformaciones, pasa de pendiente a aceptado
		let newTransformation = transformation;
		newTransformation.status = "aceptado";
		await updateDocumentById(
			collectionName2,
			transformation.id,
			newTransformation
		);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	const rejeactAction = async (transformation) => {
		setLoading(true);
		console.log("transformation: ", transformation);
		let inventoryInTransit = [];
		inventoryInTransit = await askInventoryInTransit(
			transformation.user,
			transformation.processNumber
		);
		console.log("inventoryInTransit: ", inventoryInTransit);
		//modifica el tránsito disminuyendo la cantidad no autorizada
		transformation.initialProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario en tránsito
			let iitToModify = inventoryInTransit.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			iitToModify[0].amount = iitToModify[0].amount - trProduct.amount;
			if (iitToModify[0].amount === 0) {
				await deleteDocumentById(collectionName3, iitToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName3,
					iitToModify[0].id,
					iitToModify[0]
				);
			}
		});
		// modifica el estatus en la colección de transformaciones, pasa de pendiente a rechazado
		let newTransformation = transformation;
		newTransformation.status = "rechazado";
		await updateDocumentById(
			collectionName2,
			transformation.id,
			newTransformation
		);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	return (
		<div className="containerInventory04">
			{loading ? (
				<Loader />
			) : (
				<table className="tabla-process">
					<thead>
						<tr className="fila-tabla-inventory">
							<th className="titulo-tabla-inventory">Fecha</th>
							<th className="titulo-tabla-inventory"># Proceso</th>
							<th className="titulo-tabla-inventory">Responsable</th>
							<th className="titulo-tabla-inventory">Productos Salen</th>
							<th className="titulo-tabla-inventory">Productos Entran</th>
							<th className="titulo-tabla-inventory">Acción</th>
						</tr>
					</thead>

					<tbody>
						{transformations.map((transformation, index) => (
							<tr key={index} className="fila-tabla-cuenstas">
								<td className="col-tabla-inventory1">{transformation.date}</td>
								<td className="col-tabla-inventory1">
									{transformation.processNumber}
								</td>
								<td className="col-tabla-inventory1">{transformation.user}</td>

								<td className="col-tabla-inventory2">
									{transformation.initialProducts.map((product) => (
										<h3 className="titulo4">
											{product.amount} - {product.description}
										</h3>
									))}
								</td>
								<td className="col-tabla-inventory2">
									{transformation.finalProducts.map((product) => (
										<h3 className="titulo4">
											{product.amount} - {product.description}
										</h3>
									))}
								</td>
								<td className="col-tabla-inventory2">
									<button
										onClick={() => acceptAction(transformation)}
										className="btn-show-stage"
									>
										Autorizar
									</button>
									<button
										onClick={() => rejeactAction(transformation)}
										className="btn-delete-process"
									>
										Rechazar
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
