import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import "../../../../styles/Inventory.css";
import { InventoryShowTable } from "./inventoryShowTable";

export const InventoryShowApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [dataBase, setDataBase] = useState(null); //listado del inventario el almacén General de la empresa
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);

	const nameCollection = "inventoryGeneral-" + company;

	useEffect(() => {
		askGeneralInventory();
	}, []);

	const askGeneralInventory = async () => {
		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	return (
		<div className="conten0">
			{dataBase ? (
				dataBase.length > 0 ? (
					<div className="conten1">
						<article className="titulo11">Inventario Almacén General</article>
						<InventoryShowTable generalInventory={dataBase} />
					</div>
				) : (
					<div>
						<article className="titulo11">Inventario Almacén General</article>
						<article className="alertEmpty">
							No hay inventario para mostrar
						</article>
					</div>
				)
			) : (
				<h3 className="alertEmpty">Buscando...</h3>
			)}
		</div>
	);
};
