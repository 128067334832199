import React from "react";

export const UsersproductionTableRow = ({ el, setUserToShow, askUserWork }) => {
	const actionOn = () => {
		setUserToShow(el);
		askUserWork(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-etapas2">{el.name}</td>
			<td className="col-tabla-etapas1">{el.email}</td>
			<td className="col-tabla-etapas3">
				<button className="btn-show-stage" onClick={() => actionOn()}>
					Ver
				</button>
			</td>
		</tr>
	);
};
