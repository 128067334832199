import React, { useState, useEffect } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import {
	addArea,
	askDataKeyValue,
	askDataKeyValue2,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import CrudAreasForm from "./CrudAreasForm";
import CrudAreasTable from "./CrudAreasTable";
import { firebaseConfig } from "../../../config/firebaseConfig";

const CrudAreasApp = ({ company }) => {
	const app = initializeApp(firebaseConfig);
	const db2 = getFirestore(app);
	const [db, setDb] = useState(null);
	const [data2toEdit, setData2toEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const collectionName = "areas-" + company;

	const askingAreas = () => {
		setLoading(true);
		const q = query(collection(db2, collectionName));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			setDb(dataList);
			// console.log("result =", dataList);
			setLoading(false);
		});
	};

	useEffect(() => {
		askingAreas();
	}, [company]);

	const createArea = async (data) => {
		setLoading(true);
		setError("");
		const response = await addArea(collectionName, data);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askingAreas();
		setError(null);
		setLoading(false);
	};

	const updateArea = async (data) => {
		setLoading(true);
		setError(null);
		const docId = data.id;
		const changes = data;
		const key = "area";
		const value = data.area;
		const response = await askDataKeyValue(collectionName, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta area ya existe");
			} else {
				await updateDocumentById(collectionName, docId, changes);
			}
		}
		askingAreas();
		setLoading(false);
	};

	const deleteArea = async (id) => {
		const docId = id;
		let isDelete = window.confirm(`¿Estás seguro de eliminar esta Area?`);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(collectionName, docId);
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	};

	return (
		<div>
			<h3 className="titulo1">AREAS de: {company}</h3>
			<article>
				<CrudAreasForm
					createArea={createArea}
					updateArea={updateArea}
					data2toEdit={data2toEdit}
					setData2toEdit={setData2toEdit}
				/>
				{loading && <Loader />}
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{db && (
					<CrudAreasTable
						data={db}
						setData2toEdit={setData2toEdit}
						deleteData={deleteArea}
					/>
				)}
			</article>
		</div>
	);
};

export default CrudAreasApp;
