import React, { useState, useEffect } from "react";
import { askDataSimple } from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

const CrudUsuariosForm = ({
	updateUser,
	userToEdit,
	setUserToEdit,
	company,
}) => {
	const initialForm = {
		id: null,
		uid: "",
		name: "",
		email: "",
		area: "",
		type: "",
		company: company,
		agency: "",
		group: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [agencies, setAgencies] = useState([]);
	const [areas, setAreas] = useState([]);
	// const [error, setError] = useState(null);

	const askAgencies = async () => {
		setLoading(true);
		const nameCollection = "agencies-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setAgencies(respuesta.data);
			setLoading(false);
		} else {
			// setAgencies([]);
			// setError(respuesta.error);
			setLoading(false);
		}
	};
	const askAreas = async () => {
		setLoading(true);
		const nameCollection = "areas-" + company;
		const respuesta = await askDataSimple(nameCollection);
		if (respuesta.statusResponse === true) {
			setAreas(respuesta.data);
			setLoading(false);
		} else {
			// setAgencies([]);
			// setError(respuesta.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askAgencies();
		askAreas();
		if (userToEdit) {
			setForm(userToEdit);
		} else {
			setForm(initialForm);
		}
	}, [userToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.name) {
			alert("Debe escribir un nombre...");
			return;
		}
		if (form.area === "Area" || form.area === "") {
			alert("Debe seleccionar un área");
			return;
		}
		if (form.agency === "Sucursal" || form.agency === "") {
			alert("Debe seleccionar una Sucursal");
			return;
		}
		if (form.type === "Tipo de usuario" || form.type === "") {
			alert("Debe seleccionar un Tipo de Usuario");
			return;
		}

		if (form.id != null) {
			updateUser(form);
		}
		handleReset();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setUserToEdit(null);
	};

	return (
		<div>
			{userToEdit && (
				<div className="cont1">
					<h3 className="titulo1">"Editar Usuario"</h3>
					<form onSubmit={handleSubmit} className="form-cuentas">
						<h2 className="title2-account">Empresa: {company}</h2>
						{loading ? (
							<Loader />
						) : (
							<div>
								<h3 className="title3-account"> e-mail: {form.email}</h3>
								<input
									type="text"
									name="name"
									placeholder="Nombre y Apellido"
									onChange={handleChange}
									value={form.name}
									className="input1"
								/>
								<select
									name="type"
									onChange={handleChange}
									value={form.type}
									className="input1"
								>
									<option selected>Tipo de usuario</option>
									{/* <option>1</option> */}
									<option>2</option>
									<option>3</option>
									<option>4</option>
								</select>
								<select
									name="agency"
									onChange={handleChange}
									value={form.agency}
									className="input1"
								>
									<option selected>Sucursal</option>
									{agencies.map((agency) => (
										<option key={agency.id} value={agency.agency}>
											{agency.agency}
										</option>
									))}
								</select>
								<select
									name="area"
									onChange={handleChange}
									value={form.area}
									className="input1"
								>
									<option selected>Area</option>
									{areas.map((area) => (
										<option key={area.id} value={area.area}>
											{area.area}
										</option>
									))}
								</select>

								<input
									type="submit"
									value="Enviar"
									className="btn-send-accounts"
								/>
								<input
									type="reset"
									value="Cerrar"
									onClick={handleReset}
									className="btn-close-accounts"
								/>
							</div>
						)}
					</form>
				</div>
			)}
		</div>
	);
};

export default CrudUsuariosForm;
