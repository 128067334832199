import { initializeApp } from "@firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "@firebase/storage";

// import { getFirestore } from "firebase/firestore";
// import { getFunctions } from "firebase/functions";
// import { getStorage } from "firebase/storage";

const firebaseConfig = {
	apiKey: "AIzaSyBPKVPSB7lI-wHidhB_CzWBAZUC961Hs2I",
	authDomain: "best-practices-working.firebaseapp.com",
	projectId: "best-practices-working",
	storageBucket: "best-practices-working.appspot.com",
	messagingSenderId: "22605720412",
	appId: "1:22605720412:web:ce02bc9f16b75c99322f99",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export { app, db, firebaseConfig, storage };
