import React, { useEffect, useState } from "react";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";

import {
	addDataKey4,
	askDataKeyValue3Simple,
	askDataKeyValue4Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { AnswersForm } from "./AnswersForm";

export const ViewAnswers = ({ question }) => {
	const [db, setDb] = useState(null); //Respuestas de evaluación
	const [answerToEdit, setAnswerToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const nameEvaluation = localStorage.getItem("nameEvaluation");

	const askAnswers = async () => {
		setLoading(true);
		const nameCollecion = "coursesEvaluationAnswers";
		const key1 = "company";
		const value1 = company;
		const key2 = "nameEvaluation";
		const value2 = nameEvaluation;
		const key3 = "question";
		const value3 = question;
		const response = await askDataKeyValue3Simple(
			nameCollecion,
			key1,
			value1,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			setDb(response.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		setDb(null);
		askAnswers();
		setRefresh(false);
	}, [refresh]);

	const createAnswer = async (data) => {
		console.log("---1 ---- ingresó a create Answer");
		// console.log("data:", data);
		setLoading(true);
		const nameCollection = "coursesEvaluationAnswers";
		const key1 = "company";
		const key2 = "nameEvaluation";
		const key3 = "question";
		const key4 = "answer";
		const value1 = company;
		const value2 = nameEvaluation;
		const value3 = data.question;
		const value4 = data.answer;

		const response = await addDataKey4(
			nameCollection,
			data,
			key1,
			value1,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		if (response.statusResponse === false) {
			console.log("respuesta falsa");
			setError(response.error);
			setLoading(false);
			return;
		}
		askAnswers();
		setLoading(false);
	};

	const updateAnswer = async (data) => {
		setLoading(true);
		const nameCollection = "coursesEvaluationAnswers";
		const docId = data.id;
		const changes = data;
		const key = "company";
		const value = company;
		const key2 = "nameEvaluation";
		const value2 = data.nameEvaluation;
		const key3 = "question";
		const value3 = data.question;
		const key4 = "answer";
		const value4 = data.answer;
		const response = await askDataKeyValue4Simple(
			nameCollection,
			key,
			value,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta respuesta ya existe");
				setLoading(false);
			} else {
				await updateDocumentById(nameCollection, docId, changes);
				askAnswers();
				setLoading(false);
			}
		} else {
			askAnswers();
			setLoading(false);
		}
	};

	const clickCreate = () => {
		setAnswerToEdit(null);
		openModal1();
	};

	const editAnswer = (answer) => {
		setAnswerToEdit(answer);
		openModal1();
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	return (
		<div>
			<div>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<AnswersForm
						createAnswer={createAnswer}
						updateAnswer={updateAnswer}
						setAnswerToEdit={setAnswerToEdit}
						answerToEdit={answerToEdit}
						company={company}
						nameEvaluation={nameEvaluation}
						closeModal={closeModal1}
						question={question}
					/>
				</Modal>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{db && (
							<div>
								<button onClick={clickCreate} className="buttonAddAnswer">
									Adicionar Respuesta
								</button>
								{db.length > 0 ? (
									<div>
										<h2 className="titulo2Evaluation">Lista de Respuestas</h2>
										<table className="bloque2">
											<thead>
												<tr>
													<th className="th1">Respuesta</th>
													<th className="th1">Correcta ?</th>
													<th className="th1">Acción</th>
												</tr>
											</thead>
											<tbody className="bloque2">
												{db.map((answer) => (
													<tr key={answer.id}>
														<td className="tarea-col1">{answer.answer}</td>
														<td className="tarea-col1">
															{answer.correctAnswer}
														</td>
														<td className="tarea-col1">
															<button
																onClick={() => editAnswer(answer)}
																className="buttonEditAnswer"
															>
																Editar Respuesta
															</button>
														</td>
													</tr>
												))}
											</tbody>
										</table>
									</div>
								) : (
									<div>
										<h3 className="titulo2">No hay respuestas para mostrar</h3>
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};
