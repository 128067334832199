import React from "react";
import CrudUsuariosTableRow from "./CrudUsuariosTableRow";

const CrudUsuariosTable = ({ user, setUserToEdit, deleteUser, company }) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Usuarios de: {company}</h3>
			<table className="tabla-cuentas">
				<thead>
					<tr>
						<th className="titulo-tabla">Nombre</th>
						<th className="titulo-tabla">Usuario</th>
						<th className="titulo-tabla">Tipo</th>
						<th className="titulo-tabla">Acción</th>
					</tr>
				</thead>
				<tbody>
					{user.length > 0 ? (
						user.map((el) => (
							<CrudUsuariosTableRow
								key={el.id}
								el={el}
								setUserToEdit={setUserToEdit}
								deleteUser={deleteUser}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudUsuariosTable;
