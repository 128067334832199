import React from "react";
import CrudAreasTableRow from "./CrudAreasTableRow";

const CrudAreasTable = ({ data, setData2toEdit, deleteData }) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Areas</h3>
			<table className="tabla-cuentas">
				<thead>
					<tr>
						<th className="titulo-tabla">Area</th>
						<th className="titulo-tabla">Responsable</th>
						<th className="titulo-tabla">Acciones</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => (
							<CrudAreasTableRow
								key={el.id}
								el={el}
								setData2toEdit={setData2toEdit}
								deleteData={deleteData}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudAreasTable;
