import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import "../../styles/MenuProcesos.css";

const CrudProcessAdminForm = ({
	db2,
	createProcess,
	updateProcess,
	processToEdit,
	setProcessToEdit,
	company,
	closeModal,
}) => {
	const initialForm = {
		id: null,
		process: "",
		descriptionProcess: "",
		area: "",
		release: "no",
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [areas, setAreas] = useState(db2);

	useEffect(() => {
		setAreas(db2);
		if (processToEdit) {
			setForm(processToEdit);
		} else {
			setForm(initialForm);
		}
	}, [processToEdit, db2]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.area === "Area" || form.area === "") {
			alert("Debe seleccionar un área");
			return;
		}
		if (!form.process) {
			alert("Debe colocar un proceso...");
			return;
		}
		if (!form.descriptionProcess) {
			alert("Debe llenar el cambpo de descripción del proceso...");
			return;
		}
		setLoading(true);
		if (form.id === null) {
			createProcess(form);
		} else {
			updateProcess(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setProcessToEdit(null);
	};

	return (
		<div className="conten0">
			{/* {console.log("areas en formulario", db2)} */}
			{/* {console.log("areas en formulario variable areas", areas)} */}
			<div className="conten1">
				<h3 className="titulo1">
					{processToEdit ? "Editar Proceso" : "Agregar Nuevo Proceso"}
				</h3>
				<h3 className="titulo2">
					{processToEdit &&
						"ALERTA: Solo se recomienda editar un Proceso mientras se está creando, de lo contrario estaría creando un nuevo prceso."}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>
					{loading ? (
						<Loader />
					) : (
						<div>
							<select
								name="area"
								onChange={handleChange}
								value={form.area}
								className="input1"
							>
								<option defaultValue>Area</option>
								{areas &&
									areas.map((area) => (
										<option key={area.area} value={area.area}>
											{area.area}
										</option>
									))}
							</select>
							<input
								type="text"
								name="process"
								placeholder="Proceso"
								onChange={handleChange}
								value={form.process}
								className="input0"
							/>
							<input
								type="text"
								name="descriptionProcess"
								placeholder="Descripción del proceso"
								onChange={handleChange}
								value={form.descriptionProcess}
								className="input0"
							/>
							<hr />
							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};

export default CrudProcessAdminForm;
