import React, { useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../config/firebaseConfig";
import { signIn } from "../../utils/actions/firebase/firebaseActions";
import "../styles/LoginForm.css";
import Loader from "./Loader";
import imagen from "./images/logoBPWhorizontal.png";
import Modal from "../generalComponents/Modal";
import { useModal } from "./hooks/useModal";
import { LoginForgotForm } from "./LoginForgotForm";

function Login() {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [mail, setMail] = useState("");
	const [contrasena, setContrasena] = useState("");
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [usuario, setUsuario] = useState({
		email: "",
		company: "",
		group: "",
		id: "",
		name: "",
		agency: "",
		type: "",
	});

	const doLogin = async (e) => {
		e.preventDefault();

		setLoading(true);
		const result = await signIn(mail, contrasena);

		console.log("**** result:", result);

		if (!result.statusResponse) {
			setLoading(false);
			setMail("");
			setContrasena("");
			alert(result.error);
		} else {
			localStorage.setItem("userLocal", JSON.stringify(result.data[0]));
			localStorage.setItem("usuariosEmpresa", result.data[0].company);
			await askModules(result.data[0].company);
			setUsuario(result.data[0]);
			setLoading(false);
		}
	};

	const askModules = async (comp) => {
		const q = query(
			collection(db, "adminModules"),
			where("company", "==", comp)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				// setDataBase(dataList);
				if (dataList.length > 0) {
					if (dataList[0].modules.includes("Procesos")) {
						localStorage.setItem("ModuleKeyProcess", "si");
					} else {
						localStorage.setItem("ModuleKeyProcess", "no");
					}
					if (dataList[0].modules.includes("Documentos")) {
						localStorage.setItem("ModuleKeyDocuments", "si");
					} else {
						localStorage.setItem("ModuleKeyDocuments", "no");
					}
					if (dataList[0].modules.includes("Capacitación")) {
						localStorage.setItem("ModuleKeyCapacitation", "si");
					} else {
						localStorage.setItem("ModuleKeyCapacitation", "no");
					}
					if (dataList[0].modules.includes("Evaluación")) {
						localStorage.setItem("ModuleKeyEvaluation", "si");
					} else {
						localStorage.setItem("ModuleKeyEvaluation", "no");
					}
					return;
				} else {
					localStorage.setItem("ModuleKeyProcess", "no");
					localStorage.setItem("ModuleKeyDocuments", "no");
					localStorage.setItem("ModuleKeyCapacitation", "no");
					localStorage.setItem("ModuleKeyEvaluation", "no");
					return;
				}
				// setLoading(false);
			},
			(error) => {
				localStorage.setItem("ModuleKeyProcess", "no");
				localStorage.setItem("ModuleKeyDocuments", "no");
				localStorage.setItem("ModuleKeyCapacitation", "no");
				localStorage.setItem("ModuleKeyEvaluation", "no");
				return;
			}
		);
	};

	const forgotPasword = () => {
		openModal1();
	};

	return (
		<div>
			<div className="titulo">
				<img src={imagen} className="icono" />
			</div>
			<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
				<LoginForgotForm closeModal={closeModal1} />
			</Modal>
			<div className="container2">
				<h2 className="titleLogin">Formulario de Ingreso</h2>
				<div className="login">
					<article className="fondo">
						<form onSubmit={(e) => doLogin(e)}>
							{loading && <Loader />}
							<input
								type="email"
								className="form-control mb-2"
								placeholder="Ingresa tu email..."
								onChange={(text) => setMail(text.target.value)}
								value={mail}
							/>
							<input
								type="password"
								className="form-control mb-2"
								placeholder="Ingresa tu contraseña..."
								onChange={(text) => setContrasena(text.target.value)}
								value={contrasena}
							/>

							<Link onClick={forgotPasword} className="linkForgotPassword">
								olvidó su constraseña...
							</Link>

							<button className="buttonLogin">Iniciar Sesión</button>
						</form>
						{usuario.type === "1" ? (
							// <Redirect to="/accounts" />
							<Redirect to="/super_administrator" />
						) : usuario.type === "2" ? (
							// <Redirect to="/process-list" />
							<Redirect to="/administrator_company" />
						) : usuario.type === "3" ? (
							// <Redirect to="/supervisor-company/reg_procesos" />
							<Redirect to="/supervisor_company" />
						) : (
							usuario.type === "4" && <Redirect to="/worker-company" />
						)}
					</article>
				</div>
			</div>
		</div>
	);
}
export default Login;
