import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	getDocs,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { Redirect } from "react-router";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import Loader from "../../generalComponents/Loader";
import Message from "../../generalComponents/Message";
import CrudIndicatorsTable from "./CrudIndicatorsTable";
import CrudIndicatorsForm from "./CrudIndicatorsForm";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

export const CrudIndicatorsApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [db1, setDb1] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dates, setDates] = useState(null);
	const [processToEdit, setProcessToEdit] = useState(null);
	const [goToIndicator, setGoToIndicator] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askProcess = async () => {
		setLoading(true);
		console.log("<<<< empezó");
		setLoading(true);
		const nameCollection = "process-" + company;
		const key = "processWithIndicator";
		const value = "si";
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			setDb1(response.data);
		}
		setLoading(false);
	};

	useEffect(() => {
		askProcess();
	}, []);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false); //uno para cada modal que se utilice

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<article>
						{/* <p>Aquí se gestionan listas de procesos</p> */}
						<h3 className="titleProcess">INDICADORES ({company})</h3>
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudIndicatorsForm
								setDates={setDates}
								processToEdit={processToEdit}
								company={company}
								closeModal={closeModal1}
								setGoToIndicator={setGoToIndicator}
							/>
						</Modal>

						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{db1 ? (
							<CrudIndicatorsTable
								process={db1}
								setProcessToEdit={setProcessToEdit}
								openModal1={openModal1}
							/>
						) : (
							<h3>No hay procesos para mostrar</h3>
						)}
						{goToIndicator === true && <Redirect to="/view_indicator" />}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
