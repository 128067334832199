import React, { useState, useEffect } from "react";
import Loader from "../../generalComponents/Loader";

export const AnswersForm = ({
	createAnswer,
	updateAnswer,
	setAnswerToEdit,
	answerToEdit,
	company,
	nameEvaluation,
	closeModal,
	question,
}) => {
	const initialForm = {
		id: null,
		company: company,
		nameEvaluation: nameEvaluation,
		question: question,
		answer: "",
		correctAnswer: "",
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (answerToEdit) {
			setForm(answerToEdit);
		} else {
			setForm(initialForm);
		}
	}, [answerToEdit]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!form.answer) {
			alert("Debe colocar una Respuesta...");
			return;
		}

		if (form.correctAnswer === "Seleccione" || form.correctAnswer === "") {
			alert("Debe indicar si la respuesta es la correcta si ó no ...");
			return;
		}

		setLoading(true);
		if (form.id === null) {
			createAnswer(form);
		} else {
			updateAnswer(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		setAnswerToEdit(null);
	};

	return (
		<div className="conten0">
			<div className="conten1">
				<h3 className="titulo1">
					{answerToEdit
						? "Editar Respuesta"
						: "Agregar Nueva Respuesta a la Pregunta de esta Evaluación"}
				</h3>
				<h3 className="titulo2">
					{answerToEdit &&
						"ALERTA: Solo se recomienda editar una Respuestas mientras se está creando, de lo contrario estaría creando una nueva respuesta."}
				</h3>
				<form onSubmit={handleSubmit} className="form-procesos">
					{loading ? (
						<Loader />
					) : (
						<div>
							<input
								type="text"
								name="answer"
								placeholder="Respuesta"
								onChange={handleChange}
								value={form.answer}
								className="input0"
							/>
							<label>Indique si esta es la opción correcta:</label>
							<select
								name="correctAnswer"
								onChange={handleChange}
								value={form.correctAnswer}
								className="input1"
							>
								<option defaultValue>Seleccione</option>
								<option>si</option>
								<option>no</option>
							</select>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={closeModal}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		</div>
	);
};
