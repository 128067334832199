import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";

import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import { askDataKeyValue } from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";
import { HistoricalUsersProductionTable } from "./historicalUsersProductionTable";

const HistoricalUsersProductionApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [activitiesList, setActivitiesList] = useState(null);
	const [selectedDate, setSelectedDate] = useState(null);
	const [loading, setLoading] = useState(false);

	const handleSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		// console.log("form:", form);
		// console.log("otra fecha:", Date.parse(form.selectDate));
		let otra2fecha = new Date(form.selectDate);
		// console.log("2. otra fecha:", otra2fecha);
		// let otra3fecha = otra2fecha.toLocaleDateString();
		// console.log(">>> esta es.... 3. otra fecha: ", otra3fecha);
		// let parse3 = Date.parse(otra3fecha);
		// console.log("parse3: ", parse3);
		// let dif = parse3 - Date.parse(form.selectDate);
		// console.log("dif: ", dif);
		// const fechita = new Date();
		// const actualDate = fechita.toLocaleDateString();
		// console.log("fechita: ", fechita);
		// console.log("actual date: ", actualDate);
		// let otra4fecha = Date(form.selectDate);
		// console.log("4. otrafecha: ", otra4fecha);

		// // let usDate = otra2fecha.toLocaleString("en-UK", {
		// // 	timeZone: "America/New_York",
		// // });
		// // let ukDate = otra2fecha.toUTCString();
		// let ukDate = otra2fecha.toUTCString();
		// console.log("mileg: ", Date.parse(ukDate));
		// console.log("5. ukdate: ", ukDate);
		// let datodeseado = ukDate.toString();
		// // let ahorasi = datodeseado.toLocaleDateString();
		// console.log("6. en usa: ", datodeseado);
		let ano = otra2fecha.getUTCFullYear();
		let mes = otra2fecha.getUTCMonth() + 1;
		let dia = otra2fecha.getUTCDate();
		let fecha = dia.toString() + "/" + mes.toString() + "/" + ano.toString();
		setSelectedDate(fecha);
		console.log("fecha :", fecha);
		let resp1 = await askDataKeyValue(
			"activitiesRegister-" + company,
			"date",
			fecha
		);
		setActivitiesList(resp1.data);
		console.log("actividades: ", resp1.data);
		setLoading(false);
	};

	const initialForm = {
		selectDate: "",
	};

	const [form, setForm] = useState(initialForm);
	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	return (
		<div>
			<article className="titulo9">
				HISTORIAL DE TRABAJOS POR USUARIO ({company})
			</article>
			{loading ? (
				<Loader />
			) : (
				<div>
					<form onSubmit={handleSubmit} className="filter-procesos">
						<label className="labelForm1">Seleccione Fecha</label>
						<input
							type="date"
							name="selectDate"
							placeholder="Fecha"
							onChange={handleChange}
							value={form.selectDate}
							className="input3"
						/>

						{/* <hr /> */}
						<label className="labelForm1">Estatus del Proceso</label>

						<input type="submit" value="Ver" className="btn-filter-procesos" />
					</form>
					<hr />
					{activitiesList && (
						<HistoricalUsersProductionTable activitiesList={activitiesList} />
					)}
				</div>
			)}
		</div>
	);
};

export default HistoricalUsersProductionApp;
