import React from "react";

export const ClientsTableRow = ({
	el,
	setClientToEdit,
	openModal,
	deleteClient,
}) => {
	const editButton = (el) => {
		setClientToEdit(el);
		openModal();
	};

	const deleteButton = (el) => {
		deleteClient(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.IdType}</td>
			<td className="colTableDocuments1">{el.IdNumber}</td>

			<td className="colTableDocuments1">{el.client}</td>

			<td className="colTableDocuments2">
				<button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
				</button>

				<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button>
			</td>
		</tr>
	);
};
