import React, { useState, useEffect } from "react";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import Message from "../../generalComponents/Message";
import Loader from "../../generalComponents/Loader";
import { Redirect } from "react-router-dom";
import CrudActivitiesForm from "./CrudActivitiesForm";
import CrudActivitiesTable from "./CrudActivitiesTable";
import {
	addDataKey3,
	addDataKey4,
	askDataKeyValue2,
	askDataKeyValue3,
	askDataKeyValue4,
	downPositionActivities,
	updateDocumentById,
	upPositionActivities,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw2stages from "../../generalComponents/NavbarBpw2stages";

const CrudActivitiesApp = () => {
	const [db, setDb] = useState(null);
	const [activityToEdit, setActivityToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const process = localStorage.getItem("procesoEditar");
	const stage = localStorage.getItem("etapaEditar");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askActivities = async () => {
		setLoading(true);
		const nameCollecion = "activities-" + company;
		const key2 = "process";
		const value2 = process;
		const key3 = "stage";
		const value3 = stage;
		const response = await askDataKeyValue2(
			nameCollecion,
			key2,
			value2,
			key3,
			value3
		);
		if (response.statusResponse === true) {
			setDb(response.data);
			setLoading(false);
		} else {
			setDb(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		setDb(null);
		askActivities();
		setRefresh(false);
	}, [refresh]);

	const createActivity = async (data) => {
		if (!validateData(data)) {
			return;
		}
		setLoading(true);
		const nameCollection = "activities-" + company;
		const key2 = "process";
		const key3 = "stage";
		const key4 = "activity";
		const value2 = process;
		const value3 = stage;
		const value4 = data.activity;
		const response = await addDataKey3(
			nameCollection,
			data,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		console.log(">>> response:", response);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		askActivities();
		setLoading(false);
	};

	const validateData = (data) => {
		let isValid = true;
		if (data.responseType === "Tipo de Respuesta") {
			setError("Faltó seleccionar tipo de respuesta");
			isValid = false;
		}
		if (data.responseType === "") {
			setError("Faltó seleccionar tipo de respuesta");
			isValid = false;
		}
		return isValid;
	};

	const updateActivity = async (data) => {
		setLoading(true);
		console.log("updateStage recibe data", data);
		const nameCollection = "activities-" + company;
		const docId = data.id;
		const changes = data;
		const key2 = "process";
		const value2 = data.process;
		const key3 = "stage";
		const value3 = data.stage;
		const key4 = "activity";
		const value4 = data.activity;
		const response = await askDataKeyValue3(
			nameCollection,
			key2,
			value2,
			key3,
			value3,
			key4,
			value4
		);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				setError("Esta actividad ya existe");
			} else {
				await updateDocumentById(nameCollection, docId, changes);
			}
		}
		askActivities();
		setLoading(false);
	};

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const clickCrear = () => {
		setActivityToEdit(null);
		openModal1();
	};

	const downPos = async (data) => {
		setLoading(true);
		const response = await downPositionActivities(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		setRefresh(true);
		setLoading(false);
	};

	const upPos = async (data) => {
		setLoading(true);
		const response = await upPositionActivities(data, company);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await setRefresh(true);
		setLoading(false);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2stages />
					<article>
						<h3 className="titleProcess">ACTIVIDADES ({company})</h3>

						<h3 className="titulo3">Proceso: {process}</h3>
						<h3 className="titulo3">Etapa: {stage}</h3>
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudActivitiesForm
								createActivity={createActivity}
								updateActivity={updateActivity}
								setActivityToEdit={setActivityToEdit}
								activityToEdit={activityToEdit}
								company={company}
								process={process}
								stage={stage}
								closeModal={closeModal1}
								db={db}
							/>
						</Modal>
						{loading ? (
							<Loader />
						) : (
							<div>
								<button onClick={clickCrear} className="btn-crear-proceso">
									Crear Actividad
								</button>

								{error && <Message mensaje={error} bgColor="#dc3545" />}
								{db ? (
									<CrudActivitiesTable
										activities={db}
										setActivityToEdit={setActivityToEdit}
										openModal1={openModal1}
										downPos={downPos}
										upPos={upPos}
									/>
								) : (
									<h3>No hay Actividades para mostrar</h3>
								)}
							</div>
						)}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
			{/* {refresh && <Redirect to="/stages-list" />} */}
		</div>
	);
};

export default CrudActivitiesApp;
