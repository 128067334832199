import React, { useState } from "react";
import { Redirect } from "react-router";
import NavbarBpw2 from "../../../generalComponents/NavbarBpw2";
import { PermisionsInventoryMasterApp } from "./permisionsInventoryMaster/permisionsInventoryMasterApp";
import "../../../styles/Inventory.css";
import { PermisionsInventoryGeneralApp } from "./permisionsInventoryGeneral/permisionsInventoryGeneralApp";
import { PermisionsInventoryTransfApp } from "./permisionsInventoryTransf/permisionsInventoryTransfApp";
// import "../../../styles/MenuProcesos.css";

export default function InventoryPermisionsApp() {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [showMaster, setShowMaster] = useState(false);
	const [showGeneral, setShowGeneral] = useState(false);
	const [showTransformations, setShowTransformations] = useState(false);

	const showItem = (value) => {
		if (value === "master") {
			setShowMaster(true);
		}
		if (value === "general") {
			setShowGeneral(true);
		}
		if (value == "transformations") {
			setShowTransformations(true);
		}
	};
	const hideItem = (value) => {
		if (value === "master") {
			setShowMaster(false);
		}
		if (value === "general") {
			setShowGeneral(false);
		}
		if (value === "transformations") {
			setShowTransformations(false);
		}
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<article className="titulo11">
						Permisos de Inventario ({company})
					</article>

					<div className="menuPermisionsInventory">
						<article className="titulo8">Maestro de Inventarios</article>
						{showMaster ? (
							<button
								className="btnShowPermisions"
								onClick={() => hideItem("master")}
							>
								Ocultar
							</button>
						) : (
							<button
								onClick={() => showItem("master")}
								className="btnShowPermisions"
							>
								Ver
							</button>
						)}
					</div>
					{showMaster && <PermisionsInventoryMasterApp />}

					<div className="menuPermisionsInventory">
						<article className="titulo8">Inventario General</article>
						{showGeneral ? (
							<button
								className="btnShowPermisions"
								onClick={() => hideItem("general")}
							>
								Ocultar
							</button>
						) : (
							<button
								onClick={() => showItem("general")}
								className="btnShowPermisions"
							>
								Ver
							</button>
						)}
					</div>
					{showGeneral && <PermisionsInventoryGeneralApp />}

					<div className="menuPermisionsInventory">
						<article className="titulo8">
							Autorización de Transformaciones ó Ajustes
						</article>
						{showTransformations ? (
							<button
								className="btnShowPermisions"
								onClick={() => hideItem("transformations")}
							>
								Ocultar
							</button>
						) : (
							<button
								onClick={() => showItem("transformations")}
								className="btnShowPermisions"
							>
								Ver
							</button>
						)}
					</div>
					{showTransformations && <PermisionsInventoryTransfApp />}
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
}
