import React from "react";

const CrudUsuariosTableRow = ({ el, setUserToEdit, deleteUser }) => {
	let { id, name, email, type } = el;
	const userXedit = {
		id: el.id,
		uid: el.uid,
		area: el.area,
		name: el.name,
		email: el.email,
		type: el.type,
		company: el.company,
		agency: el.agency,
		group: el.group,
	};
	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-cuenta">{name}</td>
			<td className="col-tabla-cuenta">{email}</td>
			<td className="col-tabla-cuenta">{type}</td>
			<td className="col-tabla-cuenta">
				<button onClick={() => setUserToEdit(userXedit)} className="btn-editar">
					Editar
				</button>
				{/* <button onClick={() => deleteUser(id)}>Eliminar</button> */}
			</td>
		</tr>
	);
};

export default CrudUsuariosTableRow;
