import React from "react";

export const PermisionsInventoryMasterTable = ({ userPermisions }) => {
	return (
		<div>
			<table className="tabla-procesos">
				{/* <thead>
					<tr className="fila-tabla-procesos">
						<th className="titleTableDocuments02">Correo</th>
					</tr>
				</thead> */}
				<tbody>
					{userPermisions.length > 0 ? (
						userPermisions[0].inventoryMasterPermision.map((el) => (
							<tr className="fila-tabla-cuenstas" key={el}>
								<td className="colTableDocuments1">{el}</td>
							</tr>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
