import React from "react";
import {
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

export const EvaluationsTableRow = ({
	el,
	setEvaluationToEdit,
	openModal1,
	setViewEvaluation,
	company,
}) => {
	const buttonAction = (el) => {
		setEvaluationToEdit(el);
		openModal1();
	};
	const goViewEvaluation = () => {
		localStorage.setItem("nameEvaluation", el.nameEvaluation);
		setViewEvaluation(true);
	};

	const processRelease = async (el) => {
		let isRelease = window.confirm(`¿Está segur@ de Liberar este Proceso?`);
		if (isRelease) {
			let process = el;
			process.release = "si";
			process.active = "si";
			const resp1 = await askDataKeyValue2Simple(
				"activities-" + company,
				"company",
				company,
				"process",
				process.process
			);
			let activitiesList = resp1.data;
			let sum = 0;
			for (const activity of activitiesList) {
				if (activity.responseType === "Cumple-NoCumple") {
					sum = sum + 1;
				}
			}
			if (sum === activitiesList.length) {
				process.processWithIndicator = "si";
				updateDocumentById("process-" + company, process.id, process);
			} else {
				process.processWithIndicator = "no";
				updateDocumentById("process-" + company, process.id, process);
			}
		} else {
			return;
		}
	};

	const changeActive = async (el) => {
		let isRelease = window.confirm(
			`¿Está segur@ de cambiar el estado de "Activo" ?`
		);
		if (isRelease) {
			let process = el;
			console.log("process:", process);
			if (process.active === "si") {
				process.active = "no";
			} else {
				process.active = "si";
			}
			updateDocumentById("process-" + company, process.id, process);
		} else {
			return;
		}
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-procesos1">{el.nameEvaluation}</td>
			<td className="col-tabla-procesos1">{el.instructions}</td>
			<td className="col-tabla-procesos2">{el.maxTime} minutos</td>

			<td className="col-tabla-procesos2">
				<div className="contActions01">
					<button className="btn-show-stage" onClick={() => goViewEvaluation()}>
						Ver
					</button>

					<button
						onClick={() => buttonAction(el)}
						className="btn-editar-procesos"
					>
						Editar
					</button>
				</div>
			</td>
			{/* <td className="col-tabla-procesos1">
				<div className="contActions01">
					{el.active === "si" ? (
						<p className="sino">SI</p>
					) : (
						<p className="sino">NO</p>
					)}
					{el.active === "si" ? (
						<button className="btnActive" onClick={() => changeActive(el)}>
							<Adjust />
						</button>
					) : (
						<button className="btnInActive" onClick={() => changeActive(el)}>
							<AddCircle />
						</button>
					)}
				</div>
			</td> */}
		</tr>
	);
};
