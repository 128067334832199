import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
	getDocs,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../../config/firebaseConfig";
import { useModal } from "../../../../generalComponents/hooks/useModal";
import Loader from "../../../../generalComponents/Loader";
import Message from "../../../../generalComponents/Message";
import Modal from "../../../../generalComponents/Modal";
import { FormSelectProcess } from "./formSelectProcess";
import { ParticularTransferOutForm } from "./particularTransferOutForm";
import { ParticularTransferOutTable } from "./particularTransferOutTable";

export const ParticularTransferOutApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dataBase, setDataBase] = useState(null); //alamacena base de datos de las transfeencias pendientes dede este usuario
	const [particularInventory, setParticularInventory] = useState(null);
	const [aviableInventory, setAviableInventory] = useState(null);
	const [listProcess, setListProcess] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);
	const [isOpenModal0, openModal0, closeModal0] = useModal(false);

	const buildProcessList = () => {
		setLoading(true);
		const nameCollection = "inventoryParticular-" + company;
		const q = query(
			collection(db, nameCollection),
			where("user", "==", localUser.email)
		);
		onSnapshot(q, (querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			let processList = [];
			dataList.map((item) => {
				let process = item.processNumber;
				if (processList.includes(process)) {
				} else {
					processList.push(process);
				}
			});
			setListProcess(processList);
		});
		setLoading(false);
	};

	const askInventoryTransfer = (from) => {
		const nameCollection = "inventoryTransfer-" + company;
		const q = query(
			collection(db, nameCollection),
			where("userFrom", "==", from),
			where("status", "==", "pendiente")
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);

				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		buildProcessList();
		askInventoryTransfer(localUser.email);
	}, []);

	const clickCrear = () => {
		// openModal1();
		openModal0();
	};

	return (
		<div>
			{/* <p>Aquí se gestionan las transferencias de iventario de salida</p> */}
			<article className="titulo11">Traslados de Salida ({company})</article>
			{listProcess && (
				<Modal isOpen={isOpenModal0} closeModal={closeModal0}>
					<FormSelectProcess
						listProcess={listProcess}
						closeModal={closeModal0}
						setAviableInventory={setAviableInventory}
						openModal1={openModal1}
					/>
				</Modal>
			)}
			{aviableInventory && (
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<ParticularTransferOutForm
						aviableInventory={aviableInventory}
						closeModal={closeModal1}
					/>
				</Modal>
			)}
			{loading ? (
				<Loader />
			) : (
				<div>
					{error && <Message mensaje={error} bgColor="#dc3545" />}
					<button onClick={clickCrear} className="btn-crear-proceso">
						Crear Traslado
					</button>
					{dataBase && dataBase.length > 0 ? (
						<div>
							<ParticularTransferOutTable dataBase={dataBase} />
						</div>
					) : (
						<article className="titulo2">
							No hay Traslados de Salida pendientes para mostrar...
						</article>
					)}
				</div>
			)}
		</div>
	);
};
