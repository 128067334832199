import React from "react";
import { separadoresDeMil } from "../../../../../utils/actions/firebase/firebaseActions";

export const ParticularProductsToTransferTable = ({
	productsList,
	eraseProduct,
}) => {
	return (
		<div className="containerProductsReceipt">
			<table className="tabla-inventory">
				<thead>
					<tr className="fila-tabla-procesos-">
						<th className="tabla-column-etiqueta">Referencia</th>
						<th className="tabla-column-etiqueta">Descripción</th>
						<th className="tabla-column-etiqueta">Cantidad</th>
						<th className="tabla-column-etiqueta">Acción</th>
					</tr>
				</thead>

				<tbody>
					{productsList.map((productList, index) => (
						<tr key={index}>
							<td className="tabla-column-data">{productList.product.value}</td>
							<td className="tabla-column-data">
								{productList.product.description}
							</td>
							<td className="tabla-column-data">
								{separadoresDeMil(productList.amount)}
							</td>

							<td>
								<input
									type="submit"
									value="Borrar"
									className="btnErraseProduct"
									onClick={() => eraseProduct(index)}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
	);
};
