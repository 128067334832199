import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import NavbarBpw3 from "../../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../../generalComponents/NavbarBpw4";
import NavbarBpw2 from "../../../generalComponents/NavbarBpw2";

import "../../../styles/Inventory.css";
// import { InventoryReceiptApp } from "./inventoryReceipt/inventoryReceiptApp";
// import { InventoryShowApp } from "./inventoryShow/inventoryShowApp";
// import { InventoryTransferOutApp } from "./inventoryTransferOut/inventoryTransferOutApp";
import { ParticularInventoryInitial } from "./particularInventoryInitial";
import { ParticularShowApp } from "./particularShow/particularShowApp";
import { ParticularTransferInApp } from "./particularTransferIn/particularTransferInApp";
import { ParticularTransferOutApp } from "./particularTransferOut/particularTransferOutApp";
import { TransformationApp } from "../transformation/transformationApp";
import { TransformationPermitApp } from "../transformationPermit/transformationPermitApp";
import { InventoriesApp } from "../masterInventory/inventoriesApp";
import { GeneralInventoryApp } from "../generalInventroy/generalInventoryApp";
import { InventoryShowApp } from "../generalInventroy/inventoryShow/inventoryShowApp";
import { TransformationPermitParticularApp } from "../transformationPermit/transformationPermitParticularApp";

export const ParticularInventoryApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado del inventario el almacén General de la empresa
	const [masterInventory, setMasterInventory] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading0, setLoading0] = useState(false);
	const [permitedGeneral, setPermitedGeneral] = useState(false);
	const [permitedMaster, setPermitedMaster] = useState(false);
	const [permitedTransf, setPermitedTransf] = useState(false);
	const [option, setOption] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const nameCollection2 = "inventoryMaster-" + company;

	const askMasterInventory = async () => {
		setLoading(true);
		const q = query(collection(db, nameCollection2));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setMasterInventory(dataList);
				setLoading(false);
			},
			(error) => {
				setMasterInventory(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	const askPermisions = () => {
		setLoading0(true);
		const collectionName = "inventoryPermisions-" + company;

		const q = query(collection(db, collectionName));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			const email = localUser.email;
			let generalPermisions = dataList.filter(
				(item) => item.typePermision == "General"
			)[0].inventoryGeneralPermision;
			if (generalPermisions.includes(email)) {
				setPermitedGeneral(true);
			} else {
				setPermitedGeneral(false);
			}
			let masterPermisions = dataList.filter(
				(item) => item.typePermision == "Master"
			)[0].inventoryMasterPermision;
			if (masterPermisions.includes(email)) {
				setPermitedMaster(true);
			} else {
				setPermitedMaster(false);
			}
			let transfPermisions = dataList.filter(
				(item) => item.typePermision == "Transf"
			)[0].inventoryTransfPermision;
			if (transfPermisions.includes(email)) {
				setPermitedTransf(true);
			} else {
				setPermitedTransf(false);
			}
			setLoading0(false);
		});
	};

	useEffect(() => {
		askPermisions();
	}, []);

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : (
				localUser.type === "4" && <NavbarBpw4 />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div>
							<div className="containerInventory01">
								<div className="containerInventory02">
									{option === "Inventario" ? (
										<button
											className="buttonInventory03"
											onClick={() => changeButton("Inventario")}
										>
											Inventario
										</button>
									) : (
										<button
											className="buttonInventory02"
											onClick={() => changeButton("Inventario")}
										>
											Inventario
										</button>
									)}
									{option === "Traslados - Salida" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Traslados - Salida")}
										>
											Traslados - Salida
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Traslados - Salida")}
										>
											Traslados - Salida
										</button>
									)}
									{option === "Traslados - Entrada" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Traslados - Entrada")}
										>
											Traslados - Entrada
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Traslados - Entrada")}
										>
											Traslados - Entrada
										</button>
									)}
									{option === "Cambio de Inventario" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Cambio de Inventario")}
										>
											Cambio de Inventario
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Cambio de Inventario")}
										>
											Cambio de Inventario
										</button>
									)}

									{permitedTransf && (
										<div>
											{option === "Autorizar Cambios" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											)}
										</div>
									)}
									{permitedMaster && (
										<div>
											{option === "Inventario Maestro" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											)}
										</div>
									)}
									{permitedGeneral && (
										<div>
											{option === "Almacén General" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											)}
										</div>
									)}
								</div>

								<div className="containerInventory03">
									{option === null && <ParticularInventoryInitial />}
									{option === "Inventario" && <ParticularShowApp />}
									{option === "Traslados - Salida" && (
										<ParticularTransferOutApp />
									)}
									{option === "Traslados - Entrada" && (
										<ParticularTransferInApp />
									)}
									{option === "Cambio de Inventario" && <TransformationApp />}
									{option === "Autorizar Cambios" && (
										<TransformationPermitParticularApp />
									)}
									{option === "Inventario Maestro" && <InventoriesApp />}
									{option === "Almacén General" && <InventoryShowApp />}
								</div>
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
