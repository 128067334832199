import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import NavbarBpw3 from "../../../generalComponents/NavbarBpw3";
import NavbarBpw4 from "../../../generalComponents/NavbarBpw4";
import NavbarBpw2 from "../../../generalComponents/NavbarBpw2";
import { GeneralInventoryInitial } from "./generalInventoryInitial";

import "../../../styles/Inventory.css";
import { InventoryReceiptApp } from "./inventoryReceipt/inventoryReceiptApp";
import { InventoryShowApp } from "./inventoryShow/inventoryShowApp";
import { InventoryTransferOutApp } from "./inventoryTransferOut/inventoryTransferOutApp";
import { InventoryTransferInApp } from "./inventoryTransferIn/inventoryTransferInApp";
import InventoryPermisionsApp from "../inventroyPermisions/inventoryPermisionsApp";
import { InventoriesApp } from "../masterInventory/inventoriesApp";
import { TransformationPermitApp } from "../transformationPermit/transformationPermitApp";

export const GeneralInventoryApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading0, setLoading0] = useState(false);
	const [option, setOption] = useState(null);
	const [permitedGeneral, setPermitedGeneral] = useState(false);
	const [permitedMaster, setPermitedMaster] = useState(false);
	const [permitedTransf, setPermitedTransf] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askPermisions = () => {
		setLoading0(true);
		const collectionName = "inventoryPermisions-" + company;

		const q = query(collection(db, collectionName));
		onSnapshot(q, (querySnapshot) => {
			const dataList = [];
			querySnapshot.forEach((doc) => {
				dataList.push(doc.data());
			});
			const email = localUser.email;
			console.log("Permiso inventario: ", dataList);
			try {
				let generalPermisions = dataList.filter(
					(item) => item.typePermision == "General"
				)[0].inventoryGeneralPermision;
				if (generalPermisions.includes(email)) {
					setPermitedGeneral(true);
				} else {
					setPermitedGeneral(false);
				}
			} catch (error) {}

			try {
				let masterPermisions = dataList.filter(
					(item) => item.typePermision == "Master"
				)[0].inventoryMasterPermision;
				if (masterPermisions.includes(email)) {
					setPermitedMaster(true);
				} else {
					setPermitedMaster(false);
				}
			} catch (error) {}

			try {
				let transfPermisions = dataList.filter(
					(item) => item.typePermision == "Transf"
				)[0].inventoryTransfPermision;
				if (transfPermisions.includes(email)) {
					setPermitedTransf(true);
				} else {
					setPermitedTransf(false);
				}
			} catch (error) {}
			setLoading0(false);
		});
	};

	useEffect(() => {
		askPermisions();
	}, []);

	const changeButton = (option) => {
		setOption(option);
	};

	return (
		<div>
			{localUser.type === "2" ? (
				<NavbarBpw2 />
			) : localUser.type === "3" ? (
				<NavbarBpw3 />
			) : (
				localUser.type === "4" && <NavbarBpw4 />
			)}

			<article>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}

						<div>
							<div className="containerInventory01">
								<div className="containerInventory02">
									{permitedMaster && (
										<div>
											{option === "Inventario Maestro" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Inventario Maestro")}
												>
													Inventario Maestro
												</button>
											)}
										</div>
									)}
									{permitedGeneral && (
										<div>
											{option === "Almacén General" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Almacén General")}
												>
													Almacén General
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Ingresos" ? (
												<button
													className="buttonInventory03"
													onClick={() => changeButton("Ingresos")}
												>
													Ingresos
												</button>
											) : (
												<button
													className="buttonInventory02"
													onClick={() => changeButton("Ingresos")}
												>
													Ingresos
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Salidas" ? (
												<button
													className="buttonInventory03"
													onClick={() => changeButton("Salidas")}
												>
													Salidas
												</button>
											) : (
												<button
													className="buttonInventory02"
													onClick={() => changeButton("Salidas")}
												>
													Salidas
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Traslados - Salida" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Traslados - Salida")}
												>
													Traslados - Salida
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Traslados - Salida")}
												>
													Traslados - Salida
												</button>
											)}
										</div>
									)}

									{permitedGeneral && (
										<div>
											{option === "Traslados - Entrada" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Traslados - Entrada")}
												>
													Traslados - Entrada
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Traslados - Entrada")}
												>
													Traslados - Entrada
												</button>
											)}
										</div>
									)}

									{permitedTransf && (
										<div>
											{option === "Autorizar Cambios" ? (
												<button
													className="buttonInventory03	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											) : (
												<button
													className="buttonInventory02	"
													onClick={() => changeButton("Autorizar Cambios")}
												>
													Autorizar Cambios
												</button>
											)}
										</div>
									)}
									{option === "Permisos" ? (
										<button
											className="buttonInventory03	"
											onClick={() => changeButton("Permisos")}
										>
											Permisos
										</button>
									) : (
										<button
											className="buttonInventory02	"
											onClick={() => changeButton("Permisos")}
										>
											Permisos
										</button>
									)}
								</div>

								<div className="containerInventory03">
									{option === null && <GeneralInventoryInitial />}
									{option === "Inventario Maestro" && <InventoriesApp />}
									{option === "Almacén General" && <InventoryShowApp />}
									{option === "Ingresos" && (
										<InventoryReceiptApp setOption={setOption} />
									)}
									{option === "Salidas" && <GeneralInventoryInitial />}
									{option === "Traslados - Salida" && (
										<InventoryTransferOutApp />
									)}
									{option === "Traslados - Entrada" && (
										<InventoryTransferInApp />
									)}
									{option === "Autorizar Cambios" && (
										<TransformationPermitApp />
									)}
									{option === "Permisos" && <InventoryPermisionsApp />}
								</div>
							</div>
						</div>
					</div>
				)}
			</article>
		</div>
	);
};
