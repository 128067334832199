import React from "react";
import { useParams } from "react-router";
import { Redirect } from "react-router-dom";
import NavbarBpw4 from "../components/generalComponents/NavbarBpw4";
import imagen from "../components/generalComponents/images/logoBPW.png";
import NavbarBpw3 from "../components/generalComponents/NavbarBpw3";

const StopClock = () => {
	let { user } = useParams();
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	return (
		<div>
			{localUser.type === "4" ? (
				<div>
					<NavbarBpw4 />
				</div>
			) : localUser.type === "3" ? (
				<div>
					<NavbarBpw3 />
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
			<div className="containerIcon">
				<article className="titulo9">
					⏰ Su reloj fue detenido por el usuario: ⏰
				</article>
				<article className="titulo9">{user}</article>

				<br />
				<img src={imagen} />
			</div>
		</div>
	);
};

export default StopClock;
