import React, { useEffect, useState } from "react";
import { askDataKeyValue2 } from "../../../utils/actions/firebase/firebaseActions";

const CrudRegAssignmentsTableRow = ({
	el,
	openModal1,
	goSeeDetail,
	company,
}) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [activities, setActivities] = useState([]);
	const [questions, setQuestions] = useState(null);
	const [responses, setResponses] = useState(0);

	const askActivities = async () => {
		setLoading(true);
		const nameCollection = "activitiesRegister-" + company;
		const key = "processNumber";
		const value = el.processNumber;
		const key2 = "stage";
		const value2 = el.stage;
		const response = await askDataKeyValue2(
			nameCollection,
			key,
			value,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			setActivities(response.data);
			setQuestions(response.data.length);
			let answers = 0;
			response.data.map((line) => {
				if (line.response !== "") {
					answers = answers + 1;
				}
			});
			setResponses(answers);
			setLoading(false);
		} else {
			setActivities([]);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askActivities();
	}, []);

	const buttonAction = (el) => {
		localStorage.setItem("assignment", JSON.stringify(el));
		// localStorage.setItem("processNumber", el.processNumber);
		// localStorage.setItem("stage", el.stage);
		// localStorage.setItem("dateAssignment", el.date);
		// if (el.statusActingTime) {
		// 	localStorage.setItem("statusActingTime", el.statusActingTime);
		// } else {
		// 	localStorage.setItem("statusActingTime", "no");
		// }
		// localStorage.setItem("proceso", el.process);
		goSeeDetail();
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-regtarea1">{el.date}</td>
			<td className="col-tabla-regtarea2">{el.processNumber}</td>
			<td className="col-tabla-regprocesos1">{el.process}</td>
			<td className="col-tabla-regprocesos1">{el.stage}</td>
			{/* <td className="col-tabla-regtarea1">
				{responses === questions ? (
					<div className="responseColorFull">
						<p>{Math.round((responses * 100) / questions)}%</p>
					</div>
				) : (
					<div className="responseColorEmpty">
						<p>{Math.round((responses * 100) / questions)}%</p>
					</div>
				)}
			</td> */}
			<td className="col-tabla-regprocesos2">
				<button
					onClick={() => buttonAction(el)}
					className="btn-ver-regprocesos"
				>
					Ver
				</button>
			</td>
		</tr>
	);
};

export default CrudRegAssignmentsTableRow;
