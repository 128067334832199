import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import { useModal } from "../../../generalComponents/hooks/useModal";
import Modal from "../../../generalComponents/Modal";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import {
	addDatakey1,
	askDataKeyValue,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../utils/actions/firebase/firebaseActions";
import { InventoriesForm } from "./inventoriesForm";
import { InventoriesTable } from "./inventoriesTable";

export const InventoriesApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	const [dataBase, setDataBase] = useState(null); //listado del inventario de la empresa
	const [inventoryToEdit, setInventoryToEdit] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [loading0, setLoading0] = useState(false);
	const [userPermited, setUserPermited] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	const nameCollection = "iventoryMaster-" + company;

	const askInventory = async () => {
		setLoading(true);
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);
				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askInventory();
	}, []);

	const clickCrear = () => {
		setInventoryToEdit(null);
		openModal1();
	};

	async function crateInventory(data) {
		setLoading(true);
		const response = await addDatakey1(
			nameCollection,
			data,
			"refInventory",
			data.refInventory
		);
		if (response.statusResponse === false) {
			setError(response.error);
			setLoading(false);
			return;
		}
		await askInventory();
		setLoading(false);
	}

	async function updateInventory(data) {
		setLoading(true);
		const docId = data.id;
		const changes = data;
		const key = "refInventory";
		const value = data.refInventory;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			if (response.data.length > 0) {
				if (response.data[0].id != docId) {
					setError("Esta Referencia ya existe en el inventario");
				} else {
					await updateDocumentById(nameCollection, docId, changes);
				}
			}
		}
		setLoading(false);
	}

	async function deleteInventory(data) {
		const docId = data.id;
		let isDelete = window.confirm(
			`¿Estás seguro de eliminar este Item del inventario ?`
		);
		if (isDelete) {
			setLoading(true);
			setError("");
			await deleteDocumentById(nameCollection, docId)
				.then((res) => {
					console.log("respuesta", res);
				})
				.catch((error) => {
					console.log("error", error);
					setError(error);
				});
			setError(null);
			setLoading(false);
		} else {
			return;
		}
	}

	return (
		<div>
			<article>
				{/* <p>Aquí se gestionan listas de Inventario</p> */}
				<article className="titulo11">
					Maestro de Inventarios ({company})
				</article>
				<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
					<InventoriesForm
						company={company}
						crateInventory={crateInventory}
						updateInventory={updateInventory}
						inventoryToEdit={inventoryToEdit}
						setInventoryToEdit={setInventoryToEdit}
						closeModal={closeModal1}
					/>
				</Modal>
				{loading ? (
					<Loader />
				) : (
					<div>
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{dataBase ? (
							dataBase.length >= 0 ? (
								<div>
									<button onClick={clickCrear} className="btn-create-document">
										Nuevo Item de Inventario
									</button>
									<InventoriesTable
										data={dataBase}
										setInventoryToEdit={setInventoryToEdit}
										deleteInventory={deleteInventory}
										openModal={openModal1}
									/>
								</div>
							) : (
								<h3 className="alertEmpty">
									No hay referencia creadas para mostrar
								</h3>
							)
						) : (
							<h3 className="alertEmpty">Buscando...</h3>
						)}
					</div>
				)}
			</article>
		</div>
	);
};
