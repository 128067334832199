import React, { useState } from "react";
import { Link } from "react-router-dom";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export const ProductionOrdersTableRow = ({
	el,
	setProductionOrderToEdit,
	openModal,
	deleteProductionOrder,
}) => {
	const [loading, setLoading] = useState(false);
	const company = localStorage.getItem("usuariosEmpresa");
	const nameCollection = "productionOrders-" + company;

	const editButton = (el) => {
		setProductionOrderToEdit(el);
		openModal();
	};
	const date1 = new Date(el.promiseDate.seconds * 1000).toLocaleDateString();

	const deleteButton = (el) => {
		deleteProductionOrder(el);
	};

	const changeStatus = async (newStatus) => {
		setLoading(true);
		const changes = el;
		changes.status = newStatus;
		const docId = el.id;
		updateDocumentById(nameCollection, docId, changes);
		setLoading(false);
	};

	const buttonChangeStatus = async () => {
		let newStatus = "";
		if (el.status === "abierto") {
			newStatus = "cerrado";
		} else {
			newStatus = "abierto";
		}
		await changeStatus(newStatus);
	};

	if (loading === true) {
		return <Loader />;
	} else {
		return (
			<tr className="fila-tabla-cuenstas">
				{/* <td className="colTableDocuments0">{el.area}</td> */}
				<td className="colTableDocuments1">{el.productionOrder}</td>
				<td className="colTableDocuments1">{el.client}</td>
				<td className="colTableDocuments1">{el.date1}</td>
				<td className="colTableDocuments1">{date1}</td>
				<td className="colTableDocuments1">{el.status}</td>
				<td className="colTableDocuments2">
					{el.status === "abierto" ? (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnCloseRegprocesos"
						>
							Cerrar
						</button>
					) : (
						<button
							onClick={() => buttonChangeStatus()}
							className="btnOpenRegprocesos"
						>
							Abrir
						</button>
					)}
				</td>

				<td className="colTableDocuments2">
					<button onClick={() => editButton(el)} className="btnEditDocuments">
						Editar
					</button>
					{/* borrar solo se habilita para super administrador */}
					{/* <button
						onClick={() => deleteButton(el)}
						className="btnDeleteDocuments"
					>
						Borrar
					</button> */}

					<Link to={"/production-orders/order/" + el.productionOrder}>
						<button className="btnViewDocuments">Ver</button>
					</Link>
				</td>
			</tr>
		);
	}
};
