import React, { useEffect, useState } from "react";
import { Redirect } from "react-router";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";
import { initializeApp } from "@firebase/app";
import Loader from "../../generalComponents/Loader";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import Message from "../../generalComponents/Message";
import Modal from "../../generalComponents/Modal";
import { useModal } from "../../generalComponents/hooks/useModal";
import { firebaseConfig } from "../../../config/firebaseConfig";
import CrudDataBaseTable from "./CrudDataBaseTable";
import CrudDataBaseForm from "./CrudDataBaseForm";

export const CrudDataBaseApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [db1, setDb1] = useState(null); // procesos
	const [processToEdit, setProcessToEdit] = useState(null);
	const [dates, setDates] = useState(null);
	const [goToDataBase, setGoToDataBase] = useState(false);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const askProcess = async () => {
		setLoading(true);
		const nameCollection = "process-" + company;
		const q = query(collection(db, nameCollection));
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDb1(dataList);
				setLoading(false);
			},
			(error) => {
				console.log("resultado negativo", error);
				setDb1(null);
				setError(error);
				setLoading(false);
			}
		);
	};

	useEffect(() => {
		askProcess();
	}, []);

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	return (
		<div>
			{localUser.type === "2" ? (
				<div>
					<NavbarBpw2 />
					<article>
						<h3 className="titleProcess">BASES DE DATOS ({company})</h3>
						<Modal isOpen={isOpenModal1} closeModal={closeModal1}>
							<CrudDataBaseForm
								setDates={setDates}
								processToEdit={processToEdit}
								company={company}
								closeModal={closeModal1}
								setGoToDataBase={setGoToDataBase}
							/>
						</Modal>

						{loading && <Loader />}
						{error && <Message mensaje={error} bgColor="#dc3545" />}
						{db1 ? (
							<CrudDataBaseTable
								process={db1}
								setProcessToEdit={setProcessToEdit}
								openModal1={openModal1}
							/>
						) : (
							<h3>No hay procesos para mostrar</h3>
						)}
						{goToDataBase === true && <Redirect to="/view_DataBase" />}
					</article>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};
