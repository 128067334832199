import React from "react";

const CrudDataBaseTableRow = ({ el, setProcessToEdit, openModal1 }) => {
	const buttonAction = (el) => {
		setProcessToEdit(el);
		localStorage.setItem("processToDataBase", el.process);
		localStorage.setItem("areaToDataBase", el.area);
		openModal1();
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-procesos1">{el.area}</td>
			<td className="col-tabla-procesos1">{el.process}</td>
			<td className="col-tabla-procesos2">
				<button
					onClick={() => buttonAction(el)}
					className="btn-send-reg-asign2"
				>
					Generar
				</button>
			</td>
		</tr>
	);
};

export default CrudDataBaseTableRow;
