import { initializeApp } from "@firebase/app";
import {
	createUserWithEmailAndPassword,
	getAuth,
	signInWithEmailAndPassword,
	signOut,
} from "@firebase/auth";
import {
	addDoc,
	query,
	where,
	onSnapshot,
	orderBy,
	collection,
	deleteDoc,
	doc,
	getFirestore,
	getDocs,
	setDoc,
	updateDoc,
	limit,
	startAfter,
} from "@firebase/firestore";
import { CloudSharp } from "@material-ui/icons";

import { firebaseConfig } from "../../../config/firebaseConfig";

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth();

export const signIn = async (email, password) => {
	const result = { statusResponse: false, error: null };
	return signInWithEmailAndPassword(auth, email, password)
		.then((userCredential) => {
			const nameCollection = "users";
			const key = "uid";
			const value = userCredential.user.uid;
			return askDataKeyValue(nameCollection, key, value).then((res) => {
				return res;
			});
		})
		.catch((error) => {
			const errorCode = error.code;
			const errorMessage = error.message;
			result.error = errorMessage;
			return result;
		});
};

export const logout = () => async (dispatch) => {
	await signOut(auth);
	//   dispatch({
	//     type: authTypes.logout,
	//   });
};

export const updateDocumentById = (collection, docId, changes) => {
	// console.log("*** Colección: ", collection);
	// console.log("*** id: ", docId);
	// console.log("*** cambios: ", changes);
	const docRef = doc(db, collection, docId);
	return updateDoc(docRef, changes);
};
export const updateDocumentByIdResponse = async (
	collection,
	docId,
	changes
) => {
	const result = { statusResponse: false, error: null };
	// console.log("#### recibió:", collection, docId, changes);
	const docRef = doc(db, collection, docId);
	await updateDoc(docRef, changes)
		.then((res) => {
			// console.log("respuesta de actualización : ", res);
			result.statusResponse = true;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});
	return result;
};

export const deleteDocumentById = async (nameCollection, docId) => {
	await deleteDoc(doc(db, nameCollection, docId));
	return;

	// return;
	// return deleteDoc(doc(db, `${collection}`, `${docId}`));
};

export const addDocument = (colection, item) => {
	return addDoc(collection(db, colection), item);
};

export const signUp = async (email, password) => {
	return createUserWithEmailAndPassword(auth, email, password);
};

export const setDocument = (collection, docId, changes, merge) => {
	const docRef = doc(db, collection, docId);
	return setDoc(docRef, changes, merge);
};

// export const resetPassword = (email) => {
// 	return sendPasswordResetEmail(auth, email);
// };

export const askDocuments = async (company) => {
	const result = { statusResponse: true, error: null, documents: null };

	const q = query(collection(db, "documents-list-" + company));
	const querySnapshot = await getDocs(q);
	let documentsList = querySnapshot.docs.map((d) => ({
		id: d.id,
		...d.data(),
	}));
	result.documents = documentsList;
	result.error = "algo";
	return result;
};

export const addDocumento = async (document, company) => {
	const result = { statusResponse: false, error: null };
	await addDoc(collection(db, "documents-list-" + company), document).then(
		async (res) => {
			if (res.id) {
				const docRef = doc(db, "documents-list-" + company, res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
				result.statusResponse = true;
			}
		}
	);
	return result;
};

export const addPresentation = async (document) => {
	const result = { statusResponse: false, error: null };
	await addDoc(collection(db, "coursesMaterial"), document).then(
		async (res) => {
			if (res.id) {
				const docRef = doc(db, "coursesMaterial", res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
				result.statusResponse = true;
			}
		}
	);

	return result;
};
export const addCourse = async (document) => {
	const result = { statusResponse: false, error: null };
	await addDoc(collection(db, "coursesList"), document).then(async (res) => {
		if (res.id) {
			const docRef = doc(db, "coursesList", res.id);
			await updateDoc(docRef, {
				id: res.id,
			});
			result.statusResponse = true;
		}
	});

	return result;
};
export const addModules = async (document) => {
	const result = { statusResponse: false, error: null };
	await addDoc(collection(db, "adminModules"), document).then(async (res) => {
		if (res.id) {
			const docRef = doc(db, "adminModules", res.id);
			await updateDoc(docRef, {
				id: res.id,
			});
			result.statusResponse = true;
		}
	});

	return result;
};

export const addAccount = async (nameCollection, data) => {
	const result = { statusResponse: true, error: null, data: null };
	const fechita = new Date();
	const fechaActual = fechita.toLocaleDateString();
	const horaActual = fechita.toLocaleTimeString();
	data.date = fechaActual;
	const q = query(
		collection(db, nameCollection),
		where("company", "==", data.company)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log(
			// 	"en dataList lista de empresas con ese nombre hay:",
			// 	dataList
			// );
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Esta empresa ya está creada";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const askData = async (nameCollection) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(collection(db, nameCollection), orderBy("pos", "asc"));
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataSimple = async (nameCollection) => {
	// console.log("ingresó a askData...", nameCollection);
	const result = { statusResponse: true, error: null, data: null };
	const q = query(collection(db, nameCollection));
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValueSteps = async (nameCollection, key, value) => {
	// NO SE ESTÁ USANDO, Pero es un buen ejemplo de hacer consultas por lotes ó páginas.

	// console.log("nameCollection:", nameCollection);
	// console.log("key:", key);
	// console.log("value:", value);
	const result = { statusResponse: true, error: null, data: null };

	let firstQuery = true;
	let consulting = true;
	let stepLength = 10000;
	let lastVisible = null;

	while (consulting) {
		if (firstQuery) {
			const first = query(
				collection(db, nameCollection),
				where(key, "==", value),
				limit(stepLength)
			);
			await getDocs(first)
				.then((querySnapshot) => {
					let dataList = querySnapshot.docs.map((d) => ({
						...d.data(),
					}));
					firstQuery = false;
					result.data = dataList;
					// console.log("en result.data hay:", result.data);
					if (dataList.length < stepLength) {
						consulting = false;
					} else {
						lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
						// console.log("last primer lote", lastVisible);
					}
				})
				.catch((error) => {
					console.log("hay error:", error);
					consulting = false;
					result.error = error;
					result.statusResponse = false;
				});
		} else {
			const next = query(
				collection(db, nameCollection),
				where(key, "==", value),
				startAfter(lastVisible),
				limit(stepLength)
			);
			await getDocs(next)
				.then((querySnapshot) => {
					let dataList = querySnapshot.docs.map((d) => ({
						...d.data(),
					}));
					dataList.map((item) => {
						result.data.push(item);
					});
					// result.data = result.data + dataList;
					// console.log("en result.data hay:", result.data);
					if (dataList.length < stepLength) {
						consulting = false;
					} else {
						lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
						// console.log("last siguiente lote", lastVisible);
					}
				})
				.catch((error) => {
					console.log("hay error:", error);
					consulting = false;
					result.error = error;
					result.statusResponse = false;
				});
		}
	}

	// console.log("consulting:", consulting);
	// console.log("firstQuery:", firstQuery);

	return result;
};

export const askDataKeyValue = async (nameCollection, key, value) => {
	// console.log("nameCollection:", nameCollection);
	// console.log("key:", key);
	// console.log("value:", value);
	const result = { statusResponse: true, error: null, data: null };
	const q = query(collection(db, nameCollection), where(key, "==", value));
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
			// console.log("hay respuesta:", dataList.length);
		})
		.catch((error) => {
			console.log("hay error:", error);
			result.error = error;
			result.statusResponse = false;
		});
	return result;
};
export const askDataKeyValueOrder = async (nameCollection, key, value) => {
	// console.log("nameCollection:", nameCollection);
	// console.log("key:", key);
	// console.log("value:", value);
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		orderBy("position", "asc")
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});
	return result;
};

export const askDataKeyValue2Simple = async (
	nameCollection,
	key,
	value,
	key2,
	value2
) => {
	// console.log(
	// 	"ingresó a askDataKeyValue2Simple...",
	// 	nameCollection,
	// 	key,
	// 	value,
	// 	key2,
	// 	value2
	// );
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValue2 = async (
	nameCollection,
	key,
	value,
	key2,
	value2
) => {
	// console.log(
	// 	"ingresó a askDataKeyValue2...",
	// 	nameCollection,
	// 	key,
	// 	value,
	// 	key2,
	// 	value2
	// );
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		orderBy("position", "asc")
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log("dataList", dataList);
			result.data = dataList;
		})
		.catch((error) => {
			console.log(error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValue3Simple = async (
	nameCollection,
	key,
	value,
	key2,
	value2,
	key3,
	value3
) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		where(key3, "==", value3)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("resultado positivo");
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			console.log("resultado negativo", error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};
export const askDataKeyValue3 = async (
	nameCollection,
	key,
	value,
	key2,
	value2,
	key3,
	value3
) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		where(key3, "==", value3),
		orderBy("position", "asc")
	);
	// console.log("coleccion: ", nameCollection);
	// console.log(key);
	// console.log(value);
	// console.log(key2);
	// console.log(value2);
	// console.log(key3);
	// console.log(value3);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("resultado positivo");
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
			// console.log("result.data: ", result.data);
		})
		.catch((error) => {
			// console.log("resultado negativo", error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValue4Simple = async (
	nameCollection,
	key,
	value,
	key2,
	value2,
	key3,
	value3,
	key4,
	value4
) => {
	// console.log(
	// 	"ingresó a askDataKeyValue4...",
	// 	nameCollection,
	// 	key,
	// 	value,
	// 	key2,
	// 	value2,
	// 	key3,
	// 	value3,
	// 	key4,
	// 	value4
	// );
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "==", value4)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("resultado positivo");
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			// console.log("resultado negativo", error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValue4SimpleDif = async (
	nameCollection,
	key,
	value,
	key2,
	value2,
	key3,
	value3,
	key4,
	value4
) => {
	// console.log(
	// 	"ingresó a askDataKeyValue4simpleDif...",
	// 	nameCollection,
	// 	key,
	// 	value,
	// 	key2,
	// 	value2,
	// 	key3,
	// 	value3,
	// 	key4,
	// 	value4
	// );
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "!=", value4)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("resultado positivo");
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			// console.log("resultado negativo", error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const askDataKeyValue4 = async (
	nameCollection,
	key,
	value,
	key2,
	value2,
	key3,
	value3,
	key4,
	value4
) => {
	// console.log(
	// 	"ingresó a askDataKeyValue3...",
	// 	nameCollection,
	// 	key,
	// 	value,
	// 	key2,
	// 	value2,
	// 	key3,
	// 	value3
	// );
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key, "==", value),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "==", value4),
		orderBy("position", "asc")
	);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("resultado positivo");
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			result.data = dataList;
		})
		.catch((error) => {
			console.log("resultado negativo", error);
			result.error = error;
			result.statusResponse = false;
		});

	// console.log("en result para entregar hay: ", result);
	return result;
};

export const addAgency = async (nameCollection, data) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where("agency", "==", data.agency)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log(
			// 	"en dataList lista de sucursales con ese nombre hay:",
			// 	dataList
			// );
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Esta Sucursal ya está creada";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const addArea = async (nameCollection, data) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where("area", "==", data.area)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log("en dataList lista de areas con ese nombre hay:", dataList);
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Esta Area ya está creada";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const addProcess = async (nameCollection, data) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where("company", "==", data.company),
		where("process", "==", data.process)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log(
			// 	"en dataList lista de sucursales con ese nombre hay:",
			// 	dataList
			// );
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Este proceso ya está creada";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const addQuiz = async (data) => {
	const nameCollection = "coursesQuizzes";
	let result = null;
	await addDoc(collection(db, nameCollection), data).then(async (res) => {
		if (res.id) {
			result = res.id;
			const docRef = doc(db, nameCollection, res.id);
			await updateDoc(docRef, {
				id: res.id,
			});
		}
	});
	return result;
};

export const addData = async (nameCollection, data) => {
	addDoc(collection(db, nameCollection), data).then(async (res) => {
		if (res.id) {
			const docRef = doc(db, nameCollection, res.id);
			await updateDoc(docRef, {
				id: res.id,
			});
		}
	});
};

export const addDatakey1 = async (nameCollection, data, key1, value1) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(collection(db, nameCollection), where(key1, "==", value1));
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "No se puede porque este ITEM ya está creado...";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});
	return result;
};

export const addDataKey2 = async (
	nameCollection,
	data,
	key1,
	value1,
	key2,
	value2
) => {
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key1, "==", value1),
		where(key2, "==", value2)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log(
			// 	"en dataList lista de sucursales con ese nombre hay:",
			// 	dataList
			// );
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error =
					"No se puede porque este Item ya está creado, intenta nuevamente cambiando...";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const addDataKey3 = async (
	nameCollection,
	data,
	key1,
	value1,
	key2,
	value2,
	key3,
	value3
) => {
	// console.log(">>>>> recibe:", data);
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key1, "==", value1),
		where(key2, "==", value2),
		where(key3, "==", value3)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			// console.log(
			// 	"en dataList lista de sucursales con ese nombre hay:",
			// 	dataList
			// );
			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Este Item ya está creado, intenta nuevamente ...";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};
export const addDataKey4 = async (
	nameCollection,
	data,
	key1,
	value1,
	key2,
	value2,
	key3,
	value3,
	key4,
	value4
) => {
	// console.log("**** ingreso addDataKey4", nameCollection);
	const result = { statusResponse: true, error: null, data: null };
	const q = query(
		collection(db, nameCollection),
		where(key1, "==", value1),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "==", value4)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			// console.log("hay querySnapshot", querySnapshot);
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));

			if (dataList.length > 0) {
				result.statusResponse = false;
				result.error = "Este Item ya está creado, intenta nuevamente...";
				return result;
			} else {
				addDoc(collection(db, nameCollection), data).then(async (res) => {
					if (res.id) {
						const docRef = doc(db, nameCollection, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				});
			}
		})
		.catch((error) => {
			console.log("++++ error", error);
			result.error = error;
			result.statusResponse = false;
		});

	return result;
};

export const downPositionStages = async (data, company) => {
	const result = { statusResponse: true, error: null };
	// console.log(">>> que recibe de data:", data);
	const pos1 = data.position;
	const pos2 = pos1 + 1;
	let data2 = null;
	const nameCollection = "stages-" + company;
	const key2 = "process";
	const value2 = data.process;
	const key3 = "position";
	const value3 = pos2;
	const q = query(
		collection(db, nameCollection),
		where(key2, "==", value2),
		where(key3, "==", value3)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			data2 = dataList[0];
			data.position = pos2;
			data2.position = pos1;
			// console.log("data", data);
			// console.log("data2", data2);
			updateDocumentById(nameCollection, data.id, data);
			updateDocumentById(nameCollection, data2.id, data2);
		})
		.catch((error) => {
			// console.log("*** error", error);
			result.error =
				"Error 404: No se pudo acceder a la base de datos de Etapas";
			result.statusResponse = false;
		});

	return result;
};
export const upPositionStages = async (data, company) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.position;
	const pos2 = pos1 - 1;
	let data2 = null;
	const nameCollection = "stages-" + company;
	const key2 = "process";
	const value2 = data.process;
	const key3 = "position";
	const value3 = pos2;
	const q = query(
		collection(db, nameCollection),
		where(key2, "==", value2),
		where(key3, "==", value3)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			data2 = dataList[0];
			data.position = pos2;
			data2.position = pos1;
			updateDocumentById(nameCollection, data.id, data);
			updateDocumentById(nameCollection, data2.id, data2);
		})
		.catch((error) => {
			console.log("*** error", error);
			result.error =
				"Error 404: No se pudo acceder a la base de datos de Etapas";
			result.statusResponse = false;
		});

	return result;
};

export const downPositionActivities = async (data, company) => {
	const result = { statusResponse: true, error: null };
	// console.log(">>> que recibe de data:", data);
	const pos1 = data.position;
	const pos2 = pos1 + 1;
	let data2 = null;
	const nameCollection = "activities-" + company;
	const key2 = "process";
	const value2 = data.process;
	const key3 = "stage";
	const value3 = data.stage;
	const key4 = "position";
	const value4 = pos2;
	const q = query(
		collection(db, nameCollection),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "==", value4)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			data2 = dataList[0];
			data.position = pos2;
			data2.position = pos1;
			// console.log("data", data);
			// console.log("data2", data2);
			updateDocumentById(nameCollection, data.id, data);
			updateDocumentById(nameCollection, data2.id, data2);
		})
		.catch((error) => {
			console.log("*** error", error);
			result.error =
				"Error 404: No se pudo acceder a la base de datos de Etapas";
			result.statusResponse = false;
		});

	return result;
};

export const upPositionActivities = async (data, company) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.position;
	const pos2 = pos1 - 1;
	let data2 = null;
	const nameCollection = "activities-" + company;
	const key2 = "process";
	const value2 = data.process;
	const key3 = "stage";
	const value3 = data.stage;
	const key4 = "position";
	const value4 = pos2;
	const q = query(
		collection(db, nameCollection),
		where(key2, "==", value2),
		where(key3, "==", value3),
		where(key4, "==", value4)
	);
	await getDocs(q)
		.then((querySnapshot) => {
			let dataList = querySnapshot.docs.map((d) => ({
				...d.data(),
			}));
			data2 = dataList[0];
			data.position = pos2;
			data2.position = pos1;
			updateDocumentById(nameCollection, data.id, data);
			updateDocumentById(nameCollection, data2.id, data2);
		})
		.catch((error) => {
			console.log("*** error", error);
			result.error =
				"Error 404: No se pudo acceder a la base de datos de Etapas";
			result.statusResponse = false;
		});

	return result;
};

export const addRegProcess = async (datos, company) => {
	// console.log("en addRegProcess recibe datos:", datos);
	const result = { statusResponse: true, error: null };
	//crea el número de proceso en processRegister
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();
	const horaActual1 = fechita1.toLocaleTimeString();
	let obj1 = {
		// agency: datos.agency,
		area: datos.area,
		date: fechita1,
		date1: fechaActual1,
		hour1: horaActual1,
		id: datos.id,
		creator: datos.creator,
		processNumber: datos.processNumber,
		process: datos.process,
		status: datos.status,
		dateClose: datos.dateClose,
		hourClose: datos.hourClose,
		complianceProcess: datos.complianceProcess,
		questions: datos.questions,
		responses: datos.responses,
		productionOrder: datos.productionOrder,
		refProductionProduct: datos.refProductionProduct,
		quantity: datos.quantity,
		timeControl: datos.timeControl,
		inventoryControl: datos.inventoryControl,
		finishProduct: datos.finishProduct,
		product: datos.productionProduct.description,
	};
	datos.date = fechita1;
	datos.date1 = fechaActual1;
	datos.hour1 = horaActual1;
	let nameCollection = "processRegister-" + company;
	let key2 = "processNumber";
	let value2 = datos.processNumber;
	const res1 = await addDatakey1(nameCollection, obj1, key2, value2);
	if (res1.error) {
		console.log("entró en error");
		//es porque ya existe el número de proceso en esa empresa
		result.statusResponse = false;
		result.error =
			"El Proceso ya existe, debe crearlo con un nuevo Número de Proceso";
		return result;
	} else {
		// console.log("entró en el else");
		//consulta las etapas del proceso
		// console.log("res1:", res1);
		nameCollection = "stages-" + company;
		key2 = "process";
		value2 = datos.process;
		const res2 = await askDataKeyValueOrder(nameCollection, key2, value2);
		if (res2.error) {
			console.log("error:", res2.error);
			result.statusResponse = false;
			result.error = res2.error;
			return result;
		}
		const stages = res2.data;
		// console.log("*** las etaps del proceo que va a crear son:", stages);
		let position = 0;
		//A cada etapa del proceso crearle una asignación en blanco
		stages.map(async (stageRow) => {
			position = position + 1;
			let obj3 = {
				id: null,
				position: position,
				// agency: datos.agency,
				area: datos.area,
				assignment: "",
				processNumber: datos.processNumber,
				process: datos.process,
				stage: stageRow.stage,
				status: "abierto",
				date: fechaActual1,
				complianceStage: 0,
			};
			if (datos.timeControl === "SI") {
				obj3 = {
					...obj3,
					totalTime: 0,
					statusActingTime: "no",
					eventsTime: [],
				};
			}
			await addDoc(collection(db, "assignments-" + company), obj3)
				.then(async (res03) => {
					// console.log("---- ya creó las asignaciones");
					if (res03.id) {
						const docRef = doc(db, "assignments-" + company, res03.id);
						await updateDoc(docRef, {
							id: res03.id,
						});
					}
				})
				.catch((error) => {
					// console.log("+++ error al grabar las asignaciones:", error);
					result.error =
						"No se pudo crear datos en Asignaciones. Por favor comunicar al Administrador IT";
					result.statusResponse = false;
					return result;
				});
			//consult las acividades de cada etapa
			nameCollection = "activities-" + company;
			let key3 = "stage";
			let value3 = stageRow.stage;
			const res3 = await askDataKeyValue2(
				nameCollection,
				key2,
				value2,
				key3,
				value3
			);
			// console.log("**** Las actividades que va a crear están en res3", res3);
			// console.log(
			// 	"**** Las actividades que va a crear estpan en res3.data",
			// 	res3.data
			// );
			if (res3.statusResponse) {
				const activities = res3.data;
				activities.map(async (activityRow) => {
					const obj4 = {
						id: null,
						// date: "",
						// hour: "",
						// agency: datos.agency,
						area: datos.area,
						processNumber: datos.processNumber,
						process: datos.process,
						stage: stageRow.stage,
						activity: activityRow.activity,
						position: activityRow.position,
						// descriptionActivity: activityRow.descriptionActivity,
						// responseType: activityRow.responseType,
						// userChanges: "",
						// response: "",
						totalAmount: datos.quantity,
						amount: 0,
						comment: "",
						product: obj1.product,
					};
					// console.log("debe grabar:", obj4);
					await addDoc(collection(db, "activitiesRegister-" + company), obj4)
						.then(async (res04) => {
							if (res04.id) {
								// console.log(">>> grabó res04: ", res04);
								const docRef = doc(
									db,
									"activitiesRegister-" + company,
									res04.id
								);
								await updateDoc(docRef, {
									id: res04.id,
								});
							}
						})
						.catch((error) => {
							result.error =
								"No se puedo crear datos en activitiesRegister. Por favor comunicar al Administrador IT";
							result.statusResponse = false;
							return result;
						});
				});
			} else {
				result.statusResponse = false;
				result.error =
					"Error consultando la base de datos de Actividades, proceso incompleto comuníquese con Administrador IT";
				return result;
			}
		});
	}
	if (datos.productionProduct) {
		let productionProduct = datos.productionProduct;
		productionProduct.onProcess = datos.newOnProcess;
		const nameCollection = "productionProduct-" + company;
		updateDocumentById(nameCollection, productionProduct.id, productionProduct);
	}
	return result;
};

// export const askRegProcess = async (userData) => {
// 	const result = { statusResponse: true, error: null, data: null };
// 	const q = query(
// 		collection(db, "processRegister"),
// 		where("company", "==", userData.company),
// 		where("agency", "==", userData.agency),
// 		where("area", "==", userData.area),
// 		orderBy("date", "asc")
// 	);

// 	onSnapshot(
// 		q,
// 		(querySnapshot) => {
// 			let dataList = querySnapshot.docs.map((d) => ({
// 				...d.data(),
// 			}));
// 			result.data = dataList;
// 			// console.log("en result para entregar hay: ", result);
// 			return result;
// 		},
// 		(error) => {
// 			console.log("resultado negativo", error);
// 			result.error = error;
// 			result.statusResponse = false;
// 			return result;
// 		}
// 	);

// 	// await getDocs(q)
// 	// 	.then((querySnapshot) => {
// 	// 		console.log("resultado positivo");
// 	// 		let dataList = querySnapshot.docs.map((d) => ({
// 	// 			...d.data(),
// 	// 		}));
// 	// 		result.data = dataList;
// 	// 	})
// 	// 	.catch((error) => {
// 	// 		console.log("resultado negativo", error);
// 	// 		result.error = error;
// 	// 		result.statusResponse = false;
// 	// 	});
// };

export const updateCompliance = async (data, company) => {
	//1. cumplimiento de la Etapa
	//1.1. identificar la etapa en assignments
	const stage = data.stage;
	const resp01 = await askDataKeyValue3Simple(
		"assignments-" + company,
		"process",
		data.process,
		"processNumber",
		data.processNumber,
		"stage",
		data.stage
	);
	if (!resp01.statusResponse) {
		return;
	}
	const stageToChange = resp01.data[0];
	//1.2. cuántas actividades hay en total en la etapa
	const resp02 = await askDataKeyValue2Simple(
		"activitiesRegister-" + company,
		"processNumber",
		data.processNumber,
		"stage",
		data.stage
	);
	if (!resp02.statusResponse) {
		return;
	}
	const totalActivities = resp02.data.length;
	//1.3. cuantas actividades cumplen en la etapa
	const resp03 = await askDataKeyValue3Simple(
		"activitiesRegister-" + company,
		"processNumber",
		data.processNumber,
		"stage",
		data.stage,
		"response",
		"Cumple"
	);
	if (!resp03.statusResponse) {
		return;
	}
	const complianceActvities = resp03.data.length;
	//1.4. cálculo del cumplimiento de la etapa y modificar en assignments
	if (totalActivities === 0) {
		return;
	}
	const complianceStage = complianceActvities / totalActivities;
	stageToChange.complianceStage = complianceStage;
	await updateDocumentById(
		"assignments-" + company,
		stageToChange.id,
		stageToChange
	);

	//2. cumplimiento del proceso
	//2.1. identificar el proceso en processRegister por número de proceso
	const resp04 = await askDataKeyValue2Simple(
		"processRegister-" + company,
		"process",
		data.process,
		"processNumber",
		data.processNumber
	);
	const processToChange = resp04.data[0];
	// console.log("1. processToChange: ", processToChange);
	//2.2. leer los assignments del proceso => lista de etapas
	const resp05 = await askDataKeyValue2Simple(
		"assignments-" + company,
		"process",
		data.process,
		"processNumber",
		data.processNumber
	);
	//2.3. hacer el promedio del cumplimiento de todos los asignments en el proceso
	const stagesList = resp05.data;
	const totalStages = stagesList.length;
	let sumcompliances = 0;
	await stagesList.map(
		(lineStage) => (sumcompliances = sumcompliances + lineStage.complianceStage)
	);
	const complianceProcess = sumcompliances / totalStages;
	// console.log("2. complianceProcess:", complianceProcess);
	processToChange.complianceProcess = complianceProcess;
	//2.4. modificar el cumplimiento del proceso
	// await updateDocumentById(
	// 	"processRegister",
	// 	processToChange.id,
	// 	processToChange
	// );
	//3. registrar cuántas actividades tienen respuestas en el proceso

	const resp06 = await askDataKeyValue3Simple(
		"activitiesRegister-" + company,
		"process",
		data.process,
		"processNumber",
		data.processNumber,
		"response",
		""
	);
	// console.log(">>>> resp06:", resp06);
	const responses = resp06.data.length;
	processToChange.responses = responses;
	await updateDocumentById(
		"processRegister-" + company,
		processToChange.id,
		processToChange
	);
};

export const assingCourses = async ({
	company,
	course,
	status,
	creationDate,
	initialDate,
	finalDate,
	studentList,
	calification,
	assignedBy,
	califiactionDAte,
	intents,
	maxIntents,
}) => {
	const result = { statusResponse: true, error: null };

	studentList.map(async (student) => {
		const asignation = {
			id: null,
			company,
			course,
			status,
			creationDate,
			initialDate,
			finalDate,
			student,
			calification,
			assignedBy,
			califiactionDAte,
			intents,
			maxIntents,
		};
		// console.log("debe grabar:", asignation);
		await addDoc(collection(db, "coursesAsignment"), asignation)
			.then(async (res) => {
				if (res.id) {
					const docRef = doc(db, "coursesAsignment", res.id);
					await updateDoc(docRef, {
						id: res.id,
					});
				}
			})
			.catch((error) => {
				result.error =
					"No se puedo crear datos en activitiesRegister. Por favor comunicar al Administrador IT";
				result.statusResponse = false;
				return result;
			});
	});
	return result;
};

export const receiptInventory = async (data, company) => {
	const nameCollection1 = "inventoryReceipt-" + company;
	const nameCollection2 = "inventoryGeneral-" + company;
	const result = { statusResponse: true, error: null };
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();
	const dataToRecord = {
		date: fechaActual1,
		docNumber: data.docNumber,
		id: null,
		provider: data.provider,
		recievedProducts: [],
		totalValue: data.totalValue,
		user: data.user,
	};
	data.recievedProducts.map(async (productList) => {
		const infoProduct = {
			amount: productList.amount,
			refInventory: productList.product.value,
			unitCost: productList.unitCost,
		};
		dataToRecord.recievedProducts.push(infoProduct);
		const key = "refInventory";
		const value = productList.product.value;
		const listInventory = await askDataKeyValue(nameCollection2, key, value);
		const refData = {
			id: null,
			refInventory: productList.product.value,
			description: productList.product.description,
			cost: productList.unitCost,
			amount: productList.amount,
		};
		if (listInventory.data.length > 0) {
			let newAmount = listInventory.data[0].amount + productList.amount;
			const docRef = doc(db, nameCollection2, listInventory.data[0].id);
			await updateDoc(docRef, {
				amount: newAmount,
				cost:
					(listInventory.data[0].cost * listInventory.data[0].amount +
						productList.unitCost * productList.amount) /
					newAmount,
			});
		} else {
			await addDoc(collection(db, nameCollection2), refData)
				.then(async (res) => {
					// console.log("resp:", res);
					if (res.id) {
						const docRef = doc(db, nameCollection2, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				})
				.catch((error) => {
					result.error =
						"Hubo un error al grabar en (inventoryGeneral-empresa. por favor comunicar al Administrador IT";
					result.statusResponse = false;
					return result;
				});
		}
	});

	await addDoc(collection(db, nameCollection1), dataToRecord)
		.then(async (res) => {
			if (res.id) {
				const docRef = doc(db, nameCollection1, res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
			}
		})
		.catch((error) => {
			result.error =
				"No se pudo grabar el Ingreso en la DB (inventoryReceipt-company). Por favor comunicar al Administrador IT";
			result.statusResponse = false;
			return result;
		});
	return result;
};

export const separadoresDeMil = (number) => {
	let parts = number.toString().split(".");
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	return parts.join(".");
};

export const recTransferInventory = async (data, company) => {
	const nameCollection1 = "inventoryTransfer-" + company;
	const nameCollection2 = "inventoryInTransit-" + company;
	const result = { statusResponse: true, error: null };
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();

	const dataToRecord = {
		date: fechaActual1,
		id: null,
		userFrom: data.userFrom,
		transferProducts: [],
		from: data.from,
		productionOrder: data.productionOrder,
		processNumber: data.processNumber,
		stage: data.stage.stage,
		userTo: data.stage.assignment,
		status: "pendiente",
	};

	data.transferProducts.map(async (product) => {
		let prod = {
			amount: product.amount,
			refInventory: product.product.product.refInventory,
			description: product.product.description,
		};
		dataToRecord.transferProducts.push(prod);

		const key = "refInventory";
		const value = prod.refInventory;
		const key2 = "from";
		const value2 = data.from;
		const listInventory = await askDataKeyValue2Simple(
			nameCollection2,
			key,
			value,
			key2,
			value2
		);
		const refData = {
			...prod,
			from: data.from,
			id: null,
		};
		if (listInventory.data.length > 0) {
			let newAmount = listInventory.data[0].amount + refData.amount;
			const docRef = doc(db, nameCollection2, listInventory.data[0].id);
			await updateDoc(docRef, {
				amount: newAmount,
			});
		} else {
			await addDoc(collection(db, nameCollection2), refData)
				.then(async (res) => {
					// console.log("resp:", res);
					if (res.id) {
						const docRef = doc(db, nameCollection2, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				})
				.catch((error) => {
					result.error =
						"Hubo un error al grabar en (inventoryInTransit-empresa. por favor comunicar al Administrador IT";
					result.statusResponse = false;
					return result;
				});
		}
	});

	await addDoc(collection(db, nameCollection1), dataToRecord)
		.then(async (res) => {
			if (res.id) {
				const docRef = doc(db, nameCollection1, res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
			}
		})
		.catch((error) => {
			result.error =
				"No se pudo grabar la Transferencia en la DB (inventoryTransfer-company). Por favor comunicar al Administrador IT";
			result.statusResponse = false;
			return result;
		});
	return result;
};

export const recTransferInventoryParticular = async (
	data,
	company,
	originProcessNumber
) => {
	const nameCollection1 = "inventoryTransfer-" + company;
	const nameCollection2 = "inventoryInTransit-" + company;
	const result = { statusResponse: true, error: null };
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();
	let dataToRecord = {};
	if (data.to === "warehouse") {
		dataToRecord = {
			date: fechaActual1,
			id: null,
			userFrom: data.userFrom,
			transferProducts: [],
			from: data.from,
			status: "pendiente",
			to: data.to,
			fromProcessNumber: originProcessNumber,
		};
	} else {
		dataToRecord = {
			date: fechaActual1,
			id: null,
			userFrom: data.userFrom,
			transferProducts: [],
			from: data.from,
			productionOrder: data.productionOrder,
			processNumber: data.processNumber,
			stage: data.stage.stage,
			userTo: data.stage.assignment,
			status: "pendiente",
			to: data.to,
			fromProcessNumber: originProcessNumber,
		};
	}

	data.transferProducts.map(async (product) => {
		let prod = {
			amount: product.amount,
			refInventory: product.product.product.refInventory,
			description: product.product.description,
		};
		dataToRecord.transferProducts.push(prod);

		const key = "refInventory";
		const value = prod.refInventory;
		const key2 = "from";
		const value2 = data.from;
		const key3 = "processNumber";
		const value3 = originProcessNumber;
		const listInventory = await askDataKeyValue3Simple(
			nameCollection2,
			key,
			value,
			key2,
			value2,
			key3,
			value3
		);
		const refData = {
			...prod,
			from: data.from,
			id: null,
			processNumber: originProcessNumber,
		};
		if (listInventory.data.length > 0) {
			let newAmount = listInventory.data[0].amount + refData.amount;
			const docRef = doc(db, nameCollection2, listInventory.data[0].id);
			await updateDoc(docRef, {
				amount: newAmount,
			});
		} else {
			await addDoc(collection(db, nameCollection2), refData)
				.then(async (res) => {
					// console.log("resp:", res);
					if (res.id) {
						const docRef = doc(db, nameCollection2, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				})
				.catch((error) => {
					result.error =
						"Hubo un error al grabar en (inventoryInTransit-empresa. por favor comunicar al Administrador IT";
					result.statusResponse = false;
					return result;
				});
		}
	});

	await addDoc(collection(db, nameCollection1), dataToRecord)
		.then(async (res) => {
			if (res.id) {
				const docRef = doc(db, nameCollection1, res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
			}
		})
		.catch((error) => {
			result.error =
				"No se pudo grabar la Transferencia en la DB (inventoryTransfer-company). Por favor comunicar al Administrador IT";
			result.statusResponse = false;
			return result;
		});
	return result;
};

export const recTransformInventory = async (data, company) => {
	const nameCollection1 = "inventoryTransform-" + company;
	const nameCollection2 = "inventoryInTransit-" + company;
	const result = { statusResponse: true, error: null };
	const fechita1 = new Date();
	const fechaActual1 = fechita1.toLocaleDateString();

	const dataToRecord = {
		date: fechaActual1,
		id: null,
		user: data.user,
		initialProducts: [],
		finalProducts: [],
		processNumber: data.processNumber,
		status: "pendiente",
	};

	data.finalProducts.map((product) => {
		let prod = {
			amount: product.amount,
			refInventory: product.product.product.refInventory,
			description: product.product.description,
		};
		dataToRecord.finalProducts.push(prod);
	});

	data.initialProducts.map(async (product) => {
		let prod = {
			amount: product.amount,
			refInventory: product.product.product.refInventory,
			description: product.product.description,
		};
		dataToRecord.initialProducts.push(prod);

		const key = "refInventory";
		const value = prod.refInventory;
		const key2 = "from";
		const value2 = data.user;
		const key3 = "processNumber";
		const value3 = data.processNumber;
		const listInventory = await askDataKeyValue3Simple(
			nameCollection2,
			key,
			value,
			key2,
			value2,
			key3,
			value3
		);
		const refData = {
			...prod,
			from: data.user,
			id: null,
			processNumber: data.processNumber,
		};
		// const idDocument=prod.refInventory+data.user+data.processNumber
		// const refData = {
		// 	...prod,
		// 	from: data.user,
		// 	id: idDocument,
		// 	processNumber: data.processNumber,
		// };
		if (listInventory.data.length > 0) {
			let newAmount = listInventory.data[0].amount + refData.amount;
			const docRef = doc(db, nameCollection2, listInventory.data[0].id);
			await updateDoc(docRef, {
				amount: newAmount,
			});
		} else {
			// await setDoc(doc(db, nameCollection2, refData.id), refData);
			await addDoc(collection(db, nameCollection2), refData)
				.then(async (res) => {
					// console.log("resp:", res);
					if (res.id) {
						const docRef = doc(db, nameCollection2, res.id);
						await updateDoc(docRef, {
							id: res.id,
						});
					}
				})
				.catch((error) => {
					result.error =
						"Hubo un error al grabar en (inventoryInTransit-empresa. por favor comunicar al Administrador IT";
					result.statusResponse = false;
					return result;
				});
		}
	});

	await addDoc(collection(db, nameCollection1), dataToRecord)
		.then(async (res) => {
			if (res.id) {
				const docRef = doc(db, nameCollection1, res.id);
				await updateDoc(docRef, {
					id: res.id,
				});
			}
		})
		.catch((error) => {
			result.error =
				"No se pudo grabar la Transformación en la DB (inventoryTransform-company). Por favor comunicar al Administrador IT";
			result.statusResponse = false;
			return result;
		});
	return result;
};

export const milisecondsToHHMM = (miliseconds) => {
	let hour = miliseconds / 3600000;
	let hourTrunc = Math.trunc(hour);
	let res1 = hour - hourTrunc;
	let min = res1 * 60;
	let minTrunc = Math.trunc(min);
	let hhmm = { hours: hourTrunc, minuts: minTrunc };
	return hhmm;
};
