import React, { useEffect, useState } from "react";
import { updateDocumentById } from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export const TableProcessCauses = ({ process, company }) => {
	const [form, setForm] = useState(process);
	const [loading, setLoading] = useState(false);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	useEffect(() => {
		setForm(process);
	}, [process]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		let isDelete = window.confirm(
			`¿Está seguro de Modificar?, quedará un registro que usted hizo la modificación...`
		);
		if (isDelete) {
			setLoading(true);
			form.modified = localUser.email;
			const collection = "processRegister-" + company;
			const docId = form.id;
			const changes = form;
			await updateDocumentById(collection, docId, changes);
			console.log(">>>< va a actualizar el modificar causas....");
			setLoading(false);
		} else {
			return;
		}
	};

	return (
		<div>
			{loading ? (
				<Loader />
			) : (
				<form onSubmit={handleSubmit} className="form-procesos">
					<label className="titulo5">Descripción del Problema</label>
					<textarea
						type="text"
						name="problemDescription"
						placeholder="Descripcion del problema"
						onChange={handleChange}
						value={form.problemDescription}
						className="input2"
					/>
					<label className="titulo5">Causas</label>
					<textarea
						type="text"
						name="problemCauses"
						placeholder="Causas"
						onChange={handleChange}
						value={form.problemCauses}
						className="input2"
					/>
					<hr />
					<div className="contModif">
						<h6 className="textAlert">
							Ultima vez modificado por ({form.modified})
						</h6>
						<input type="submit" value="Modificar" className="btnModif" />
					</div>
				</form>
			)}
		</div>
	);
};
