import React from "react";
import { Redirect } from "react-router-dom";
import imagen from "../components/generalComponents/images/logoBPW.png";
import NavbarBpw3 from "../components/generalComponents/NavbarBpw3";

const Supervisor = () => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	return (
		<div>
			{localUser.type === "3" ? (
				<div>
					<NavbarBpw3 />
					<div className="containerIcon">
						<h2>B I E N V E N I D O</h2>
						<br />
						<br />
						<br />
						<img src={imagen} />
					</div>
				</div>
			) : (
				<Redirect to="/intruso" />
			)}
		</div>
	);
};

export default Supervisor;
