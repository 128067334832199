import React from "react";
import Adjust from "@material-ui/icons/Adjust";
import AddCircle from "@material-ui/icons/AddCircle";
import {
	askDataKeyValue,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudProcessTableRow = ({
	el,
	setProcessToEdit,
	openModal1,
	setGoStages,
	company,
}) => {
	const buttonAction = (el) => {
		setProcessToEdit(el);
		openModal1();
	};
	const goStages = () => {
		localStorage.setItem("procesoEditar", el.process);
		localStorage.setItem("areaProcesoEditar", el.area);
		localStorage.setItem("processRelease", el.release);
		setGoStages(true);
	};

	const processRelease = async (el) => {
		let isRelease = window.confirm(`¿Está segur@ de Liberar este Proceso?`);
		if (isRelease) {
			let process = el;
			process.release = "si";
			process.active = "si";
			const resp1 = await askDataKeyValue(
				"activities-" + company,
				"process",
				process.process
			);
			let activitiesList = resp1.data;
			let sum = 0;
			for (const activity of activitiesList) {
				if (activity.responseType === "Cumple-NoCumple") {
					sum = sum + 1;
				}
			}
			if (sum === activitiesList.length) {
				process.processWithIndicator = "si";
				updateDocumentById("process-" + company, process.id, process);
			} else {
				process.processWithIndicator = "no";
				updateDocumentById("process-" + company, process.id, process);
			}
		} else {
			return;
		}
	};

	const changeActive = async (el) => {
		let isRelease = window.confirm(
			`¿Está segur@ de cambiar el estado de "Activo" ?`
		);
		if (isRelease) {
			let process = el;
			console.log("process:", process);
			if (process.active === "si") {
				process.active = "no";
			} else {
				process.active = "si";
			}
			updateDocumentById("process-" + company, process.id, process);
		} else {
			return;
		}
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-procesos1">{el.area}</td>
			<td className="col-tabla-procesos1">{el.process}</td>
			<td className="col-tabla-procesos1">{el.descriptionProcess}</td>
			<td className="col-tabla-procesos2">
				<div className="contActions01">
					<button className="btn-show-stage" onClick={() => goStages()}>
						Ver Etapas
					</button>
					{el.release === "no" && (
						<div>
							<button
								onClick={() => buttonAction(el)}
								className="btn-editar-procesos"
							>
								Editar
							</button>
							<button
								className="btn-release-process"
								onClick={() => processRelease(el)}
							>
								Liberar
							</button>
						</div>
					)}
				</div>
			</td>
			<td className="col-tabla-procesos1">
				<div className="contActions01">
					{el.active === "si" ? (
						<p className="sino">SI</p>
					) : (
						<p className="sino">NO</p>
					)}
					{el.active === "si" ? (
						<button className="btnActive" onClick={() => changeActive(el)}>
							<Adjust />
						</button>
					) : (
						<button className="btnInActive" onClick={() => changeActive(el)}>
							<AddCircle />
						</button>
					)}
				</div>
			</td>
		</tr>
	);
};

export default CrudProcessTableRow;
