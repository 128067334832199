import React from "react";

export const TimeClocksTableRow = ({ el, stopParticular }) => {
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.area}</td>
			<td className="colTableDocuments1">{el.assignment}</td>
			<td className="colTableDocuments1">{el.process}</td>
			<td className="colTableDocuments1">{el.processNumber}</td>
			<td className="colTableDocuments1">{el.stage}</td>
			<td className="colTableDocuments1">
				<button
					onClick={() => stopParticular(el)}
					className="btnClearTimeParticular"
				>
					Detener
				</button>
			</td>
		</tr>
	);
};
