import React, { useEffect, useState } from "react";
import { ProcessOrderViewTableRow } from "./processOrderViewTableRow";

export const ProcessOrderViewTable = ({
	data,
	setProductionProductToEdit,
	deleteProductionProduct,
	openModal,
}) => {
	const [showData, setShowData] = useState(data);

	useEffect(() => {
		setShowData(data);
	}, [data]);

	// console.log("data", data);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setShowData(data);
		}
		setShowData(
			data.filter(
				(item) =>
					item.refProductionProduct.toLowerCase().includes(e.target.value) ||
					item.description.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div className="headerPO">
			{/* <div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div> */}
			<h4>Lista de Productos</h4>
			<table className="tabla-procesos-light">
				<thead>
					<tr className="fila-tabla-procesos-light">
						<th className="titleTableDocuments03">Referencia</th>
						<th className="titleTableDocuments03">Cantidad</th>
						<th className="titleTableDocuments03">Unidad</th>
						<th className="titleTableDocuments02">Descripción</th>
						<th className="titleTableDocuments02">Características</th>
						<th className="titleTableDocuments03">En proceso</th>
						<th className="titleTableDocuments03">Terminado</th>
						{/* <th className="titleTableDocuments03">Acción</th> */}
					</tr>
				</thead>
				<tbody>
					{showData.length > 0 ? (
						showData.map((el) => (
							<ProcessOrderViewTableRow
								key={el.id}
								el={el}
								setProductionProductToEdit={setProductionProductToEdit}
								openModal={openModal}
								deleteProductionProduct={deleteProductionProduct}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
