import React from "react";

const CrudIndicatorsTableRow = ({ el, setProcessToEdit, openModal1 }) => {
	const buttonAction = (el) => {
		setProcessToEdit(el);
		localStorage.setItem("processToIndicator", el.process);
		localStorage.setItem("areaToIndicator", el.area);
		openModal1();
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-procesos1">{el.area}</td>
			<td className="col-tabla-procesos1">{el.process}</td>
			<td className="col-tabla-procesos2">
				<button
					onClick={() => buttonAction(el)}
					className="btn-send-reg-asign2"
				>
					Indicador
				</button>
			</td>
		</tr>
	);
};

export default CrudIndicatorsTableRow;
