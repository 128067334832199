import React from "react";

const ProgressBar = ({ bgcolor, fontcolor, progress, height }) => {
	const Parentdiv = {
		height: height,
		width: "70%",
		backgroundColor: "whitesmoke",
		borderRadius: 5,
		margin: 50,
	};

	const Childdiv = {
		height: "100%",
		width: `${progress}%`,
		backgroundColor: bgcolor,
		borderRadius: 5,
		textAlign: "right",
	};

	const progresstext = {
		padding: 10,
		color: fontcolor,
		fontWeight: 900,
	};

	return (
		<div style={Parentdiv}>
			<div style={Childdiv}>
				<span style={progresstext}>{`${progress}%`}</span>
			</div>
		</div>
	);
};

export default ProgressBar;
