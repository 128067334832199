import { initializeApp } from "@firebase/app";
import React, { useEffect, useState } from "react";
import {
	query,
	where,
	onSnapshot,
	collection,
	getFirestore,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../../config/firebaseConfig";
import Loader from "../../../generalComponents/Loader";
import Message from "../../../generalComponents/Message";
import { TransformationPermitTable } from "./transformationPermitTable";

export const TransformationPermitParticularApp = () => {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [dataBase, setDataBase] = useState(null); //alamacena base de datos de todas las TRANSFORMACIONES pendientes
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		askInventoryTransformation();
	}, []);

	const askInventoryTransformation = () => {
		setLoading(true);
		const nameCollection = "inventoryTransform-" + company;
		const q = query(
			collection(db, nameCollection),
			where("status", "==", "pendiente"),
			where("user", "==", localUser.email)
		);
		onSnapshot(
			q,
			(querySnapshot) => {
				let dataList = querySnapshot.docs.map((d) => ({
					...d.data(),
				}));
				setDataBase(dataList);

				setLoading(false);
			},
			(error) => {
				setDataBase(null);
				setError(error);
				setLoading(false);
			}
		);
		setLoading(false);
	};
	return (
		<div className="conten0">
			<div className="conten1">
				{error && <Message mensaje={error} bgColor="#dc3545" />}
				{loading ? (
					<Loader />
				) : (
					dataBase && (
						<div>
							<article className="titulo11">
								Transformaciones Pendientes por Autorizar
							</article>
							{dataBase.length > 0 ? (
								<TransformationPermitTable transformations={dataBase} />
							) : (
								<div>
									<article className="titulo2">
										No hay Transformaciones pendientes por autorizar...
									</article>
								</div>
							)}
						</div>
					)
				)}
			</div>
		</div>
	);
};
