import React, { useEffect, useState } from "react";
import { initializeApp } from "@firebase/app";
import {
	query,
	where,
	collection,
	getFirestore,
	getDocs,
	updateDoc,
} from "@firebase/firestore";
import { firebaseConfig } from "../../../config/firebaseConfig";
import {
	askDataKeyValue,
	askDataKeyValue2Simple,
	updateCompliance,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import Loader from "../../generalComponents/Loader";

export default function FormResponseAssignments({
	closeModal1,
	activityToEdit,
	setActivityToEdit,
	company,
	process,
	lastStage,
	activitiesLength,
}) {
	const app = initializeApp(firebaseConfig);
	const db = getFirestore(app);

	console.log("recibe porcess: ", process);

	// const responseType = activityToEdit.responseType;
	const initialForm = activityToEdit;
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	// const [closedAnswers, setClosedAnswers] = useState(null);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const [newAmount, setNewAmount] = useState(0);

	// const askClosedAnswers = async () => {
	// 	setLoading(true);
	// 	const nameCollection = "closedAnswers";
	// 	const key = "responseType";
	// 	const value = responseType; //hacer referencia a activityToEdit.responseType
	// 	const response = await askDataKeyValue(nameCollection, key, value);

	// 	if (response.statusResponse === true) {
	// 		setClosedAnswers(response.data);
	// 		setLoading(false);
	// 	} else {
	// 		setLoading(false);
	// 	}
	// };

	useEffect(() => {
		// if (responseType !== "Abierta") {
		// 	askClosedAnswers();
		// }
		setForm(activityToEdit);
	}, []);

	const handleChange = (e) => {
		if (e.target.name === "amount") {
			let aditionAmount = form.amount + parseInt(e.target.value);
			if (aditionAmount > form.totalAmount) {
				alert(
					"No se puede producir cantidad por encima de la orden de producción..."
				);
				return;
			}
			setNewAmount(aditionAmount);

			// setForm({
			// 	...form,
			// 	[e.target.name]: parseInt(e.target.value),
			// });
		} else {
			setForm({
				...form,
				[e.target.name]: e.target.value,
			});
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		setLoading(true);

		if (lastStage) {
			if (activitiesLength === activityToEdit.position) {
				let nProcess = process;
				nProcess.finishProduct = newAmount;
				let resp1 = await askDataKeyValue2Simple(
					"productionProduct-" + company,
					"productionOrder",
					process.productionOrder,
					"refProductionProduct",
					process.refProductionProduct
				);
				let nProductionProduct = resp1.data[0];
				nProductionProduct.finishedProduct = newAmount;
				nProductionProduct.onProcess = nProductionProduct.amount - newAmount;
				updateDocumentById(
					"productionProduct-" + company,
					nProductionProduct.id,
					nProductionProduct
				);
				updateDocumentById("processRegister-" + company, nProcess.id, nProcess);
			}
		}
		const fechita = new Date();
		const actualDate = fechita.toLocaleDateString();
		const actualHour = fechita.toLocaleTimeString();

		form.date = actualDate;
		form.hour = actualHour;
		form.user = localUser.email;
		const nameCollection = "activitiesRegister-" + company;
		let changes = form;
		changes.amount = newAmount;
		if (form.id === null) {
			const q = query(
				collection(db, nameCollection),
				where("activity", "==", form.activity),
				where("stage", "==", form.stage),
				where("processNumber", "==", form.processNumber)
			);
			await getDocs(q).then((querySnapshot) => {
				form.id = querySnapshot.docs[0].id;
				// console.log("res: ", querySnapshot.docs[0].id);
			});
		}
		// console.log("va a actualizar con form: ", form);

		await updateDocumentById(nameCollection, form.id, changes);
		console.log(">>>< va a actualizar el cumplimiento....");
		// await updateCompliance(changes, company);
		setActivityToEdit(null);
		handleReset();
		setLoading(false);
		setLoading(false);
		closeModal1();
	};

	const handleReset = (e) => {
		setForm(initialForm);
		// setActivityToEdit(null);
	};

	const handleClose = (e) => {
		setActivityToEdit(null);
		closeModal1();
	};

	if (activityToEdit) {
		return (
			<div>
				<form onSubmit={handleSubmit} className="form-procesos">
					<h2 className="title2-account">{company}</h2>

					<p className="title2-account">Actividad: {activityToEdit.activity}</p>
					{/* <p className="title2-account">
						Descripción: {activityToEdit.descriptionActivity}
					</p> */}
					{loading ? (
						<Loader />
					) : (
						<div>
							{/* <label className="titulo1">Respuesta:</label> */}
							{/* {closedAnswers ? (
								<div>
									<select
										name="response"
										onChange={handleChange}
										value={form.response}
										className="input0"
									>
										<option defaultValue>Seleccione una Respuesta</option>
										{closedAnswers.map((linea) => (
											<option
												key={linea.responseOption}
												value={linea.responseOption}
											>
												{linea.responseOption}
											</option>
										))}
									</select>
								</div>
							) : (
								<div>
									<input
										type="text"
										name="response"
										placeholder="Respuesta"
										onChange={handleChange}
										value={form.response}
										className="input0"
									/>
								</div>
							)} */}
							{/* <label className="titulo1">Cantidad:</label> */}
							<input
								required
								type="number"
								min="0"
								className="input0"
								name="amount"
								placeholder="Nueva Cantidad"
								onChange={handleChange}
							/>
							<input
								type="text"
								name="comment"
								placeholder="Comentario"
								onChange={handleChange}
								value={form.comment}
								className="input0"
							/>

							<input
								type="submit"
								value="Enviar"
								className="btn-send-reg-asign"
							/>
							<input
								type="reset"
								value="Limpiar"
								onClick={handleReset}
								className="btn-clean-reg-asign"
							/>
							<input
								type="reset"
								value="Cerrar"
								onClick={handleClose}
								className="btn-close-reg-asign"
							/>
						</div>
					)}
				</form>
			</div>
		);
	} else {
		return (
			<div>
				<p>No hay información</p>
			</div>
		);
	}
}
