import React from "react";
import { Link } from "react-router-dom";

const ViewCoursesTableRow = ({ el }) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	// const buttonAction = (el) => {
	// 	setDocumentToEdit(el);
	// 	openModal1();
	// };
	// const buttonAction3 = (el) => {
	// 	console.log(">>>>el:", el);
	// 	setDocumentToEdit(el);
	// 	openModal2();
	// };

	// const buttonAction2 = (el) => {
	// 	setDocumentToDelete(el);
	// 	deleteDocument(el);
	// };

	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.name}</td>
			<td className="colTableDocuments1">{el.description}</td>
			<td className="colTableDocuments2">
				<Link to={"/bpw/presentation-view/" + el.id} target="_blank">
					<button className="btnViewDocuments">Ver</button>
				</Link>
			</td>
		</tr>
	);
};

export default ViewCoursesTableRow;
