import React, { useState } from "react";
import { Link } from "react-router-dom";
// import imagen from "../components/images/logoBPWhorizontal.png";
import imagen from "./images/logoBPW.png";

export default function NavbarBpw3() {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const ModuleKeyProcess = localStorage.getItem("ModuleKeyProcess");
	const ModuleKeyDocuments = localStorage.getItem("ModuleKeyDocuments");
	const ModuleKeyCapacitation = localStorage.getItem("ModuleKeyCapacitation");
	const ModuleKeyEvaluation = localStorage.getItem("ModuleKeyEvaluation");

	const [visibleProduction, setVisibleProduction] = useState(false);
	const [visibleInventroy, setVisibleInventroy] = useState(false);

	function openProduction() {
		setVisibleProduction(true);
	}

	function closeProduction() {
		setVisibleProduction(false);
	}

	function openInventory() {
		setVisibleInventroy(true);
	}

	function closeInventrory() {
		setVisibleInventroy(false);
	}

	return (
		<div className="navbar">
			<div className="div01Navbar">
				<img src={imagen} className="icono" />
			</div>

			{ModuleKeyProcess === "si" && (
				// <div onMouseLeave={closeProduction}>
				// 	<div className="linkNavbar" onMouseEnter={openProduction}>
				// 		xxProducción
				// 	</div>
				// 	{visibleProduction === true && (
				// 		<div className="dropdownMenu">
				// 			{localUser.area === "Producción" && (
				// 				<div className="div01Navbar01">
				// 					<Link to="/production-process-orders" className="linkNavbar">
				// 						Ordenes de Producción
				// 					</Link>
				// 				</div>
				// 			)}
				// 			<div className="div01Navbar01">
				// 				<Link
				// 					to="/supervisor-company/reg_procesos"
				// 					className="linkNavbar"
				// 				>
				// 					Supervisión de Procesos
				// 				</Link>
				// 			</div>
				// 			<div className="div01Navbar01">
				// 				<Link to="/worker-company/assignments" className="linkNavbar">
				// 					Etapas Asignadas
				// 				</Link>
				// 			</div>
				// 			<div className="div01Navbar01">
				// 				<Link to="/inventory-user3" className="linkNavbar">
				// 					Inventarios
				// 				</Link>
				// 			</div>
				// 			<div className="div01Navbar01">
				// 				<Link to="/time-management" className="linkNavbar">
				// 					Tiempos
				// 				</Link>
				// 			</div>
				// 		</div>
				// 	)}
				// </div>
				<div className="div01Navbar">
					<Link to="/process-management" className="linkNavbar">
						Producción
					</Link>
				</div>
			)}
			{(localUser.area === "Producción" || localUser.area === "Gerencia") && (
				<div className="div01Navbar">
					<Link to="/inventory-user3" className="linkNavbar">
						Inventarios
					</Link>
				</div>
			)}

			{ModuleKeyDocuments === "si" && (
				<div className="div01Navbar">
					<Link to="/documents" className="linkNavbar">
						Documentos
					</Link>
				</div>
			)}
			{ModuleKeyCapacitation === "si" && (
				<div className="div01Navbar">
					<Link to="/students_training_main_company" className="linkNavbar">
						Cursos
					</Link>
				</div>
			)}
			<div className="div02Navbar">
				<Link to="/admin_user" className="linkNavbar">
					{localUser.email}
				</Link>
			</div>
			<div className="divCloseNavbar">
				<Link to="/" className="closeButton">
					Cerrar
				</Link>
			</div>
		</div>
	);
}
