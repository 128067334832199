import React, { useEffect, useState } from "react";
import { EvaluationsTableRow } from "./EvaluationsTableRow";

export const EvaluationsTable = ({
	evaluations,
	setEvaluationToEdit,
	openModal1,
	setViewEvaluation,
	company,
}) => {
	const initialForm = {
		area: "Area",
	};
	const [form, setForm] = useState(initialForm);
	const [processFilter, setProcessFilter] = useState(evaluations);

	useEffect(() => {
		setProcessFilter(evaluations);
	}, [evaluations]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setProcessFilter(evaluations);
		}
		setProcessFilter(
			evaluations.filter(
				(item) =>
					item.nameEvaluation.toLowerCase().includes(e.target.value) ||
					item.descriptionEvaluation.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>

			<h3 className="titulo1">Lista de Procesos</h3>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos2">Evaluación</th>
						<th className="titulo-tabla-procesos2">Instrucciones</th>
						<th className="titulo-tabla-procesos1">Tiempo</th>
						<th className="titulo-tabla-procesos1">Acción</th>
					</tr>
				</thead>
				<tbody>
					{processFilter.length > 0 ? (
						processFilter.map((el) => (
							<EvaluationsTableRow
								key={el.id}
								el={el}
								setEvaluationToEdit={setEvaluationToEdit}
								openModal1={openModal1}
								setViewEvaluation={setViewEvaluation}
								company={company}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
