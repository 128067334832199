import React, { useEffect, useState } from "react";
import {
	addData,
	askDataKeyValue,
	askDataKeyValue2Simple,
	askDataSimple,
	deleteDocumentById,
	updateDocumentById,
} from "../../../../../utils/actions/firebase/firebaseActions";
import Loader from "../../../../generalComponents/Loader";

export const InventoryTransferInTable = ({ inventoryTransfer }) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const company = localStorage.getItem("usuariosEmpresa");
	const [loading, setLoading] = useState(false);
	const collectionName = "inventoryGeneral-" + company;
	const collectionName2 = "inventoryTransfer-" + company;
	const collectionName3 = "inventoryInTransit-" + company;
	const collectionName4 = "inventoryParticular-" + company;
	const collectionName5 = "processRegister-" + company;
	const collectionName6 = "productionProduct-" + company;

	const askInventoryInTransit = async (user, processNumber) => {
		setLoading(true);
		let res = null;
		await askDataKeyValue2Simple(
			collectionName3,
			"from",
			user,
			"processNumber",
			processNumber
		).then((result) => {
			res = result.data;
			setLoading(false);
		});
		return res;
	};

	const askInventoryParticular = async (user, processNumber) => {
		setLoading(true);
		let res = null;
		await askDataKeyValue2Simple(
			collectionName4,
			"user",
			user,
			"processNumber",
			processNumber
		).then((result) => {
			setLoading(false);
			res = result.data;
		});
		return res;
	};

	const askInventoryGeneral = async () => {
		setLoading(true);
		let res = null;
		await askDataSimple(collectionName).then((result) => {
			setLoading(false);
			res = result.data;
		});
		return res;
	};

	const askProcessRegister = async (processNumber) => {
		setLoading(true);
		let res = null;
		await askDataKeyValue2Simple(
			collectionName5,
			"company",
			company,
			"processNumber",
			processNumber
		).then((result) => {
			setLoading(false);
			res = result.data;
		});
		return res;
	};

	const recieveAction = async (transfer) => {
		setLoading(true);
		let inventoryInTransit = [];
		inventoryInTransit = await askInventoryInTransit(
			transfer.userFrom,
			transfer.fromProcessNumber
		);
		//modifica el tránsito disminuyendo la cantidad autorizada
		transfer.transferProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario en tránsito
			let iitToModify = inventoryInTransit.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			iitToModify[0].amount = iitToModify[0].amount - trProduct.amount;
			if (iitToModify[0].amount === 0) {
				await deleteDocumentById(collectionName3, iitToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName3,
					iitToModify[0].id,
					iitToModify[0]
				);
			}
		});

		let particularInventory = [];
		particularInventory = await askInventoryParticular(
			transfer.userFrom,
			transfer.fromProcessNumber
		);
		//modifica el inventario particular disminuyendo la cantidad transferida
		transfer.transferProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario particular
			let inventoryToModify = particularInventory.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			inventoryToModify[0].amount =
				inventoryToModify[0].amount - trProduct.amount;
			if (inventoryToModify[0].amount === 0) {
				await deleteDocumentById(collectionName4, inventoryToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName4,
					inventoryToModify[0].id,
					inventoryToModify[0]
				);
			}
		});

		let generalInventory = [];
		generalInventory = await askInventoryGeneral();
		let processRegister = await askProcessRegister(transfer.fromProcessNumber);
		//modifica el inventario General aumentando la cantidad producto de la transferido
		transfer.transferProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario
			let inventoryToModify = generalInventory.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			if (inventoryToModify.length === 0) {
				//crea el item en el inventario
				let newProduct = {
					amount: trProduct.amount,
					cost: 0,
					description: trProduct.description,
					id: null,
					refInventory: trProduct.refInventory,
				};
				addData(collectionName, newProduct);
			} else {
				//aumenta la cantidad del inventario
				inventoryToModify[0].amount =
					inventoryToModify[0].amount + trProduct.amount;
				await updateDocumentById(
					collectionName,
					inventoryToModify[0].id,
					inventoryToModify[0]
				);
			}
			if (processRegister[0].refProductionProduct === trProduct.refInventory) {
				let productionOrder = processRegister[0].productionOrder;
				let productionProduct = await askDataKeyValue2Simple(
					collectionName6,
					"productionOrder",
					productionOrder,
					"refProductionProduct",
					trProduct.refInventory
				);
				//aumenta la cantidad producida en el proceso
				let amountToModify = trProduct.amount;
				if (
					processRegister[0].finishProduct + trProduct.amount <=
					processRegister[0].quantity
				) {
					processRegister[0].finishProduct =
						processRegister[0].finishProduct + trProduct.amount;
				} else {
					amountToModify =
						processRegister[0].quantity - processRegister[0].finishProduct;
					processRegister[0].finishProduct = processRegister[0].quantity;
				}
				await updateDocumentById(
					collectionName5,
					processRegister[0].id,
					processRegister[0]
				);
				//aumenta la cantidad producida en la orden de produccion y disminuye la que está en proceso

				// AQUÍ SE REVENTÓ JAJAJAJA REVISRAR .finshedProduct
				console.log(
					"1. productoTerminado:",
					productionProduct.data[0].finishedProduct
				);

				console.log(
					"1. producto en proceso:",
					productionProduct.data[0].onProcess
				);
				console.log("1. nueva cantidad:", amountToModify);
				productionProduct.data[0].finishedProduct =
					productionProduct.data[0].finishedProduct + amountToModify;
				productionProduct.data[0].onProcess =
					productionProduct.data[0].onProcess - amountToModify;
				console.log(
					"2. productoTerminado:",
					productionProduct.data[0].finishedProduct
				);
				console.log(
					"2. producto en proceso:",
					productionProduct.data[0].onProcess
				);
				await updateDocumentById(
					collectionName6,
					productionProduct.data[0].id,
					productionProduct.data[0]
				);
			}
		});

		// modifica el estatus en la colección de transferencia, pasa de pendiente a aceptado
		let newTransfer = transfer;
		newTransfer.status = "aceptado";
		await updateDocumentById(collectionName2, transfer.id, newTransfer);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	const rejeactAction = async (transfer) => {
		setLoading(true);
		let inventoryInTransit = [];
		inventoryInTransit = await askInventoryInTransit(
			transfer.userFrom,
			transfer.fromProcessNumber
		);
		//modifica el tránsito disminuyendo la cantidad no recibida
		transfer.transferProducts.map(async (trProduct) => {
			// aqui debe actualizar la cantidad de inventario en tránsito
			let iitToModify = inventoryInTransit.filter(
				(item) => item.refInventory === trProduct.refInventory
			);
			iitToModify[0].amount = iitToModify[0].amount - trProduct.amount;
			if (iitToModify[0].amount === 0) {
				await deleteDocumentById(collectionName3, iitToModify[0].id);
			} else {
				await updateDocumentById(
					collectionName3,
					iitToModify[0].id,
					iitToModify[0]
				);
			}
		});
		// modifica el estatus en la colección de transferencias, pasa de pendiente a rechazado
		let newTransfer = transfer;
		newTransfer.status = "rechazado";
		await updateDocumentById(collectionName2, transfer.id, newTransfer);
		setTimeout(function () {
			setLoading(false);
		}, 1000);
	};

	return (
		<div className="containerInventory04">
			{loading ? (
				<Loader />
			) : (
				<table className="tabla-process">
					<thead>
						<tr className="fila-tabla-inventory">
							<th className="titulo-tabla-inventory">Fecha</th>
							<th className="titulo-tabla-inventory">Origen</th>
							{/* <th className="titulo-tabla-inventory">Orden</th> */}
							<th className="titulo-tabla-inventory">Usuario</th>
							<th className="titulo-tabla-inventory">Productos</th>
							<th className="titulo-tabla-inventory">Acción</th>
						</tr>
					</thead>

					<tbody>
						{inventoryTransfer.map((transfer, index) => (
							<tr key={index} className="fila-tabla-cuenstas">
								<td className="col-tabla-inventory1">{transfer.date}</td>
								<td className="col-tabla-inventory1">
									{transfer.fromProcessNumber
										? transfer.fromProcessNumber
										: "Almacén General"}
								</td>
								{/* <td className="col-tabla-inventory1">
									{transfer.productionOrder}
								</td> */}
								<td className="col-tabla-inventory2">{transfer.from}</td>
								<td className="col-tabla-inventory2">
									{transfer.transferProducts.map((product) => (
										<h3 className="titulo4">
											{product.amount} - {product.description}
										</h3>
									))}
								</td>
								<td className="col-tabla-inventory2">
									<button
										onClick={() => recieveAction(transfer)}
										className="btn-show-stage"
									>
										Recibir
									</button>
									<button
										onClick={() => rejeactAction(transfer)}
										className="btn-delete-process"
									>
										Rechazar
									</button>
								</td>
							</tr>
						))}
					</tbody>
				</table>
			)}
		</div>
	);
};
