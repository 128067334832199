import React, { useState, useEffect } from "react";
import {
	askDataKeyValue,
	askDataKeyValueOrder,
} from "../../../utils/actions/firebase/firebaseActions";
import NavbarBpw3 from "../../generalComponents/NavbarBpw3";
import Loader from "../../generalComponents/Loader";
import DetalleRegProcesosEtapa from "./DetalleRegProcesosEtapa";
import NavbarBpw from "../../generalComponents/NavbarBpw";
import { useModal } from "../../generalComponents/hooks/useModal";
import Modal from "../../generalComponents/Modal";
import NavbarBpw2 from "../../generalComponents/NavbarBpw2";

const DetalleRegProcesos = () => {
	const [loading, setLoading] = useState(false);
	const [assignments, setAssignments] = useState([]);
	const [error, setError] = useState(null);
	const [refresh, setRefresh] = useState(false);
	const [productionProduct, setProductionProduct] = useState(null);

	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const usuario = localUser.email;
	const regProceso = localStorage.getItem("regProceso");
	const proceso = localStorage.getItem("proceso");
	let totalRegProcess = JSON.parse(localStorage.getItem("totalRegProcess"));

	const [isOpenModal1, openModal1, closeModal1] = useModal(false);

	// console.log("> > > totalRegProcess:", totalRegProcess);

	const askAssignments = async () => {
		setLoading(true);
		const nameCollection = "assignments-" + company;
		const key2 = "processNumber";
		const value2 = regProceso;
		const response = await askDataKeyValueOrder(nameCollection, key2, value2);
		if (response.statusResponse === true) {
			setAssignments(response.data);
			setLoading(false);
		} else {
			setAssignments([]);
			setError(response.error);
			console.log(response.error);
			setLoading(false);
		}
	};

	const askProductionProduct = async () => {
		setLoading(true);
		const nameCollection = "productionProduct-" + company;
		const key = "productionOrder";
		const value = totalRegProcess.productionOrder;
		const response = await askDataKeyValue(nameCollection, key, value);
		if (response.statusResponse === true) {
			setProductionProduct(response.data[0]);
			setLoading(false);
		} else {
			setProductionProduct(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askAssignments();
		if (totalRegProcess.productionOrder) {
			askProductionProduct();
		}
		setRefresh(false);
	}, [refresh]);

	const openQuantity = () => {
		openModal1();
	};
	return (
		<div>
			{localUser.type === "1" ? (
				<NavbarBpw />
			) : localUser.type === "2" ? (
				<NavbarBpw2 />
			) : (
				<NavbarBpw3 />
			)}
			<h3 className="titleProcess">DETALLE PROCESO ({company})</h3>
			{/* <p>Aquí va a verse el detalle del proceso seleccionado</p> */}
			{proceso && regProceso && (
				<h3 className="titulo3">
					Proceso: {proceso} ( {regProceso} )
				</h3>
			)}
			{totalRegProcess && totalRegProcess.refProductionProduct && (
				<div>
					<Modal isOpen={isOpenModal1} closeModal={closeModal1}></Modal>

					<article
						className={
							totalRegProcess.quantity == totalRegProcess.finishProduct
								? "titulo12"
								: "titulo13"
						}
					>
						Producto: {productionProduct && productionProduct.description} ---
						Cantidad a Producir: {totalRegProcess.quantity} --- Cantidad
						Producida: {totalRegProcess.finishProduct}
						{/* <button onClick={openQuantity} className="btn-editar-etapa">
							Modificar
						</button> */}
					</article>
				</div>
			)}
			<hr />
			{loading === true && <Loader />}
			<table className="bloque2">
				<thead className="bloque2">
					<tr>
						<th className="col30p">Actividad</th>
						<th className="col20p">Asignación</th>
						<th className="col10p">Avance</th>
						<th className="col10p">%</th>
						<th className="col20p">Comentario</th>
						<th className="col10p">Tiempo [HH:MM]</th>
					</tr>
				</thead>
				<tbody className="bloque2">
					<tr className={"amountPartial"}>
						<td className="col20p"></td>
						<td className="col10p"></td>
						<td className="col10p"></td>
						<td className="col10p"></td>
						<td className="col20p"></td>
						<td className="col10p"></td>
					</tr>
				</tbody>
			</table>
			{assignments.map((assignment) => (
				<DetalleRegProcesosEtapa
					key={assignment.id}
					assignment={assignment}
					localUser={localUser}
					setRefresh={setRefresh}
					company={company}
				/>
			))}
		</div>
	);
};

export default DetalleRegProcesos;
