import React from "react";

export const CoursesTableRow = ({
	el,
	setCourseToEdit,
	deleteCourse,
	openModal1,
}) => {
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	const buttonAction = (el) => {
		setCourseToEdit(el);
		openModal1();
	};

	const buttonAction2 = (el) => {
		deleteCourse(el);
	};

	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.nameCourse}</td>
			<td className="colTableDocuments1">{el.descriptionCourse}</td>
			<td className="colTableDocuments1">
				{el.materialCourse.map((material) => {
					return (
						<div className="" key={material}>
							<p className="">* {material}</p>
						</div>
					);
				})}
			</td>
			<td className="colTableDocuments1">{el.nameEvaluation}</td>
			{localUser.type === "1" && (
				<td className="colTableDocuments1">
					{el.autorizedCompanies.map((autorized) => {
						return (
							<div className="" key={autorized}>
								<p className="">* {autorized}</p>
							</div>
						);
					})}
				</td>
			)}

			<td className="colTableDocuments1">{el.date}</td>

			<td className="colTableDocuments2">
				<button onClick={() => buttonAction(el)} className="btnEditDocuments">
					Editar
				</button>

				<button
					onClick={() => buttonAction2(el)}
					className="btnDeleteDocuments"
				>
					Borrar
				</button>
			</td>
		</tr>
	);
};
