import React from "react";

export const InventoriesTableRow = ({
	el,
	setInventoryToEdit,
	openModal,
	deleteInventory,
}) => {
	const editButton = (el) => {
		setInventoryToEdit(el);
		openModal();
	};

	const deleteButton = (el) => {
		deleteInventory(el);
	};
	return (
		<tr className="fila-tabla-cuenstas">
			{/* <td className="colTableDocuments0">{el.area}</td> */}
			<td className="colTableDocuments1">{el.refInventory}</td>
			<td className="colTableDocuments1">{el.description}</td>
			<td className="colTableDocuments1">{el.features}</td>
			<td className="colTableDocuments1">{el.measureUnit}</td>
			{/* <td className="colTableDocuments2">
				<button onClick={() => editButton(el)} className="btnEditDocuments">
					Editar
				</button>

				<button onClick={() => deleteButton(el)} className="btnDeleteDocuments">
					Borrar
				</button>
			</td> */}
		</tr>
	);
};
