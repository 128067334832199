import React from "react";
import CrudActivitiesAdminTableRow from "./CrudActivitiesAdminTableRow";

const CrudActivitiesAdminTable = ({
	activities,
	setActivityToEdit,
	openModal1,
	downPos,
	upPos,
	setRefresh,
	company,
}) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Actividades</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-etapas1">#</th>
						<th className="titulo-tabla-etapas1">Tarea</th>
						<th className="titulo-tabla-etapas1">Descripcion</th>
						<th className="titulo-tabla-etapas1">Tipo Respuesta</th>
						<th className="titulo-tabla-etapas1">Acción</th>
						<th className="titulo-tabla-etapas1">Posición</th>
					</tr>
				</thead>
				<tbody>
					{activities.length > 0 ? (
						activities.map((el) => (
							<CrudActivitiesAdminTableRow
								key={el.id}
								el={el}
								setActivityToEdit={setActivityToEdit}
								openModal1={openModal1}
								totalActivities={activities.length}
								downPos={downPos}
								upPos={upPos}
								setRefresh={setRefresh}
								company={company}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudActivitiesAdminTable;
