import React from "react";
import Adjust from "@material-ui/icons/Adjust";
import AddCircle from "@material-ui/icons/AddCircle";
import {
	askDataKeyValue,
	deleteDocumentById,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";

const CrudProcessAdminTableRow = ({
	el,
	setProcessToEdit,
	openModal1,
	setGoStages,
	company,
}) => {
	const buttonAction = (el) => {
		setProcessToEdit(el);
		openModal1();
	};
	const goStages = () => {
		localStorage.setItem("procesoEditar", el.process);
		localStorage.setItem("areaProcesoEditar", el.area);
		localStorage.setItem("processRelease", el.release);
		setGoStages(true);
	};

	const processRelease = async (el) => {
		let isRelease = window.confirm(`¿Está segur@ de Liberar este Proceso?`);
		if (isRelease) {
			let process = el;
			process.release = "si";
			process.active = "si";
			const resp1 = await askDataKeyValue(
				"activities-" + company,
				"process",
				process.process
			);
			let activitiesList = resp1.data;
			let sum = 0;
			for (const activity of activitiesList) {
				if (activity.responseType === "Cumple-NoCumple") {
					sum = sum + 1;
				}
			}
			if (sum === activitiesList.length) {
				process.processWithIndicator = "si";
				updateDocumentById("process-" + company, process.id, process);
			} else {
				process.processWithIndicator = "no";
				updateDocumentById("process-" + company, process.id, process);
			}
		} else {
			return;
		}
	};

	const processUnRelease = async (el) => {
		let isUnRelease = window.confirm(`¿Está segur@ de Retener este Proceso?`);
		if (isUnRelease) {
			let process = el;
			process.release = "no";
			process.active = "no";
			updateDocumentById("process-" + company, process.id, process);
		} else {
			return;
		}
	};

	const processDelete = async (el) => {
		let isDelete = window.confirm(
			`¿Está segur@ de Borrar este Proceso? esto borrará toda información relacionada con el proceso seleccionado, etapas, actividades y registros hechos con este proceso...`
		);

		if (isDelete) {
			let isDeleteConfirmation = window.confirm(
				"¿C o m p l e t a m e n t e   s e g u r @ ?"
			);
			if (isDeleteConfirmation) {
				let process = el;
				const resp1 = await askDataKeyValue(
					"activities-" + company,
					"process",
					process.process
				);
				let activitiesList = resp1.data;

				for (const activity of activitiesList) {
					deleteDocumentById("activities-" + company, activity.id);
				}
				const resp2 = await askDataKeyValue(
					"activitiesRegister-" + company,
					"process",
					process.process
				);
				let activitiesList2 = resp2.data;

				for (const activity of activitiesList2) {
					deleteDocumentById("activitiesRegister-" + company, activity.id);
				}
				const resp3 = await askDataKeyValue(
					"stages-" + company,
					"process",
					process.process
				);
				let stagesList = resp3.data;
				for (const stage of stagesList) {
					deleteDocumentById("stages-" + company, stage.id);
				}
				const resp4 = await askDataKeyValue(
					"assignments-" + company,
					"process",
					process.process
				);
				let stagesList2 = resp4.data;
				for (const stage of stagesList2) {
					deleteDocumentById("assignments-" + company, stage.id);
				}
				const resp5 = await askDataKeyValue(
					"processRegister-" + company,
					"process",
					process.process
				);
				for (const processReg of resp5.data) {
					deleteDocumentById("processRegister-" + company, processReg.id);
				}
				deleteDocumentById("process-" + company, process.id);
			} else {
				return;
			}
		} else {
			return;
		}
	};

	const changeActive = async (el) => {
		let isRelease = window.confirm(
			`¿Está segur@ de cambiar el estado de "Activo" ?`
		);
		if (isRelease) {
			let process = el;
			console.log("process:", process);
			if (process.active === "si") {
				process.active = "no";
			} else {
				process.active = "si";
			}
			updateDocumentById("process-" + company, process.id, process);
		} else {
			return;
		}
	};

	return (
		<tr className="fila-tabla-cuenstas">
			<td className="col-tabla-procesos1">{el.area}</td>
			<td className="col-tabla-procesos1">{el.process}</td>
			<td className="col-tabla-procesos1">{el.descriptionProcess}</td>
			<td className="col-tabla-procesos2">
				<div className="contActions01">
					<button className="btn-show-stage" onClick={() => goStages()}>
						Ver Etapas
					</button>
					{el.release === "no" ? (
						<div>
							<button
								onClick={() => buttonAction(el)}
								className="btn-editar-procesos"
							>
								Editar
							</button>
							<button
								className="btn-release-process"
								onClick={() => processRelease(el)}
							>
								Liberar
							</button>
						</div>
					) : (
						<div>
							<button
								className="btn-unrelease-process"
								onClick={() => processUnRelease(el)}
							>
								Retener
							</button>
						</div>
					)}
					<button
						className="btn-delete-process"
						onClick={() => processDelete(el)}
					>
						Borrar Proceso
					</button>
				</div>
			</td>
			<td className="col-tabla-procesos1">
				<div className="contActions01">
					{el.active === "si" ? (
						<p className="sino">SI</p>
					) : (
						<p className="sino">NO</p>
					)}
					{el.active === "si" ? (
						<button className="btnActive" onClick={() => changeActive(el)}>
							<Adjust />
						</button>
					) : (
						<button className="btnInActive" onClick={() => changeActive(el)}>
							<AddCircle />
						</button>
					)}
				</div>
			</td>
		</tr>
	);
};

export default CrudProcessAdminTableRow;
