import React, { useEffect, useState } from "react";
import { StudentsHistorialTableRow } from "./StudentsHistorialTableRow";

export const StudentsHistorialTable = ({ studentCourses }) => {
	const [data, setData] = useState(studentCourses);
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);

	useEffect(() => {
		setData(studentCourses);
	}, [studentCourses]);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setData(studentCourses);
		}
		setData(
			studentCourses.filter(
				(item) =>
					item.course.toLowerCase().includes(e.target.value) ||
					item.status.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			{/* {console.log("data", data)} */}
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<h3 className="titulo1">LISTADO DE CURSOS DEL ESTUDIANTE</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						{/* <th className="titleTableDocuments01">Area</th> */}
						<th className="titleTableDocuments02">Curso</th>
						<th className="titleTableDocuments03">Status</th>
						<th className="titleTableDocuments03">Calificación</th>
						<th className="titleTableDocuments03">Desde</th>
						<th className="titleTableDocuments03">Hasta</th>
						<th className="titleTableDocuments03">Terminado en:</th>
					</tr>
				</thead>
				<tbody>
					{data.length > 0 ? (
						data.map((el) => <StudentsHistorialTableRow key={el.id} el={el} />)
					) : (
						<tr>
							<td colSpan="3">No tiene historial de Cursos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
