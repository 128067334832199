import React, { useEffect, useState } from "react";
import RegProcessAdminTableRow from "./RegProcessAdminTableRow";

const RegProcessAdminTable = ({ regProcess, irVerDetalle, company }) => {
	function sumarDias(fecha, dias) {
		fecha.setDate(fecha.getDate() + dias);
		return fecha;
	}

	const actualDate = new Date();
	const pastDate = sumarDias(actualDate, -8);
	// const pastDateStr = pastDate.toLocaleDateString();
	const initialForm = {
		status: "Todas",
		initialDate: "",
		finalDate: "",
	};
	const [form, setForm] = useState(initialForm);
	const [regProcessFilter, setRegProcessFilter] = useState(regProcess);
	const [regProcessFilter2, setRegProcessFilter2] = useState(regProcess);
	const db2 = [{ status: "abierto" }, { status: "cerrado" }];
	let counter = 0;

	useEffect(() => {
		const processInitial = regProcess.filter(
			(proceso) => proceso.date.seconds >= pastDate / 1000
		);
		setRegProcessFilter(processInitial);
		setRegProcessFilter2(processInitial);
		counter = 0;
	}, [regProcess]);

	const handleChange = (e) => {
		setForm({
			...form,
			[e.target.name]: e.target.value,
		});
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (form.initialDate === "" || form.finalDate === "") {
			alert("debe seleccionar rango de fechas para filtrar...");
			return;
		}
		let process1 = [];
		if (form.status === "Todas") {
			process1 = regProcess;
		} else {
			process1 = regProcess.filter((proceso) => proceso.status === form.status);
		}
		// console.log("fecha inicial:", Date.parse(form.initialDate));
		const process2 = process1.filter(
			// (proceso) => proceso.date.seconds >= Date.parse(form.initialDate) / 1000
			(proceso) => proceso.date.seconds >= new Date(form.initialDate) / 1000
		);
		const process3 = process2.filter(
			(proceso) => proceso.date.seconds <= new Date(form.finalDate) / 1000
		);
		setRegProcessFilter(process3);
		setRegProcessFilter2(process3);
		// if (form.status !== "Status del Proceso") {
		// 	setRegProcessFilter(
		// 		regProcess.filter((proceso) => proceso.status === form.status)
		// 	);
		// } else {
		// 	setRegProcessFilter(regProcess);
		// }
	};

	const handleReset = (e) => {
		const processInitial = regProcess.filter(
			(proceso) => proceso.date.seconds >= pastDate / 1000
		);
		// setRegProcessFilter(regProcess);
		setRegProcessFilter(processInitial);
		setRegProcessFilter2(processInitial);
		setForm(initialForm);
	};

	function handleSearch(e) {
		if (e.target.value === "") {
			return setRegProcessFilter2(regProcessFilter);
		}
		setRegProcessFilter2(
			regProcessFilter.filter(
				(item) =>
					item.process.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.area.toLowerCase().includes(e.target.value) ||
					item.status.toLowerCase().includes(e.target.value)
			)
		);
	}

	return (
		<div>
			<div>
				{regProcessFilter.length > 0 && (
					<div>
						<h3 className="titulo3">
							Area: {regProcess[0].area} ____ Sucursal: {regProcess[0].agency}
						</h3>
					</div>
				)}

				<form onSubmit={handleSubmit} className="filter-procesos">
					{form.initialDate === "" && (
						<div>
							<p className="alertaFormulario">
								Por defecto se muestran procesos de los últimos 7 días
							</p>
						</div>
					)}
					<label className="labelForm1">Fecha Inicial</label>
					<input
						type="date"
						name="initialDate"
						placeholder="Fecha Inicial"
						onChange={handleChange}
						value={form.initialDate}
						className="input3"
					/>
					<label className="labelForm1">Fecha Final</label>
					<input
						type="date"
						name="finalDate"
						placeholder="Fecha final"
						onChange={handleChange}
						value={form.finalDate}
						className="input3"
					/>
					{/* <hr /> */}
					<label className="labelForm1">Estatus del Proceso</label>
					<select
						name="status"
						onChange={handleChange}
						value={form.status}
						className="input3"
					>
						{/* <option defaultValue>Status del Proceso</option> */}
						<option defaultValue>Todas</option>
						{db2.map((line) => (
							<option key={line.status} value={line.status}>
								{line.status}
							</option>
						))}
					</select>
					<input
						type="submit"
						value="Filtrar"
						className="btn-filter-procesos"
					/>
					<input
						type="reset"
						value="Limpiar"
						onClick={handleReset}
						className="btnCleanFilterProcess"
					/>
				</form>

				<div className="document-search">
					<h2 className="title-search">Buscar</h2>
					<input
						type="search"
						name=""
						id=""
						onChange={handleSearch}
						className="search-box"
					/>
				</div>

				<h3 className="titulo1">
					Lista Registro de Procesos (Total:{regProcessFilter2.length})
				</h3>
			</div>

			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos">No.</th>
						<th className="titulo-tabla-procesos">Fecha</th>
						{/* <th className="titulo-tabla-procesos">Hora</th> */}
						<th className="titulo-tabla-procesos"># Proceso</th>
						<th className="titulo-tabla-procesos">Nombre Proceso</th>
						<th className="titulo-tabla-procesos">Area</th>
						<th className="titulo-tabla-procesos">Estado</th>
						{/* <th className="titulo-tabla-procesos">Cambio</th> */}
						<th className="titulo-tabla-procesos">Cumplimiento</th>
						<th className="titulo-tabla-procesos">Avance</th>
						<th className="titulo-tabla-procesos">Acción</th>
					</tr>
				</thead>
				<tbody>
					{regProcessFilter.length > 0 ? (
						regProcessFilter2.map(
							(el) => (
								(counter = counter + 1),
								(
									<RegProcessAdminTableRow
										key={el.id}
										el={el}
										irVerDetalle={irVerDetalle}
										counter={counter}
										company={company}
									/>
								)
							)
						)
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default RegProcessAdminTable;
