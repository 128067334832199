import React, { useEffect, useState } from "react";
import { ViewAnswers } from "./ViewAnswers";

export const DetailQuestion = ({ question, setQuestionToEdit, openModal1 }) => {
	const [showAnswers, setShowAnswers] = useState(false);

	const clickEdit = () => {
		setQuestionToEdit(question);
		openModal1();
	};

	const answersShow = () => {
		setShowAnswers(true);
	};

	const hiddeAnswers = () => {
		setShowAnswers(false);
	};

	return (
		<div>
			<div className="containerQuestion">
				<table>
					<tbody>
						<tr>
							<td className="questionCol01">{question.question}</td>
							<td className="questionCol02">
								<button onClick={clickEdit} className="buttonEditQuestion">
									<p>Editar Preguntas</p>
								</button>
							</td>
							<td className="questionCol02">
								{showAnswers === true ? (
									<button
										onClick={hiddeAnswers}
										className="btnVisibleAnswerOff"
									>
										{/* <VisibilityOffIcon style={{ fontSize: 20 }} /> */}
										Ocultar Respuestas
									</button>
								) : (
									<button onClick={answersShow} className="btnVisibleAnswerOn">
										{/* <VisibilityIcon style={{ fontSize: 20 }} /> */}
										Ver Respuestas
									</button>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			{showAnswers && <ViewAnswers question={question.question} />}
		</div>
	);
};
