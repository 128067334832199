import axios from "axios";

export const verifyEmailAndPasswordMsql = async (correo, contrasena) => {
	const result = { statusResponse: true, error: null, datoUsuaro: {} };
	try {
		const obj = { correo, contrasena };
		const res = await axios.post("https://apibpw.pruebas.com.co/usuarios", obj);
		const algo = res.data[0];

		if (algo.correo !== obj.correo) {
			result.statusResponse = false;
			result.error = "no hay coincidencias...";
		} else {
			result.datoUsuaro = algo;
			// await AsyncStorage.setItem('user', JSON.stringify(algo))
			// console.log("---en teoría grabó el usuario...");
		}
	} catch (error) {
		result.statusResponse = false;
		result.error = "Usuario ó Contraseña no válidos";
	}
	return result;
};

export const askCuentas = async () => {
	const result = { statusResponse: true, error: null, datosCuentas: null };
	try {
		const res = await axios.post("https://apibpw.pruebas.com.co/askaccounts");
		// console.log("en ask cuentas la respuesta es", res.data);
		result.datosCuentas = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo conectar con la Base de Datos de Empresas";
	}
	// console.log("en result para entregar hay: ", result);
	return result;
};

const fechita = new Date();
// const year = fechita.getFullYear();
// const mes = fechita.getMonth() + 1;
// const dia = fechita.getDate();
const fechaActual = fechita.toLocaleDateString();
const horaActual = fechita.toLocaleTimeString();

// export const addCuenta = async (form) => {
// 	// console.log("empezó la función  adicionar, recibe", form);
// 	const result = { statusResponse: true, error: null, data: null };
// 	try {
// 		const obj = {
// 			empresa: form.empresa,
// 			fecha: fechaActual,
// 			tipoId: form.tipoId,
// 			numeroId: form.numeroId,
// 			pais: form.pais,
// 			ciudad: form.ciudad,
// 			direccion: form.direccion,
// 			email: form.email,
// 			telefono: form.telefono,
// 			nomContacto: form.nomContacto,
// 		};
// 		// console.log("la empresa que se enviará al la base de datos es: ", obj);
// 		const res = await axios.post(
// 			"https://apibpw.pruebas.com.co/addempresa",
// 			obj
// 		);
// 		result.data = res;
// 		// console.log("la respuesta de adCuentas es:", res);
// 	} catch (error) {
// 		result.error =
// 			"Error 404: No se pudo acceder a la base de datos de Empresas ";
// 		result.statusResponse = false;
// 	}
// 	return result;
// };

export const updateCuenta = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		// console.log("--2-- en upadateData la data es:", data);
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateempresa",
			obj
		);
		// console.log("**3*+ la respuesta es:", res);
	} catch (error) {
		result.error = error;
		result.statusResponse = false;
	}
	return result;
};

export const askSucursales = async (empresa) => {
	const result = { statusResponse: true, error: null, datosSucursales: null };
	const obj = { empresa: empresa };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/asksucursales",
			obj
		);
		result.datosSucursales = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Sucursales";
	}
	return result;
};

export const addSucursal = async (datos) => {
	// console.log("empezó la función  adicionar sucursal y recibe", datos);
	const result = { statusResponse: true, error: null };
	try {
		// const obj = {
		// 	empresa: datos.empresa,
		// 	sucursal: datos.sucursal,
		// };
		console.log("la sucursal que se enviará al la base de datos es: ", datos);
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/addsucursal",
			datos
		);
		console.log("la respuesta de addSucursal es:", res);
	} catch (error) {
		result.error = error;
		result.statusResponse = false;
	}
	return result;
};

export const actualizaSucursal = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		// console.log("--2-- en upadateData la data es:", data);
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updatesucursal",
			obj
		);
		// console.log("**3*+ la respuesta es:", res);
	} catch (error) {
		result.error = error;
		result.statusResponse = false;
	}
	return result;
};

export const askUsuarios = async (empresa) => {
	const result = { statusResponse: true, error: null, datosUsuarios: null };
	const obj = { empresa: empresa };
	try {
		const res = await axios.post("https://apibpw.pruebas.com.co/askusers", obj);
		// console.log("en ask users la respuesta es", res.data);
		result.datosUsuarios = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Usuarios";
	}
	// console.log("en result para entregar hay: ", result);
	return result;
};

export const addUsuario = async (datos) => {
	const result = { statusResponse: true, error: null };
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/askunuser",
			datos
		);
		if (res1.data[0]) {
			result.statusResponse = false;
			result.error = "El usuario ya existe";
		} else {
			try {
				const res = await axios.post(
					"https://apibpw.pruebas.com.co/adduser",
					datos
				);
			} catch (error) {
				result.error = error;
				result.statusResponse = false;
			}
		}
	} catch (error) {
		result.error = "Error 404. No se pueo comunicar con la base de Usuarios";
		result.statusResponse = false;
	}

	return result;
};

export const actualizaUsuario = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateuser",
			obj
		);
	} catch (error) {
		result.error = error;
		result.statusResponse = false;
	}
	return result;
};

export const borrarUsuario = async (id) => {
	const result = { statusResponse: true, error: null };
	const obj = { id: id };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/deleteuser",
			obj
		);
	} catch (error) {
		result.error = error;
		result.statusResponse = false;
	}
	return result;
};

export const askProcesos = async (empresa) => {
	const result = { statusResponse: true, error: null, datosProcesos: null };
	const obj = { empresa: empresa };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askprocesos",
			obj
		);
		// console.log("en ask procesos la respuesta es", res.data);
		result.datosProcesos = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Procesos";
	}
	// console.log("en result para entregar hay: ", result);
	return result;
};

export const addProceso = async (datos) => {
	const result = { statusResponse: true, error: null };
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/askunproceso",
			datos
		);
		if (res1.data[0]) {
			result.statusResponse = false;
			result.error = "El Proceso ya existe, debe crearlo con un nuevo nombre";
		} else {
			try {
				const res = await axios.post(
					"https://apibpw.pruebas.com.co/addproceso",
					datos
				);
			} catch (error) {
				result.error =
					"Error 404: No se pudo acceder a la base de datos de Procesos";
				result.statusResponse = false;
			}
		}
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Procesos";
		result.statusResponse = false;
	}

	return result;
};

export const actualizaProceso = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateproceso",
			obj
		);
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Procesos";
		result.statusResponse = false;
	}
	return result;
};

export const askEtapas = async (empresa, proceso) => {
	const result = { statusResponse: true, error: null, datosProcesos: null };
	const obj = { empresa: empresa, proceso: proceso };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/asketapas",
			obj
		);
		// console.log("en ask Etapas la respuesta es", res.data);
		result.datosProcesos = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error = "Error 404: No se pudo acceder a la base de datos de Etapas";
	}
	// console.log("en result para entregar hay: ", result);
	return result;
};

export const addEtapa = async (datos) => {
	// console.log("recibe data:", datos);
	const result = { statusResponse: true, error: null };
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/askunaetapa",
			datos
		);
		if (res1.data[0]) {
			result.statusResponse = false;
			result.error = "La etapa ya existe, debe crearla con un nuevo nombre";
		} else {
			try {
				const res = await axios.post(
					"https://apibpw.pruebas.com.co/addetapa",
					datos
				);
			} catch (error) {
				result.error =
					"Error 404: No se pudo acceder a la base de datos de Etapas";
				result.statusResponse = false;
			}
		}
	} catch (error) {
		result.error = "Error 404: No se pudo acceder a la base de datos de Etapas";
		result.statusResponse = false;
	}

	return result;
};

export const actualizaEtapa = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateetapa",
			obj
		);
	} catch (error) {
		result.error = "Error 404: No se pudo acceder a la base de datos de Etapas";
		result.statusResponse = false;
	}
	return result;
};

export const bajarPosition = async (data) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.posEtapa;
	const pos2 = pos1 + 1;
	const id1 = data.id;
	let id2 = 0;
	const obj = { data: data, posEtapa: pos2 };
	let data2 = {};
	let data1 = data;
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/asksiguienteetapa",
			obj
		);
		data2 = res1.data[0];
		data2.posEtapa = pos1;
		id2 = data2.id;
		data2.id = id1;
		data1.posEtapa = pos2;
		data1.id = id2;
		const sube = { data: data2, id: id1 };
		const baja = { data: data1, id: id2 };
		// console.log("** lo que sube", sube);
		// console.log("+++ lo que baja:", baja);
		const res2 = await axios.post(
			"https://apibpw.pruebas.com.co/updateetapa",
			baja
		);
		const res3 = await axios.post(
			"https://apibpw.pruebas.com.co/updateetapa",
			sube
		);
	} catch (error) {
		result.error = "Error 404: No se pudo acceder a la base de datos de Etapas";
		result.statusResponse = false;
	}
	return result;
};

export const subirPosition = async (data) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.posEtapa;
	const pos2 = pos1 - 1;
	const id1 = data.id;
	let id2 = 0;
	const obj = { data: data, posEtapa: pos2 };
	let data2 = {};
	let data1 = data;
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.coo/asksiguienteetapa",
			obj
		);
		data2 = res1.data[0];
		data2.posEtapa = pos1;
		id2 = data2.id;
		data2.id = id1;
		data1.posEtapa = pos2;
		data1.id = id2;
		const sube = { data: data2, id: id1 };
		const baja = { data: data1, id: id2 };
		// console.log("** lo que sube", sube);
		// console.log("+++ lo que baja:", baja);
		const res2 = await axios.post(
			"https://apibpw.pruebas.com.co/updateetapa",
			baja
		);
		const res3 = await axios.post(
			"https://apibpw.pruebas.com.co/updateetapa",
			sube
		);
	} catch (error) {
		result.error = "Error 404: No se pudo acceder a la base de datos de Etapas";
		result.statusResponse = false;
	}
	return result;
};

export const askActividades = async (empresa, proceso, etapa) => {
	const result = { statusResponse: true, error: null, datosActividades: null };
	const obj = { empresa: empresa, proceso: proceso, etapa: etapa };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askactividades",
			obj
		);
		console.log("en ask Actividades la respuesta es", res.data);
		result.datosActividades = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
	}
	return result;
};

export const addActividad = async (datos) => {
	console.log("recibe data:", datos);
	const result = { statusResponse: true, error: null };
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/askunaactividad",
			datos
		);
		if (res1.data[0]) {
			result.statusResponse = false;
			result.error = "La actividad ya existe, debe crearla con un nuevo nombre";
		} else {
			try {
				const res = await axios.post(
					"https://apibpw.pruebas.com.co/addactividad",
					datos
				);
			} catch (error) {
				result.error =
					"Error 404: No se pudo acceder a la base de datos de Actividades";
				result.statusResponse = false;
			}
		}
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
		result.statusResponse = false;
	}
	return result;
};

export const actualizaActivdad = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateactividad",
			obj
		);
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
		result.statusResponse = false;
	}
	return result;
};

export const bajarPosActividad = async (data) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.posTarea;
	const pos2 = pos1 + 1;
	const id1 = data.id;
	let id2 = 0;
	const obj = { data: data, posTarea: pos2 };
	let data2 = {};
	let data1 = data;
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/asksiguienteactividad",
			obj
		);
		data2 = res1.data[0];
		data2.posTarea = pos1;
		id2 = data2.id;
		data2.id = id1;
		data1.posTarea = pos2;
		data1.id = id2;
		const sube = { data: data2, id: id1 };
		const baja = { data: data1, id: id2 };
		// console.log("** lo que sube", sube);
		// console.log("+++ lo que baja:", baja);
		const res2 = await axios.post(
			"https://apibpw.pruebas.com.co/updateactividad",
			baja
		);
		const res3 = await axios.post(
			"https://apibpw.pruebas.com.co/updateactividad",
			sube
		);
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
		result.statusResponse = false;
	}
	return result;
};

export const subirPosActividad = async (data) => {
	const result = { statusResponse: true, error: null };
	const pos1 = data.posTarea;
	const pos2 = pos1 - 1;
	const id1 = data.id;
	let id2 = 0;
	const obj = { data: data, posTarea: pos2 };
	let data2 = {};
	let data1 = data;
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/asksiguienteactividad",
			obj
		);
		data2 = res1.data[0];
		data2.posTarea = pos1;
		id2 = data2.id;
		data2.id = id1;
		data1.posTarea = pos2;
		data1.id = id2;
		const sube = { data: data2, id: id1 };
		const baja = { data: data1, id: id2 };
		// console.log("** lo que sube", sube);
		// console.log("+++ lo que baja:", baja);
		const res2 = await axios.post(
			"https://apibpw.pruebas.com.co/updateactividad",
			baja
		);
		const res3 = await axios.post(
			"https://apibpw.pruebas.com.co/updateactividad",
			sube
		);
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
		result.statusResponse = false;
	}
	return result;
};

export const askTipoPregunta = async () => {
	const result = { statusResponse: true, error: null, datosTipoPregunta: null };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/asktipopregunta"
		);
		result.datosTipoPregunta = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Tipos de Pregunta";
	}
	return result;
};

export const askRegProcesos = async (localUser) => {
	const result = { statusResponse: true, error: null, datosRegProcesos: null };
	const obj = { empresa: localUser.empresa, sucursal: localUser.sucursal };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askregprocesos",
			obj
		);
		result.datosRegProcesos = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Registro de Procesos";
	}
	return result;
};

export const addRegProceso = async (datos) => {
	const result = { statusResponse: true, error: null };
	try {
		const res1 = await axios.post(
			"https://apibpw.pruebas.com.co/askunregproceso",
			datos
		);
		console.log("+++ devuelve datos:", res1);
		if (res1.data[0]) {
			result.statusResponse = false;
			result.error =
				"El Proceso ya existe, debe crearlo con un nuevo Número de Proceso";
		} else {
			try {
				const obj = {
					fecha1: fechaActual,
					hora1: horaActual,
					empresa: datos.empresa,
					sucursal: datos.sucursal,
					crador: datos.crador,
					numProceso: datos.numProceso,
					proceso: datos.proceso,
					estado: datos.estado,
				};
				console.log(
					"1. en este punto registra el proceso, está validado pero oculto"
				);
				const res = await axios.post(
					"https://apibpw.pruebas.com.co/addregproceso",
					obj
				);
				//consultar las etapas del proceso
				const obj2 = { empresa: datos.empresa, proceso: datos.proceso };
				const res2 = await axios.post(
					"https://apibpw.pruebas.com.co/asketapas",
					obj2
				);
				const etapas = res2.data;
				console.log("2. las etapas del proceso son:", etapas);
				// A cdada etapa del proceso crearle una asignación sin asignar

				etapas.map(async (lineaEtapa) => {
					console.log(
						"proceso: ",
						datos.proceso,
						"etapa es:",
						lineaEtapa.etapa
					);
					const obj3 = {
						empresa: datos.empresa,
						sucursal: datos.sucursal,
						numProceso: datos.numProceso,
						proceso: datos.proceso,
						etapa: lineaEtapa.etapa,
						estado: "abierto",
					};

					const res03 = await axios.post(
						"https://apibpw.pruebas.com.co/addasignaciones",
						obj3
					);
					const res3 = await axios.post(
						"https://apibpw.pruebas.com.co/askactividades",
						obj3
					);
					const actividades = res3.data;
					// A cada etpas del proceso consultar las taras
					actividades.map(async (lineaActividad) => {
						// console.log(
						// 	"el proceso es: ",
						// 	datos.proceso,
						// 	"etapa: ",
						// 	lineaEtapa.etapa,
						// 	"Atividad es:",
						// 	lineaActividad.tarea
						// );
						const obj4 = {
							empresa: datos.empresa,
							sucursal: datos.sucursal,
							numProceso: datos.numProceso,
							proceso: datos.proceso,
							etapa: lineaEtapa.etapa,
							tarea: lineaActividad.tarea,
							posTarea: lineaActividad.posTarea,
							descTarea: lineaActividad.descTarea,
							tipoRespuesta: lineaActividad.tipoRespuesta,
						};
						console.log("debe grabar:", obj4);
						const res4 = await axios.post(
							"https://apibpw.pruebas.com.co/addregactividades",
							obj4
						);
					});
				});

				// A cada tarea crearle su registro de actividades
			} catch (error) {
				result.error =
					"Error 404: No se pudo acceder a la base de datos de Registro de Procesos";
				result.statusResponse = false;
			}
		}
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Registro de Procesos";
		result.statusResponse = false;
	}
	return result;
};

export const askAsignaciones = async (regProceso, empresa) => {
	const result = { statusResponse: true, error: null, datosAsignaciones: null };
	const obj = { numProceso: regProceso, empresa: empresa };
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askasignaciones",
			obj
		);
		result.datosAsignaciones = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Asignaciones";
	}
	return result;
};

export const askUsuariosAsignar = async (admin) => {
	const result = { statusResponse: true, error: null, datosUsuarios: null };
	const obj = {
		empresa: admin.empresa,
		sucursal: admin.sucursal,
	};
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askusersasign",
			obj
		);
		result.datosUsuarios = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Usuarios";
	}
	return result;
};

export const actualizaUsuarioAsignado = async (data) => {
	const result = { statusResponse: true, error: null };
	const obj = { data: data, id: data.id };
	try {
		console.log("la data entregada es:", data);
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/updateusuarioasignado",
			obj
		);
	} catch (error) {
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Usuarios";
		result.statusResponse = false;
	}
	return result;
};

export const askTares = async (asignacion) => {
	const result = { statusResponse: true, error: null, datosTareass: null };
	const obj = {
		numProceso: asignacion.numProceso,
		etapa: asignacion.etapa,
	};
	try {
		const res = await axios.post(
			"https://apibpw.pruebas.com.co/askactivities",
			obj
		);
		result.datosTareas = res.data;
	} catch (error) {
		result.statusResponse = false;
		result.error =
			"Error 404: No se pudo acceder a la base de datos de Actividades";
	}
	return result;
};
