import React, { useEffect, useState } from "react";

export const BlocksForm = ({
	company,
	modules,
	closeModal,
	isOpenModal1,
	setError,
	modulesToEdit,
	setModulesToEdit,
	createAsignedModules,
	updateAsignedModules,
}) => {
	const initialForm = {
		id: null,
		company: company,
		modules: [],
	};

	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [newModules, setNewModules] = useState(modules);
	const [checkedState, setCheckedState] = useState([]); //cheqState true/false Moudulos

	useEffect(() => {
		setNewModules(modules);
		if (modulesToEdit) {
			setForm(modulesToEdit);
			editCheckedState();
		} else {
			setForm(initialForm);
			setCheckedState(new Array(modules.length).fill(false));
		}
	}, [modulesToEdit]);

	const clicCheckBox = (id) => {
		const updatedCheckedState = checkedState.map((item, index) =>
			index === id ? !item : item
		);
		setCheckedState(updatedCheckedState);
	};

	const modulesAdd = () => {
		form.modules = [];
		checkedState.map((item, index) => {
			if (item === true) {
				form.modules.push(newModules[index]); //revisar si es . algo
			}
		});
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		// const totalTrue = checkedState.filter((item) => item === true);
		// if (totalTrue.length === 0) {
		// 	alert("Debe seleccionar por lo menos un Módulo para esta empresa...");
		// 	return;
		// }

		setLoading(true);

		modulesAdd();

		if (form.id === null) {
			createAsignedModules(form);
		} else {
			updateAsignedModules(form);
		}

		handleReset();
		setLoading(false);
		closeModal();
	};

	const handleReset = () => {
		setForm(initialForm);
		setModulesToEdit(null);
		setCheckedState(new Array(modules.length).fill(false));
	};

	const editCheckedState = () => {
		const newCheckedState = [];
		modules.map((item, index) => {
			if (modulesToEdit.modules.includes(item)) {
				newCheckedState.push(true);
			} else {
				newCheckedState.push(false);
			}
		});
		setCheckedState(newCheckedState);
	};

	return (
		<div>
			<form onSubmit={handleSubmit} className="form-procesos">
				<h2 className="title2-account">{company}</h2>
				<h2 className="titulo3">Seleccione los módulos permitidos</h2>

				<div className="containerChequedListCourses">
					{newModules.map((module, id) => {
						return (
							<div className="chequedlist" key={id}>
								<input
									type="checkbox"
									id={`custom-checkbox2-${id}`}
									name={module}
									value={module}
									checked={checkedState[id]}
									onChange={() => clicCheckBox(id)}
								/>
								<label
									htmlFor={`custom-checkbox2-${id}`}
									className="nameCheckbox"
								>
									{module}
								</label>
							</div>
						);
					})}
				</div>
				<input type="submit" value="Enviar" className="btn-send-reg-asign" />
				<input
					type="reset"
					value="Limpiar"
					onClick={handleReset}
					className="btn-clean-reg-asign"
				/>
				<input
					type="reset"
					value="Cerrar"
					onClick={closeModal}
					className="btn-close-reg-asign"
				/>
			</form>
		</div>
	);
};
