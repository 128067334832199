import React, { useEffect, useState } from "react";
import Loader from "../../generalComponents/Loader";
import {
	addQuiz,
	askDataKeyValue2Simple,
	updateDocumentById,
} from "../../../utils/actions/firebase/firebaseActions";
import { ViewEvaluationForm } from "./ViewEvaluationForm";

export const ViewEvaluationApp = ({
	courseInfo,
	setSelectedCourse,
	setOnlyEvaluation,
	selectedCourse,
}) => {
	const company = localStorage.getItem("usuariosEmpresa");
	const jsonValue = localStorage.getItem("userLocal");
	const localUser = JSON.parse(jsonValue);
	const fechita = new Date();
	const actualDate = fechita.toLocaleDateString();

	const initialForm = {
		id: null,
		company: company,
		nameEvaluation: courseInfo.nameEvaluation,
		answers: null,
		califications: null,
		questions: null,
		student: localUser.email,
		date: actualDate,
		totalCalification: 0,
	};
	const [form, setForm] = useState(initialForm);
	const [loading, setLoading] = useState(false);
	const [evaluationInfo, setEvaluationInfo] = useState(null);
	const [questionsList, setQuestionsList] = useState(null);
	const [answersList, setAnswersList] = useState(null);
	const [error, setError] = useState(null);
	const [goToEvaluation, setGoToEvaluation] = useState(false);
	const [quizToEdit, setQuizToEdit] = useState(null);
	const [blockEvaluation, setBlockEvaluation] = useState(true);

	const askEvaluationInfo = async () => {
		setLoading(true);

		const response = await askDataKeyValue2Simple(
			"courseEvaluations",
			"company",
			courseInfo.company,
			"nameEvaluation",
			courseInfo.nameEvaluation
		);
		if (response.statusResponse === true) {
			setEvaluationInfo(response.data[0]);
		} else {
			setError(response.error);
			setEvaluationInfo(null);
		}

		setLoading(false);
	};

	const askQuestions = async () => {
		setLoading(true);
		const nameCollecion = "coursesEvaluationQuestions";
		const key1 = "company";
		const value1 = courseInfo.company;
		const key2 = "nameEvaluation";
		const value2 = courseInfo.nameEvaluation;
		const response = await askDataKeyValue2Simple(
			nameCollecion,
			key1,
			value1,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			setQuestionsList(response.data);
			setLoading(false);
		} else {
			setQuestionsList(null);
			setError(response.error);
			setLoading(false);
		}
	};

	const askAnswers = async () => {
		setLoading(true);
		const nameCollecion = "coursesEvaluationAnswers";
		const key1 = "company";
		const value1 = courseInfo.company;
		const key2 = "nameEvaluation";
		const value2 = courseInfo.nameEvaluation;
		const response = await askDataKeyValue2Simple(
			nameCollecion,
			key1,
			value1,
			key2,
			value2
		);
		if (response.statusResponse === true) {
			setAnswersList(response.data);
			setLoading(false);
		} else {
			setAnswersList(null);
			setError(response.error);
			setLoading(false);
		}
	};

	useEffect(() => {
		askEvaluationInfo();
		askQuestions();
		askAnswers();
		setForm(initialForm);
		if (selectedCourse.intents >= selectedCourse.maxIntents) {
			setBlockEvaluation(false);
		}
	}, [courseInfo]);

	const startEvaluation = async () => {
		let areReady = window.confirm(
			`¿Estás seguro de empezar la evaluación? Verifica tu conexión a internet antes de empezar la evaluación. Una vez comenzada la evaluación debes concluirla. Si cierras la ventana, la evaluación termina y no se puede retomar.`
		);
		if (areReady) {
			//Aquí se crea el primer quizz en  courseQuizzes y devuelve el id como llave de edición
			const id = await addQuiz(form);
			form.id = id;
			setQuizToEdit(form);
			//se alimenta en asignación de curso la primera calificación, un intento más,  y se cambia el estatus del curso
			let newSelectedCourse = selectedCourse;
			newSelectedCourse.intents = newSelectedCourse.intents + 1;
			if (newSelectedCourse.intents === newSelectedCourse.maxIntents) {
				newSelectedCourse.status = "cerrado";
			}
			newSelectedCourse.califiactionDAte = actualDate;
			setSelectedCourse(newSelectedCourse);
			await updateDocumentById(
				"coursesAsignment",
				selectedCourse.id,
				newSelectedCourse
			);
			setGoToEvaluation(true);
			setOnlyEvaluation(true);
		}
	};

	return (
		<div>
			{goToEvaluation ? (
				quizToEdit && (
					<ViewEvaluationForm
						questionsList={questionsList}
						answersList={answersList}
						setSelectedCourse={setSelectedCourse}
						quizToEdit={quizToEdit}
						selectedCourse={selectedCourse}
					/>
				)
			) : (
				<div>
					{loading ? (
						<Loader />
					) : (
						<div>
							<h3 className="titulo1">Evaluación del Curso</h3>

							{evaluationInfo && blockEvaluation ? (
								<div>
									<h2 className="titulo6">
										Nombre de la evaluaicón: {courseInfo.nameEvaluation}
									</h2>
									<h2 className="titulo6">
										Instrucciones Generales: Verifique su conexión a internet
										antes de empezar la evaluación, esta evaluación tiene la
										posibildad de 2 intentos. Una vez comenzada la evaluación
										debe concluirla. Si se cierra la ventana, la evaluación
										termina y no se puede retomar.
									</h2>
									<h2 className="titulo6">
										Instrucciones específicas de la prueba:{" "}
										{evaluationInfo.instructions}
									</h2>
									<button
										onClick={startEvaluation}
										className="btn-crear-proceso"
									>
										Realizar la Evaluación
									</button>
								</div>
							) : (
								<h2 className="titulo2">
									Ya realizó los intentos permitidos...
								</h2>
							)}
						</div>
					)}
				</div>
			)}
		</div>
	);
};
