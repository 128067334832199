import React, { useState } from "react";
import { HistoricalUsersProductionTableRow } from "./historicalUsersProductionTableRow";

export const HistoricalUsersProductionTable = ({ activitiesList }) => {
	const [filterList, setFilterList] = useState(activitiesList);

	function handleSearch(e) {
		if (e.target.value === "") {
			return setFilterList(activitiesList);
		}

		setFilterList(
			activitiesList.filter(
				(item) =>
					item.user.toLowerCase().includes(e.target.value) ||
					item.processNumber.toLowerCase().includes(e.target.value) ||
					item.product.toLowerCase().includes(e.target.value) ||
					item.activity.toLowerCase().includes(e.target.value)
			)
		);
	}
	return (
		<div>
			<div className="document-search">
				<h2 className="title-search">Buscar</h2>
				<input
					type="search"
					name=""
					id=""
					onChange={handleSearch}
					className="search-box"
				/>
			</div>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-procesos">Usuario</th>
						<th className="titulo-tabla-procesos"># Proceso</th>
						<th className="titulo-tabla-procesos">Producto</th>
						<th className="titulo-tabla-procesos">Actividad</th>
						<th className="titulo-tabla-procesos">Entregado / Cantidad</th>
						<th className="titulo-tabla-procesos">avance</th>
					</tr>
				</thead>
				<tbody>
					{filterList.length > 0 ? (
						filterList.map((el) => (
							<HistoricalUsersProductionTableRow key={el.id} el={el} />
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};
