import React from "react";
import CrudStagesTableRow from "./CrudStagesTableRow";

const CrudStagesTable = ({
	stages,
	setStageToEdit,
	openModal1,
	setIrActivity,
	downPos,
	upPos,
}) => {
	return (
		<div>
			<h3 className="titulo1">Lista de Etapas</h3>
			<table className="tabla-procesos">
				<thead>
					<tr className="fila-tabla-procesos">
						<th className="titulo-tabla-etapas1">#</th>
						<th className="titulo-tabla-etapas1">Etapa</th>
						<th className="titulo-tabla-etapas1">Descripcion</th>
						<th className="titulo-tabla-etapas1">Acción</th>
						<th className="titulo-tabla-etapas1">Posición</th>
					</tr>
				</thead>

				<tbody>
					{stages.length > 0 ? (
						stages.map((el) => (
							<CrudStagesTableRow
								key={el.id}
								el={el}
								setStageToEdit={setStageToEdit}
								openModal1={openModal1}
								setIrActivity={setIrActivity}
								totalStages={stages.length}
								downPos={downPos}
								upPos={upPos}
							/>
						))
					) : (
						<tr>
							<td colSpan="3">Sin datos</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
};

export default CrudStagesTable;
