import React, { useEffect, useState } from "react";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

export const LoginForgotForm = ({ closeModal }) => {
	const [mail, setMail] = useState("");
	const [close, setClose] = useState(false);
	const [refresh, setRefresh] = useState(false);

	useEffect(() => {
		setRefresh(false);
		setMail("");
	}, [refresh]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (mail === "") {
			alert("No ha escrito su correo electrónico ...");
			setRefresh(true);
			return;
		}
		const auth = getAuth();
		sendPasswordResetEmail(auth, mail)
			.then(() => {
				alert(
					"Se ha enviado un correo electrónico a la dirección especificada. Por favor revise incluso su carpeta Spam ...."
				);
				setRefresh(true);
				closeModal();
				// Password reset email sent!
				// ..
			})
			.catch((error) => {
				alert(error.message);
				setRefresh(true);
				const errorCode = error.code;
				const errorMessage = error.message;
				// ..
			});
	};
	return (
		<div>
			<div className="containerModalForgotPassword">
				<h2 className="titleLogin">Reestablecer Contraseña</h2>
				<div className="login">
					<article className="fondo">
						<form onSubmit={handleSubmit} className="form-procesos">
							<h2 className="title2-account">Ingrese su correo...</h2>
							<input
								type="email"
								className="form-control mb-2"
								placeholder="e-mail..."
								onChange={(text) => setMail(text.target.value)}
								value={mail}
							/>

							<div>
								<input
									type="submit"
									value="Enviar Correo"
									className="buttonLogin"
								/>
							</div>
						</form>
					</article>
				</div>
			</div>
			<div className="BtnCloseModal">
				<input
					type="reset"
					value="Cerrar"
					onClick={closeModal}
					className="btn-close-reg-asign"
				/>
			</div>
		</div>
	);
};
